import { Inject, Injectable } from '@angular/core';

import { MailSenderService } from 'src/app/common/services/mail/mail-sender.service';
import { MailTemplateService } from 'src/app/common/services/mail/mail-template.service';

import { OclMailService } from '../../../ocl/services/ocl-mail-service/ocl-mail.service';
import { OccModuleConfigService } from '../occ-module-config/occ-module-config.service';

import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class OccMailService extends OclMailService {
  protected constructor(
    protected readonly mailSenderService: MailSenderService,
    @Inject('CONSTANTS') protected CONSTANTS,
    protected mailTemplateService: MailTemplateService,
    protected moduleConfig: OccModuleConfigService,
  ) {
    super(mailSenderService, CONSTANTS, mailTemplateService, moduleConfig);

    //TODO: Penser a remplacer translaPipe par translate du package
    this.translate.onLangChange.subscribe(() => {
      this.SENDER_SUFFIX = this.translate.instant('MAIL.OCC.SENDER_SUFFIX') || 'OCC';
    });
  }
}
