import { OclTagsStoreManager } from './../../ocl/store/tags/ocl-tags.store-manager';
import { NotificationsService } from 'src/app/common/services/notifications/notifications.service';
import { EclSmsService } from './ecl-sms-service/ecl-sms.service';
import { EclMailService } from './ecl-mail-service/ecl-mail.service';
import { Injectable } from '@angular/core';
import { RequestService } from 'src/app/common/services/request.service';
import { OclTasksService } from '../../ocl/services/ocl-tasks-service/ocl-tasks.service';
import { OclTasksStoreManager } from '../../ocl/store/tasks/ocl-tasks.store-manager';
import { EclHistoryService } from './ecl-history-service/ecl-history.service';
import { EclTaskTagService } from './ecl-task-tag.service';
import { EclTagsService } from './ecl-tags-service/ecl-tags.service';
import { EclModuleConfigService } from './ecl-module-config/ecl-module-config.service';
import { CommonStoreManager } from '../../common/store/common.store-manager';
import { EclCrisis } from '../models/ecl-crisis';

@Injectable({
  providedIn: 'root',
})
export class EclTasksService extends OclTasksService {
  // tslint:disable:variable-name
  protected ParseTask = Parse.Object.extend('HOLTask');
  protected ParseTag = Parse.Object.extend('ECLTag');
  protected ParseTaskTag = Parse.Object.extend('ECLTaskTag');

  // tslint:enabled

  constructor(
    protected requestService: RequestService,
    protected taskTagService: EclTaskTagService,
    protected historyService: EclHistoryService,
    protected tagsService: EclTagsService,
    protected moduleConfig: EclModuleConfigService,
    protected tasksStoreManager: OclTasksStoreManager,
    protected commonStoreManager: CommonStoreManager,
    protected mailService: EclMailService,
    protected smsService: EclSmsService,
    protected notificationsService: NotificationsService,
    protected tagsStoreManager: OclTagsStoreManager,
  ) {
    super(
      requestService,
      historyService,
      tagsService,
      taskTagService,
      moduleConfig,
      tasksStoreManager,
      commonStoreManager,
      mailService,
      smsService,
      notificationsService,
      tagsStoreManager,
    );
  }

  public isCrisisTaskActivated(eclCrisis: EclCrisis): boolean {
    return false;
  }
}
