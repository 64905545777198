import { ErpCrisisTaskService } from '../../services/erp-crisis-task.service';
import { DatePipe } from '@angular/common';
import { Component, inject, Inject, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { MarkdownEditorComponent, MdEditorOption } from 'ngx-markdown-editor';
import { FunctionUserForCrisis } from 'src/app/erp/models/erp-functionUser';
import { ErpCrisisTask, ErpVisiblityStatus } from 'src/app/erp/models/erp-crisisTask';
import { cloneDeep, orderBy } from 'lodash';
import { ModuleConfigService } from 'src/app/common/services/module-config/module-config.service';
import { nextInfoType } from 'src/app/common/components/hol-next-info/hol-next-info.component';

import { HolNotifyFunction } from '../../../common/models/hol-notification.model';
import { HistoryService } from '../../../common/services/history.service';
import { TagsService } from '../../../common/services/tags.service';
import { ErpCrisis } from '../../models/erp-crisis';
import { ErpHistoryService } from '../../services/erp-history.service';
import { ErpModuleConfigService } from '../../services/erp-module-config.service';
import { ErpTagsService } from '../../services/erp-tag.service';
import { ErpFunctionCrisis } from '../../models/erp-functionCrisis';
import { ErpFunctionsService } from '../../services/erp-functions.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-erp-crisis-task-modal',
  templateUrl: './erp-crisis-task-modal.component.html',
  styleUrls: ['./erp-crisis-task-modal.component.scss'],
  providers: [
    {
      provide: TagsService,
      useExisting: ErpTagsService,
    },
    {
      provide: ModuleConfigService,
      useExisting: ErpModuleConfigService,
    },
    {
      provide: HistoryService,
      useExisting: ErpHistoryService,
    },
  ],
})
export class ErpCrisisTaskModalComponent implements OnInit {
  public options: MdEditorOption = {
    resizable: false,
    showBorder: false,
    hideIcons: ['FullScreen', 'Image'],
    showPreviewPanel: false,
  };
  functionsUserForCrisis: FunctionUserForCrisis[];
  functionsUserForCrisisSelected: string;
  allUserFunctions: FunctionUserForCrisis[] = [];
  crisisTask: ErpCrisisTask;
  form: UntypedFormGroup;
  crisis: ErpCrisis;
  defaultOutputDataLabelPlaceHolder: string;
  outputDataLabelPlaceHolder: string;
  defaultOutputTitlePlaceHolder: string;
  outputTitlePlaceHolder: string;
  defaultTitlePlaceHolder: string;
  titlePlaceHolder: string;
  temporarySave = false;
  notifyFunction: HolNotifyFunction = { sendByMail: false, sendBySms: false };
  @ViewChild('mdEditor', { static: false }) mdEditor: MarkdownEditorComponent;

  private readonly translate = inject(TranslateService);

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public moduleConfig: ModuleConfigService,
    public dialogRef: MatDialogRef<ErpCrisisTaskModalComponent>,
    private tasksService: ErpCrisisTaskService,
    private datePipe: DatePipe,
    private erpFunctionService: ErpFunctionsService,
  ) {}

  ngOnInit(): void {
    this.setUserFunctionList();
    this.defaultOutputDataLabelPlaceHolder = this.translate.instant(
      this.moduleConfig.config.translateKey + '.MODALS.TASK.OUTPUT_DATA_LABEL_PLACEHOLDER',
    );
    this.outputDataLabelPlaceHolder = this.defaultOutputDataLabelPlaceHolder;
    this.defaultOutputTitlePlaceHolder = this.translate.instant(
      this.moduleConfig.config.translateKey + '.MODALS.TASK.OUTPUT_TITLE_PLACEHOLDER',
    );
    this.outputTitlePlaceHolder = this.defaultOutputTitlePlaceHolder;
    this.defaultTitlePlaceHolder = this.translate.instant(this.moduleConfig.config.translateKey + '.MODALS.TASK.TASK_TITLE_PLACEHOLDER');
    this.titlePlaceHolder = this.defaultTitlePlaceHolder;
    this.form = new UntypedFormGroup({});

    this.crisis = this.data.crisis;

    this.temporarySave = this.data.temporarySave ? this.data.temporarySave : false;

    this.crisisTask = {
      crisisTypeId: this.data.crisis.type.crisisTypeId,
      taskTitle: '',
      taskDescription: this.data.crisisNew
        ? this.data.crisisNew.content
        : this.data.crisisDescision
        ? this.data.crisisDescision.contentText +
          ' (' +
          this.translate.instant('ERP.MODALS.DECISION.NEW_TASK_TITLE', {
            date: this.datePipe.transform(new Date(), 'dd/MM/yyyy').toString().toLocaleUpperCase(),
          }) +
          ')'
        : '',
      functionId: '',
      isOnDashboard: false,
      order: 1,
      status: 'TODO',
      outputDataLabel: '',
      outputTitle: '',
      isTemporary: true,
      code: '',
      attachments: this.data.crisisDescision ? this.data.crisisDescision.attachments : {},
      tags: [],
    };
    if (this.data.isCreationMode) {
      this.crisisTask.customVisibleBy = ErpVisiblityStatus.all;
    }

    if (!this.functionsUserForCrisisSelected) {
      const defaultSelectedFunction =
        this.functionsUserForCrisis.find(fUser => fUser.functionId === this.data.forFunction) || this.functionsUserForCrisis[0];
      this.functionsUserForCrisisSelected = defaultSelectedFunction.functionId;
    }
  }

  setCrisisTaskValues() {
    const dataFn = this.data.isInErdTeam ? this.allUserFunctions : this.functionsUserForCrisis;
    const selectedFn = dataFn.find(f => f.functionId === this.functionsUserForCrisisSelected);
    if (selectedFn) {
      this.crisisTask.code = selectedFn.shortTitle;
      this.crisisTask.functionId = selectedFn.functionId;
      this.crisisTask.order = 1;
      this.crisisTask.subOrder = '';
    }
    const crisisTasksOrdered = orderBy(
      this.data.crisis.tasks.filter(t => {
        const taskComp = this.getCompaniesNameFromAcl(t.acl);
        const newTaskComp = this.getCompaniesNameFromAcl(this.crisisTask.acl);
        const intersection = taskComp.filter((x: string) => newTaskComp.includes(x));
        return t.functionId === this.functionsUserForCrisisSelected && t.status !== 'DONE' && intersection.length > 0;
      }),
      ['order', 'subOrder'],
      ['asc', 'asc'],
    );
    if (crisisTasksOrdered.length) {
      const selectedTasks = crisisTasksOrdered.filter(c => {
        return c.order === crisisTasksOrdered[0].order;
      });
      const taskModel = selectedTasks[selectedTasks.length - 1];
      this.crisisTask.order = taskModel.order;
      this.crisisTask.code = taskModel.code;
      this.crisisTask.subOrder = this.nextChar(taskModel.subOrder);
    }
  }

  setUserFunctionList() {
    this.functionsUserForCrisis = orderBy(this.data.functionsUserForCrisis, 'shortTitle');

    if (this.data.isInErdTeam) {
      this.erpFunctionService.getUserFunctionsCrisis().then(erpFunctionsCrisis => {
        this.erpFunctionService.getAllUserFunctions().then(value => {
          this.allUserFunctions = value
            .map(uFunc => {
              const matchFunction: ErpFunctionCrisis = erpFunctionsCrisis.find(fCrisis => fCrisis.functionId === uFunc.functionId);
              return {
                functionId: uFunc.functionId,
                title: matchFunction && matchFunction.title,
                shortTitle: matchFunction && matchFunction.shortTitle,
                isHolder: false,
                categoryDocumentId: matchFunction && matchFunction.tagId,
              };
            })
            .sort((a, b) => (a.shortTitle > b.shortTitle ? 1 : -1));
          this.allUserFunctions = this.allUserFunctions.filter((obj, pos, arr) => {
            return arr.map(mapObj => mapObj['functionId']).indexOf(obj['functionId']) === pos;
          });
        });
      });
    }
  }

  outputDataLabelChange(event: string): void {
    this.crisisTask.outputDataLabel = event;
    if (event.trim().length === 0) {
      this.crisisTask.customVisibleBy = undefined;
      this.crisisTask.frozenByErd = undefined;
    }
  }

  changeVisibility(event: ErpVisiblityStatus): void {
    this.crisisTask.customVisibleBy = event;
  }

  changeFrozenByErd(event: boolean): void {
    this.crisisTask.frozenByErd = event;
  }

  selectTaskCode(event): void {
    this.functionsUserForCrisisSelected = event;
  }

  saveCrisisTask(): void {
    this.setCrisisTaskValues();
    //   this.crisisTask.isOnDashboard = this.crisisTask.outputDataLabel && this.crisisTask.outputDataLabel.trim().length > 0 ? true : false;
    if (this.notifyFunction.sendByMail || this.notifyFunction.sendBySms) {
      this.notifyFunction['functionTitle'] =
        this.functionsUserForCrisis.length > 0
          ? this.functionsUserForCrisis.find(fn => fn.functionId === this.crisisTask.functionId).title
          : '';
    }
    if (this.temporarySave) {
      this.dialogRef.close({
        crisisTask: { ...this.crisisTask },
        notifications: [],
        notifyFunction: this.notifyFunction.sendByMail || this.notifyFunction.sendBySms ? this.notifyFunction : undefined,
        crisisObjectId: this.crisis.objectId,
        historyLogComment: 'CREATE_TASK',
      });
    } else {
      this.tasksService.save(
        { ...this.crisisTask },
        {
          notifications: [],
          functionToNotify: this.notifyFunction.sendByMail || this.notifyFunction.sendBySms ? this.notifyFunction : undefined,
        },
        'CREATE_TASK',
      );
      this.dialogRef.close({});
    }
  }

  saveNextInfo(nextInfo: nextInfoType) {
    this.crisisTask.nextInfoTime = nextInfo.nextInfoTime;
  }

  saveNotifyFunction(notifyFunction: { sendByMail: boolean; sendBySms: boolean }) {
    this.notifyFunction = cloneDeep(notifyFunction);
  }

  nextChar(c: string) {
    return c === '' ? 'a' : c == 'z' ? 'a' : c == 'Z' ? 'A' : String.fromCharCode(c.charCodeAt(0) + 1);
  }

  removeDuplicates(arr: string[]): string[] {
    return arr.filter((item, index) => arr.indexOf(item) === index);
  }

  getCompaniesNameFromAcl(acl: Parse.ACL): string[] {
    const ROLE_PARTS_REGEXP = /^([A-Z]+)_([A-Z]+)(?:-([A-Z]+))?_(READ|WRITE)/;
    let comp: string[] = [];
    if (!acl) {
      return [];
    }
    comp = Object.keys(acl.permissionsById).map(el => {
      const r = el.replace('role:', '');
      if (ROLE_PARTS_REGEXP.test(r)) {
        const parts = ROLE_PARTS_REGEXP.exec(r);
        if (parts) {
          return parts[2];
        } else {
          return '';
        }
      } else {
        return '';
      }
    });
    comp = comp.filter(el => el !== '');
    comp = this.removeDuplicates(comp);
    return comp;
  }
}
