import { Inject, Injectable } from '@angular/core';
import moment from 'moment';

import { ModuleConfigService } from '../../../common/services/module-config/module-config.service';
import { ISmsMessage, SmsSenderService } from 'src/app/common/services/sms/sms-sender.service';
import { OclTask } from '../../models/ocl-task';
import { TranslateService } from '@ngx-translate/core';
import { OclGlobalInstruction } from '../../models/ocl-global-instruction.model';

@Injectable({
  providedIn: 'root',
})
export class OclSmsService {
  protected SENDER_SUFFIX = '';

  constructor(
    protected readonly smsSenderService: SmsSenderService,
    @Inject('CONSTANTS') protected CONSTANTS,
    protected translate: TranslateService,
    protected moduleConfig: ModuleConfigService,
  ) {}

  public sendNewDecisionSMS(decision, phoneNumbers) {
    if (phoneNumbers && phoneNumbers.length) {
      const messages: ISmsMessage[] = phoneNumbers.map(phoneNumber => {
        return {
          to: phoneNumber,
          text: this.getDecisionMessage(decision),
        };
      });
      this.smsSenderService.sendMultipleSms(messages, true, this.CONSTANTS.COMPANY_NAME + ' ' + this.SENDER_SUFFIX);
    }
  }

  public sendNewGlobalInstructionSMS(globalInstruction, phoneNumbers) {
    if (phoneNumbers && phoneNumbers.length) {
      const messages: ISmsMessage[] = phoneNumbers.map(phoneNumber => {
        return {
          to: phoneNumber,
          text: this.getGlobalInstructionMessage(globalInstruction),
        };
      });
      this.smsSenderService.sendMultipleSms(messages, true, this.CONSTANTS.COMPANY_NAME + ' ' + this.SENDER_SUFFIX);
    }
  }

  public sendNewEventSMS(event, infos, phoneNumbers) {
    if (phoneNumbers && phoneNumbers.length) {
      const messages: ISmsMessage[] = phoneNumbers.map(phoneNumber => {
        return {
          to: phoneNumber,
          text: this.getNewEventMessage(event, infos, false),
        };
      });
      this.smsSenderService.sendMultipleSms(messages, true, this.CONSTANTS.COMPANY_NAME + ' ' + this.SENDER_SUFFIX);
    }
  }

  public sendEclActivatedSMS(event, infos, phoneNumbers) {
    if (phoneNumbers && phoneNumbers.length) {
      const messages: ISmsMessage[] = phoneNumbers.map(phoneNumber => {
        return {
          to: phoneNumber,
          text: this.getECLActivateMessage(event, infos),
        };
      });
      this.smsSenderService.sendMultipleSms(messages, true, this.CONSTANTS.COMPANY_NAME + ' ' + this.SENDER_SUFFIX);
    }
  }

  public sendNewEventChecklistActivatedSMS(event, infos, phoneNumbers) {
    if (phoneNumbers && phoneNumbers.length) {
      const messages: ISmsMessage[] = phoneNumbers.map(phoneNumber => {
        return {
          to: phoneNumber,
          text: this.getNewEventMessage(event, infos, true),
        };
      });
      this.smsSenderService.sendMultipleSms(messages, true, this.CONSTANTS.COMPANY_NAME + ' ' + this.SENDER_SUFFIX);
    }
  }

  public sendNewEventInfoSMS(event, eventInfo, phoneNumbers) {
    if (phoneNumbers && phoneNumbers.length) {
      const messages: ISmsMessage[] = phoneNumbers.map(phoneNumber => {
        return {
          to: phoneNumber,
          text: this.getNewEventInfoMessage(event, eventInfo),
        };
      });
      this.smsSenderService.sendMultipleSms(messages, true, this.CONSTANTS.COMPANY_NAME + ' ' + this.SENDER_SUFFIX);
    }
  }

  public sendNewEventInfoChecklistActivatedSMS(event, eventInfo, phoneNumbers) {
    if (phoneNumbers && phoneNumbers.length) {
      const messages: ISmsMessage[] = phoneNumbers.map(phoneNumber => {
        return {
          to: phoneNumber,
          text: this.getNewEventInfoMessage(event, eventInfo, true),
        };
      });
      this.smsSenderService.sendMultipleSms(messages, true, this.CONSTANTS.COMPANY_NAME + ' ' + this.SENDER_SUFFIX);
    }
  }

  public sendCloseEventSMS(event, phoneNumbers) {
    if (phoneNumbers && phoneNumbers.length) {
      const messages: ISmsMessage[] = phoneNumbers.map(phoneNumber => {
        return {
          to: phoneNumber,
          text: this.getCloseEventMessage(event),
        };
      });
      this.smsSenderService.sendMultipleSms(messages, true, this.CONSTANTS.COMPANY_NAME + ' ' + this.SENDER_SUFFIX);
    }
  }

  private getDecisionMessage(decision) {
    let msg = this.translate.instant(`SMS.${this.moduleConfig.config.translateKey}.NEW_DECISION`, {
      decision: decision.message,
    });

    if (decision.attachments.noteFile) {
      msg +=
        '\n\n' +
        this.translate.instant(`COMMON.LINK_TO_NOTE`, {
          link: decision.attachments.noteFile.url,
        });
    }
    return msg;
  }

  private getGlobalInstructionMessage(globalInstruction: OclGlobalInstruction) {
    console.log('msg getGlobalInstructionMessage', this.moduleConfig.config.translateKey);
    let msg = this.translate.instant(`SMS.${this.moduleConfig.config.translateKey}.NEW_GLOBAL_INSTRUCTION`, {
      globalInstruction: globalInstruction.description,
    });

    if (globalInstruction.attachments.noteFile) {
      msg +=
        '\n\n' +
        this.translate.instant(`COMMON.LINK_TO_NOTE`, {
          link: globalInstruction.attachments.noteFile.url,
        });
    }

    console.log('msg getGlobalInstructionMessage', msg);
    return msg;
  }

  private getECLActivateMessage(event, infos) {
    const lastInfo = infos && infos.length ? infos[0] : null;
    const translateKey = 'SMS.' + this.moduleConfig.config.translateKey + '.NEW_EVENT_FROM_ECL_ACTIVATED';

    return this.translate.instant(translateKey, {
      code: event.scenario ? event.scenario.code : undefined,
      order: event.order,
      scenario: event.scenario ? event.scenario.title : '',
      description: event.description,
      nextInfoTime: lastInfo && lastInfo && lastInfo.nextInfoTime && moment(lastInfo.nextInfoTime).utc().format('ddd DD [at] HH:mm [UTC]'),
      nextInfoText: lastInfo && lastInfo && lastInfo.message,
      eclUrl: location.origin + '/#/ecl/dashboard',
    });
  }

  //TODO: fix bug blocking sms function when there is a note attached to the event
  private getNewEventMessage(event, infos, isFromChecklistActivate?) {
    const lastInfo = infos && infos.length ? infos[0] : null;

    const translateKey = isFromChecklistActivate
      ? 'SMS.' + this.moduleConfig.config.translateKey + '.NEW_EVENT_FROM_CHECKLIST_ACTIVATED'
      : 'SMS.' + this.moduleConfig.config.translateKey + '.NEW_EVENT';

    let msg = this.translate.instant(translateKey, {
      code: event.scenario ? event.scenario.code : undefined,
      order: event.order,
      scenario: event.scenario ? event.scenario.title : '',
      description: event.description,
      nextInfoTime:
        lastInfo &&
        lastInfo.info &&
        lastInfo.info.nextInfoTime &&
        moment(lastInfo.info.nextInfoTime).utc().format('ddd DD [at] HH:mm [UTC]'),
      nextInfoText: lastInfo && lastInfo.info && lastInfo.info.message,
    });

    if (event.attachments.noteFile) {
      msg +=
        '\n\n' +
        this.translate.instant(`COMMON.LINK_TO_NOTE`, {
          link: event.attachments.noteFile.url,
        });
    }

    return msg;
  }

  private getNewEventInfoMessage(event, eventInfo, isFromChecklistActivate?) {
    if (eventInfo.length) {
      eventInfo = eventInfo[0];
    }
    const translateKey = isFromChecklistActivate
      ? 'SMS.' + this.moduleConfig.config.translateKey + '.NEW_EVENT_INFO_FROM_CHECKLIST_ACTIVATED'
      : 'SMS.' + this.moduleConfig.config.translateKey + '.NEW_EVENT_INFO';

    return this.translate.instant(translateKey, {
      nbInfos: event.infos.length,
      code: event.scenario ? event.scenario.code : undefined,
      order: event.order,
      eventInfoDescription: eventInfo.message,
      eventDescription: event.description,
      nextInfoTime: moment(eventInfo.nextInfoTime).utc().format('ddd DD [at] HH:mm [UTC]'),
      appUrl: location.origin,
    });
  }

  private getCloseEventMessage(event) {
    let closeReason;
    if (event.closeReason === 0) {
      closeReason = this.translate.instant(this.moduleConfig.config.translateKey + '.DASHBOARD.EVENTS.MODALS.CLOSE_REASON_1');
    } else if (event.closeReason === 1) {
      closeReason = this.translate.instant(this.moduleConfig.config.translateKey + '.DASHBOARD.EVENTS.MODALS.CLOSE_REASON_2');
    } else {
      closeReason = event.closeReasonText;
    }
    return this.translate.instant('SMS.' + this.moduleConfig.config.translateKey + '.CLOSE_EVENT', {
      code: event.scenario ? event.scenario.code : undefined,
      order: event.order,
      description: event.description,
      closeReason,
    });
  }

  public sendNewLogbookSMS(logbook, phoneNumbers) {
    if (phoneNumbers && phoneNumbers.length) {
      const messages: ISmsMessage[] = phoneNumbers.map(phoneNumber => {
        return {
          to: phoneNumber,
          text: this.getLogbookMessage(logbook),
        };
      });
      this.smsSenderService.sendMultipleSms(messages, true, this.CONSTANTS.COMPANY_NAME + ' ' + this.SENDER_SUFFIX);
    }
  }

  private getLogbookMessage(logbook) {
    let msg = this.translate.instant('SMS.' + this.moduleConfig.config.translateKey + '.NEW_LOGBOOK', { logbook: logbook.text });

    if (logbook.attachments.noteFile) {
      msg +=
        '\n\n' +
        this.translate.instant(`COMMON.LINK_TO_NOTE`, {
          link: logbook.attachments.noteFile.url,
        });
    }

    return msg;
  }

  sendTaskDoneSms(task: OclTask, functionTitle: string, comment: string, phoneNumbersToSend: string[]) {
    const messages: ISmsMessage[] = [];
    for (const phoneNumber of phoneNumbersToSend) {
      messages.push({
        to: phoneNumber,
        text: this.getTaskDoneMail(task, functionTitle, comment),
      });
    }
    this.smsSenderService.sendMultipleSms(messages, true, this.CONSTANTS.COMPANY_NAME + ' ' + this.SENDER_SUFFIX);
  }

  getTaskDoneMail(task: OclTask, functionTitle, comment): string {
    let msg = this.translate.instant('SMS.' + this.moduleConfig.config.translateKey + '.TASK_TO_DONE.SUBJECT') + ' ';
    msg += this.translate.instant('SMS.' + this.moduleConfig.config.translateKey + '.TASK_TO_DONE.FISRT_PART', {
      firstName: task.updatedBy.firstName,
      lastName: task.updatedBy.lastName,
      taskTitle: task.taskTitle,
      functionTitle,
      taskResult: task.outputTitle,
    });
    if (comment) {
      msg += this.translate.instant('SMS.CRISIS_TASK_TO_DONE.SECOND_PART', {
        comment,
      });
    }
    return msg;
  }

  sendTaskFrozenSms(task: OclTask, functionTitle: string, comment: string, phoneNumbersToSend: string[]) {
    const messages: ISmsMessage[] = [];
    for (const phoneNumber of phoneNumbersToSend) {
      messages.push({
        to: phoneNumber,
        text: this.getTaskFrozenMail(task, functionTitle, comment),
      });
    }
    this.smsSenderService.sendMultipleSms(messages, true, this.CONSTANTS.COMPANY_NAME + ' ' + this.SENDER_SUFFIX);
  }

  getTaskFrozenMail(task: OclTask, functionTitle, comment): string {
    let msg = this.translate.instant('SMS.' + this.moduleConfig.config.translateKey + '.TASK_TO_FROZEN.SUBJECT') + ' ';
    msg += this.translate.instant('SMS.' + this.moduleConfig.config.translateKey + '.TASK_TO_FROZEN.FISRT_PART', {
      firstName: task.updatedBy.firstName,
      lastName: task.updatedBy.lastName,
      taskTitle: task.taskTitle,
      functionTitle,
      taskResult: task.outputTitle,
      comment,
    });
    return msg;
  }

  sendTaskBackTodoSms(task: OclTask, functionTitle: string, comment: string, phoneNumbersToSend: string[]) {
    const messages: ISmsMessage[] = [];
    for (const phoneNumber of phoneNumbersToSend) {
      messages.push({
        to: phoneNumber,
        text: this.getTaskBackTodoMail(task, functionTitle, comment),
      });
    }
    this.smsSenderService.sendMultipleSms(messages, true, this.CONSTANTS.COMPANY_NAME + ' ' + this.SENDER_SUFFIX);
  }

  getTaskBackTodoMail(task: OclTask, functionTitle, comment): string {
    let msg = this.translate.instant('SMS.' + this.moduleConfig.config.translateKey + '.TASK_TO_TODO.SUBJECT');
    msg += this.translate.instant('SMS.' + this.moduleConfig.config.translateKey + '.TASK_TO_TODO.FISRT_PART', {
      firstName: task.updatedBy.firstName,
      lastName: task.updatedBy.lastName,
      taskTitle: task.taskTitle,
      functionTitle,
      taskResult: task.outputTitle,
    });
    if (comment) {
      msg += this.translate.instant('SMS.' + this.moduleConfig.config.translateKey + '.TASK_TO_TODO.SECOND_PART', {
        comment,
      });
    }
    return msg;
  }
}
