import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';

import { FormIoCredentials } from 'src/app/common/services/options.service';

import { Formio } from 'formiojs';
import { FormAsAttachmentService } from 'src/app/common/components/form-as-attachment/form-as-attachment.service';
import { HolAttachments, HolisFile } from 'src/app/common/models/hol-attachments.model';
import { ModuleConfigService } from 'src/app/common/services/module-config/module-config.service';
import moment from 'moment';
import { FilesService } from 'src/app/common/services/files.service';

import { HolTask } from '../../../models/hol-task';
import { TasksService } from '../../../services/tasks.service';
import { HelperService } from '../../../services/helper.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-task-form-io',
  templateUrl: './task-form-io.component.html',
  styleUrls: ['./task-form-io.component.scss'],
})
export class TaskFormIoComponent implements OnInit {
  @Input() task: HolTask;
  @Input() formIoCredentials: FormIoCredentials;
  @Input() isHolder: boolean;
  formIoUrl = '';
  formComponents: any;

  constructor(
    protected translate: TranslateService,
    protected readonly filesService: FilesService,
    private http: HttpClient,
    private moduleConfig: ModuleConfigService,
    private tasksService: TasksService,
    private formAsAttachmentService: FormAsAttachmentService,
    private helperService: HelperService,
  ) {}

  ngOnInit() {
    this.formIoUrl =
      this.task.formIoFormSubmission && this.task.formIoFormSubmission.length
        ? 'https://' +
          this.formIoCredentials.formIoBackEnd +
          '.form.io/' +
          this.task.formIoFormRef +
          '/submission/' +
          this.task.formIoFormSubmission
        : 'https://' + this.formIoCredentials.formIoBackEnd + '.form.io/' + this.task.formIoFormRef;
  }

  formLoad(e) {
    this.formComponents = e.components;
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    this.http
      .post('https://' + this.formIoCredentials.formIoBackEnd + '.form.io/admin/login', this.formIoCredentials.dataLogin, {
        headers,
        observe: 'response',
      })
      .subscribe(res => {
        Formio.setToken(res.headers.get('x-jwt-token'));
      });
  }

  private formHTMLToFile(formToExport): Promise<HolisFile> {
    const formHtml = JSON.stringify(formToExport);
    const context = {
      module: this.moduleConfig.config.translateKey,
      category: 'TASK',
      htmlTitle: 'Task form ' + this.task.taskTitle,
      htmlScenario: formHtml,
      htmlDate: moment(this.task.updatedAt).format('DD/MM/YY HH:mm'),
      htmlTemplate: 'A',
    };
    const htmlTemplate = this.formAsAttachmentService.createHtmlContent(formHtml, context);
    const blob = new Blob([htmlTemplate], { type: 'text/html' });
    const reader = new FileReader();

    const attachments = new HolAttachments();
    const file: HolisFile = new HolisFile();
    const taskTitle = this.helperService.removeDiacritics(this.task.taskTitle.toLocaleLowerCase());
    file.fileName = 'form-' + this.task.code + '-' + moment().utc().format('YYMMDDHHmm') + '.html';
    file.url = '';
    reader.readAsDataURL(blob);
    return new Promise((resolve, reject) => {
      reader.onloadend = () => {
        this.filesService.uploadFile(file.fileName, { base64: reader.result }).then(
          url => {
            file.url = url;
            file.canBeDeleted = false;
            attachments.file = file;
            resolve(attachments.file);
          },
          err => {
            reject(err);
          },
        );
      };
    });
  }
  prepareFormData(data, components) {
    return components.map(c => {
      if (c.type === 'button' && c.action === 'submit') {
        return null;
      } else {
        c.disabled = true;
        c.defaultValue = data[c.key];
        return c;
      }
    });
  }

  onSubmit(e) {
    const formComponents = this.formComponents;
    const formToExport = this.prepareFormData(e.data, formComponents);
    this.formHTMLToFile(formToExport).then(formAsFile => {
      if (this.task.attachments.files.length) {
        this.task.attachments.files = this.task.attachments.files.reduce((acc, curr) => {
          if (curr.fileName !== formAsFile.fileName && !curr.fileName.startsWith('form-' + this.task.code)) {
            acc.push(curr);
          }
          return acc;
        }, []);
      }
      this.task.attachments.files.push(formAsFile);
      (this.task.formIoFormSubmission = e._id),
        this.tasksService.save(
          { ...this.task },
          { notifications: [], functionToNotify: undefined },
          this.translate.instant('UPDATE_TASK_FORMIO'),
        );
    });
  }
}
