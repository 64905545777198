import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-crew-events-box',
  templateUrl: './crew-events-box.component.html',
  styleUrls: ['./crew-events-box.component.scss'],
})
export class CrewEventsBoxComponent {
  @Input() currentFilter: any;
  @Input() isReadOnly: boolean;

  eventFilter = '';

  constructor() {}
}
