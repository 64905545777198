import { OclGroupsStoreManager } from '../../../ocl/store/groups/ocl-groups.store-manager';
import { Store } from '@ngrx/store';
import { AppState } from '../../../store';
import { combineLatest, Observable } from 'rxjs';
import { OclGroupsStoreState } from '../../../ocl/ocl.model';
import { EclCrisis } from '../../models/ecl-crisis';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { EclDecisionGroup } from '../../models/ecl-decision-group.model';
import { EclEventGroup } from '../../models/ecl-event-group.model';
import { EclLogbookGroup } from '../../models/ecl-logbook-group.model';

@Injectable({
  providedIn: 'root',
})
export class EclGroupsStoreManager extends OclGroupsStoreManager {
  private $selectedCrisis: Observable<EclCrisis>;

  constructor(store: Store<AppState>) {
    super(store);
    this.$selectedCrisis = store.select('ecl', 'crisisState', 'selectedCrisis');
  }

  get groupsState(): Observable<OclGroupsStoreState> {
    return combineLatest([this.$selectedCrisis, this._groupsState]).pipe(
      map(([crisis, groupState]) => {
        const crisisId = crisis?.objectId;

        return {
          ...groupState,
          decisions: groupState.decisions.filter((decisionGroup: EclDecisionGroup) => decisionGroup.crisis?.objectId === crisisId),
          events: groupState.events.filter((eventsGroup: EclEventGroup) => eventsGroup.crisis?.objectId === crisisId),
          logbooks: groupState.logbooks.filter((logbooksGroup: EclLogbookGroup) => logbooksGroup.crisis?.objectId === crisisId),
          /* Uncomment if needed
          globalInstructions: groupState.globalInstructions.filter(
            (globalInstructionsGroup: EclGlobalInstructionGroup) =>
              globalInstructionsGroup.crisis?.objectId === crisisId
          ),
          */
        };
      }),
    );
  }
}
