import { Component, Inject, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { nextInfoType } from 'src/app/common/components/hol-next-info/hol-next-info.component';
import { TasksService } from '../../services/tasks.service';
import { HolTask } from '../../models/hol-task';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-task-item',
  templateUrl: './task-item.component.html',
  styleUrls: ['./task-item.component.scss'],
})
export class TaskItemComponent implements OnInit {
  @Input()
  task: HolTask;
  @Input()
  isReadOnly: boolean;
  @Input()
  canMarkAsDone = true;
  @Input()
  displayFromHistory = false;
  @Input()
  functionShortTitle: string;
  @Input() isHolder;
  @Output()
  public clickOnItem = new EventEmitter<string>();
  functionShortTitleToDisplay: string;
  codeToDisplay: string;
  tagsToChip = [];

  constructor(@Inject('CONSTANTS') protected CONSTANTS, protected translate: TranslateService, private tasksService: TasksService) {}

  ngOnInit() {
    this.tagsToChip = [...this.task.tags, ...this.task.defaultTags];

    if (this.functionShortTitle) {
      this.functionShortTitleToDisplay = this.functionShortTitle.substring(0, 3);
    }
    this.codeToDisplay = this.task.code.substring(0, 3);
  }

  public goToTask(taskObjectdId): void {
    this.clickOnItem.emit(taskObjectdId);
  }

  markAsDone(nextInfo: nextInfoType) {
    if (!this.task.nextInfoTime || this.isReadOnly || !this.canMarkAsDone) {
      return;
    }
    this.task.nextInfoDone = nextInfo.nextInfoDone ? nextInfo.nextInfoDone : false;
    this.tasksService.save(
      { ...this.task },
      { notifications: [], functionToNotify: undefined },
      this.translate.instant('UPDATE_TASK_NEXT_INFO'),
    );
  }
}
