import { Component, Inject, OnInit } from '@angular/core';
import { OclEvent } from '../../models/ocl-event.model';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { HolNotification } from '../../../common/models/hol-notification.model';
import { OclEventService } from '../../services/ocl-event-service/ocl-event.service';
import { OclFlightStatusService } from '../../services/ocl-flight-status-service/ocl-flight-status.service';
import { ModuleConfigService } from '../../../common/services/module-config/module-config.service';

@Component({
  selector: 'app-event-close-modal',
  templateUrl: './ocl-event-close-modal.component.html',
  styleUrls: ['./ocl-event-close-modal.component.scss'],
})
export class OclEventCloseModalComponent implements OnInit {
  event: OclEvent;
  closeReason: number;
  reasonText: string;
  notifications: HolNotification[];

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    public dialogRef: MatDialogRef<OclEventCloseModalComponent>,
    private eventService: OclEventService,
    private flightsStatusService: OclFlightStatusService,
    public moduleConfig: ModuleConfigService,
  ) {
    this.event = data.event;
    this.notifications = data.notifications;
  }

  ngOnInit() {}

  closeEvent() {
    if (this.event.gocEvent) {
      this.eventService.deleteDuplicateGocEvent(this.event);
      this.dialogRef.close();
    } else if (this.event.occEvent) {
      this.eventService.deleteDuplicateOccEvent(this.event);
      this.dialogRef.close();
    } else {
      this.eventService.close(this.event, this.closeReason, this.reasonText, this.notifications).then(() => {
        this.flightsStatusService.setNeedsUpdate(true);
        if (this.data.hasToDeactivateECL) {
          this.eventService.deactivateECLModule(this.data.eclOptionsId);
        }
        this.dialogRef.close(this.closeReason);
      });
    }
  }
}
