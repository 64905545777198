import { Injectable } from '@angular/core';
import { OCLOptions, OclOptionsService } from '../../../ocl/services/ocl-options-service/ocl-options.service';

export class EclOptions extends OCLOptions {
  functionIdCrisisInitializer: string[];
  crisisInitializerCanActivate: boolean;
  functionIdForCrisisDirector: string[];
  functionIdForCEO: string[];
  functionIdForCrisisObservor: string[];
  hideEventModalCheckboxes = false;
  hideAssets = false;
  showSelectFunctionButtons = false;
  summaryTitleMaxLength = 64;
  summaryNoteMaxLine = 10;
  reopenLimitDays = 90;
  displayFunctionSelectionButton = false;

  constructor(parseObject: Parse.Object) {
    super(parseObject);
    this.crisisInitializerCanActivate = parseObject.get('crisisInitializerCanActivate');
    this.hideEventModalCheckboxes = !!parseObject.get('hideEventModalCheckboxes');
    this.hideAssets = !!parseObject.get('hideAssets');
    this.showSelectFunctionButtons = !!parseObject.get('showSelectFunctionButtons');
    this.summaryTitleMaxLength = parseObject.get('summaryTitleMaxLength') ? parseObject.get('summaryTitleMaxLength') : 64;
    this.summaryNoteMaxLine = parseObject.get('summaryNoteMaxLine') ? parseObject.get('summaryNoteMaxLine') : 10;
    this.reopenLimitDays = parseObject.get('reopenLimitDays') ? parseObject.get('reopenLimitDays') : 90;
    this.functionIdCrisisInitializer = parseObject.get('functionIdCrisisInitializer')
      ? parseObject.get('functionIdCrisisInitializer').split('|')
      : [];
    this.functionIdForCrisisDirector = parseObject.get('holFunctionIdForCrisisDirector')
      ? parseObject.get('holFunctionIdForCrisisDirector').split('|')
      : [];
    this.functionIdForCEO = parseObject.get('functionIdForCEO') ? parseObject.get('functionIdForCEO').split('|') : [];
    this.functionIdForCrisisObservor = parseObject.get('functionIdForCrisisObservor')
      ? parseObject.get('functionIdForCrisisObservor').split('|')
      : [];

    this.displayFunctionSelectionButton = !!parseObject.get('displayFunctionSelectionButton');
  }
}

@Injectable({
  providedIn: 'root',
})
export class EclOptionsService extends OclOptionsService<EclOptions> {
  // tslint:disable-next-line:variable-name
  protected ParseOptions = Parse.Object.extend('ECLOptions');

  getFunctionIdForCEO(): string[] {
    return this.options && this.options.functionIdForCEO;
  }

  getFunctionIdForCrisisObservor(): string[] {
    return this.options && this.options.functionIdForCrisisObservor;
  }

  getFunctionIdEclCrisisInitializer(): string[] {
    return this.options && this.options.functionIdCrisisInitializer;
  }

  getFunctionIdCrisisForCrisisDirector(): string[] {
    return this.options && this.options.functionIdForCrisisDirector;
  }

  getCrisisInitializerCanActivate(): boolean {
    return this.options && this.options.crisisInitializerCanActivate;
  }

  getHideEventModalCheckboxes(): boolean {
    return !!(this.options && this.options.hideEventModalCheckboxes);
  }

  getHideAssets(): boolean {
    return !!(this.options && this.options.hideAssets);
  }

  getShowSelectFunctionButtons(): boolean {
    return !!(this.options && this.options.showSelectFunctionButtons);
  }

  getFastTypingModal(): boolean {
    return this.options && this.options.fastTypingModal;
  }

  getReopenLimitDays(): number {
    return (this.options && this.options.reopenLimitDays) || 90;
  }

  getOptions(): EclOptions {
    return this.options;
  }

  getDisplayFunctionSelectionButton(): boolean {
    return this.options && this.options.displayFunctionSelectionButton;
  }

  async updateEclOptionsFunction(options: EclOptions): Promise<EclOptions> {
    this.options.hideAssets = !options.hideAssets;
    const parseOptions = new this.ParseOptions({ id: options.objectId });
    parseOptions.set('logbooksTitle', options.logbooksTitle);
    parseOptions.set('decisionsTitle', options.decisionsTitle);
    parseOptions.set('eventsTitle', options.eventsTitle);
    parseOptions.set('managersTitle', options.managersTitle);
    parseOptions.set('isNextInfoMandatory', options.isNextInfoMandatory);
    parseOptions.set('defaultNextInfoDelay', options.defaultNextInfoDelay);
    parseOptions.set('utcDisplay', options.utcDisplay);
    parseOptions.set('historyTimeSlot', +options.historyTimeSlot);
    parseOptions.set('isGlobalInstructionActivated', options.isGlobalInstructionActivated);
    parseOptions.set('hideAssets', options.hideAssets);
    parseOptions.set('hiddenButtons', options.hiddenButtons);
    parseOptions.set('holFunctionIdForCrisisDirector', options.functionIdForCrisisDirector.join('|'));
    parseOptions.set('functionIdCrisisInitializer', options.functionIdCrisisInitializer.join('|'));
    parseOptions.set('functionIdForCrisisObservor', options.functionIdForCrisisObservor.join('|'));
    parseOptions.set('functionIdForCEO', options.functionIdForCEO.join('|'));
    parseOptions.set('summaryNoteMaxLine', options.summaryNoteMaxLine);
    parseOptions.set('summaryTitleMaxLength', options.summaryTitleMaxLength);
    parseOptions.set('reopenLimitDays', options.reopenLimitDays);
    parseOptions.set('displayFunctionSelectionButton', options.displayFunctionSelectionButton);

    return this.requestService.performSaveQuery(parseOptions);
  }

  protected newOptionsObject(parser: Parse.Object): EclOptions {
    return new EclOptions(parser);
  }
}
