import { Action } from '@ngrx/store';
import { MccAircraft } from '../../models/mcc-aircraft.model';
import { MccMel } from '../../models/mcc-mel.model';
import { HolNextInfo } from '../../../common/models/hol-next-info.model';
import { MccEvent } from '../../models/mcc-event.model';
import { MccCabin } from '../../models/mcc-cabin.model';

export enum MccAircraftsActionTypes {
  UPDATE_AIRCRAFTS = '[MCC AIRCRAFTS] Update Aircrafts',
  UPDATE_OPE_STATUS = '[MCC AIRCRAFTS] Update Aircraft Operational status',
  UPDATE_MEL = '[MCC AIRCRAFTS] Update Mel',
  UPDATE_MEL_INFO = '[MCC AIRCRAFTS] Update Mel info',
  ADD_EVENT = '[MCC AIRCRAFTS] Add event',
  ADD_ITEM_CABIN = '[MCC AIRCRAFTS] Add item Cabin',
  UPDATE_EVENT = '[MCC AIRCRAFTS] Update event',
  UPDATE_ITEM_CABIN = '[MCC AIRCRAFTS] Update item Cabin',
  UPDATE_EVENT_INFO = '[MCC AIRCRAFTS] Update event info',
  REMOVE_EVENT = '[MCC AIRCRAFTS] Remove event',
  REMOVE_ITEM_CABIN = '[MCC AIRCRAFTS] Remove item cabin',
  UPDATE_ITEM_CABIN_INFO = '[MCC AIRCRAFTS] Update item Cabin info',
}

export class UpdateAircrafts implements Action {
  readonly type = MccAircraftsActionTypes.UPDATE_AIRCRAFTS;

  constructor(public payload: Array<MccAircraft>) {}
}

export class UpdateOperationalStatus implements Action {
  readonly type = MccAircraftsActionTypes.UPDATE_OPE_STATUS;

  constructor(public payload: MccAircraft) {}
}

export class UpdateMel implements Action {
  readonly type = MccAircraftsActionTypes.UPDATE_MEL;

  constructor(public payload: MccMel) {}
}

export class UpdateMelInfo implements Action {
  readonly type = MccAircraftsActionTypes.UPDATE_MEL_INFO;

  constructor(public payload: HolNextInfo) {}
}

export class AddEvent implements Action {
  readonly type = MccAircraftsActionTypes.ADD_EVENT;

  constructor(public payload: MccEvent) {}
}

export class UpdateEvent implements Action {
  readonly type = MccAircraftsActionTypes.UPDATE_EVENT;

  constructor(public payload: MccEvent) {}
}

export class RemoveEvent implements Action {
  readonly type = MccAircraftsActionTypes.REMOVE_EVENT;

  constructor(public payload: MccEvent) {}
}

export class AddItemCabin implements Action {
  readonly type = MccAircraftsActionTypes.ADD_ITEM_CABIN;

  constructor(public payload: MccCabin) {}
}

export class UpdateItemCabin implements Action {
  readonly type = MccAircraftsActionTypes.UPDATE_ITEM_CABIN;

  constructor(public payload: MccCabin) {}
}

export class UpdateItemCabinInfo implements Action {
  readonly type = MccAircraftsActionTypes.UPDATE_ITEM_CABIN_INFO;

  constructor(public payload: HolNextInfo) {}
}

export class RemoveItemCabin implements Action {
  readonly type = MccAircraftsActionTypes.REMOVE_ITEM_CABIN;

  constructor(public payload: MccCabin) {}
}

export class UpdateEventInfo implements Action {
  readonly type = MccAircraftsActionTypes.UPDATE_EVENT_INFO;

  constructor(public payload: HolNextInfo) {}
}

export type AircraftsActions =
  | UpdateAircrafts
  | UpdateOperationalStatus
  | UpdateMel
  | UpdateMelInfo
  | AddEvent
  | UpdateEvent
  | UpdateEventInfo
  | RemoveEvent
  | AddItemCabin
  | UpdateItemCabin
  | UpdateItemCabinInfo
  | RemoveItemCabin;
