import { OclGlobalInstruction } from './ocl-global-instruction.model';
import { OclGroup } from './ocl-group.model';
import { orderBy } from 'lodash';

export class OclGlobalInstructionGroup<T extends OclGlobalInstruction = OclGlobalInstruction> extends OclGroup<T> {
  constructor(parseObject?: Parse.Object) {
    super(parseObject);
    if (!parseObject) {
      return;
    }

    this.items =
      parseObject.get('globalInstructions') && parseObject.get('globalInstructions').length
        ? orderBy(
            parseObject.get('globalInstructions').map(gi => this.newItem(gi)),
            'createdAt',
            'desc',
          )
        : [];
  }

  public getMostRecentGlobalInstruction(): T {
    return this.items.length > 0 ? this.items[0] : null;
  }

  protected newItem(parseObject?: Parse.Object): T {
    return new OclGlobalInstruction(parseObject) as T;
  }
}
