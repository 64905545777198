import { Component, Input, Inject, Output, EventEmitter } from '@angular/core';
import { nextInfoType } from '../hol-next-info/hol-next-info.component';
import moment from 'moment';

@Component({
  selector: 'app-hol-next-info-display',
  templateUrl: './hol-next-info-display.component.html',
  styleUrls: ['./hol-next-info-display.component.scss'],
})
export class HolNextInfoDisplayComponent {
  @Input() isReadOnly = false;
  @Input() nextInfo: nextInfoType;
  @Input() canUpdate = false;
  @Input() isUtc: boolean;

  @Output() saved = new EventEmitter<nextInfoType>();

  constructor(@Inject('CONSTANTS') protected CONSTANTS) {}

  isElapsed(date): boolean {
    return date ? moment(date).isBefore(moment()) : false;
  }

  isAlmostElapsed(date): boolean {
    return date ? moment(date).diff(moment(), 'minutes') < this.CONSTANTS.TIME_BEFORE_WARNING : false;
  }

  markAsDone($event) {
    $event.stopPropagation();
    this.nextInfo.nextInfoDone = !this.nextInfo.nextInfoDone ? true : false;
    this.saved.emit(this.nextInfo);
  }
}
