import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AppState } from 'src/app/store';
// tslint:disable-next-line: max-line-length
import {
  InitDecisions,
  ResetDecisions,
  UpdateDecisionsFromPooling,
  AddOneDecision,
  UpdateOneDecision,
  UpdateDecisionsFromLinkedValue,
  RemoveDecisionsFromLinkedValue,
  RemoveAllDecisionsLinked,
  AddManyDecisions,
  UpdateManyDecisions,
  DeleteOneDecision,
} from './ocl-decisions.actions';
import { OclDecision } from '../../models/ocl-decision.model';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class OclDecisionsStoreManager {
  protected _decisionsState: Observable<OclDecision[]>;
  protected $currentOclModule: Observable<string>;

  protected constructor(protected store: Store<AppState>) {
    this._decisionsState = store.select('ocl', 'decisions');

    this.$currentOclModule = store.select('common', 'currentOclModule');
  }

  getValue(obj: Observable<any>) {
    let value: any;
    obj.subscribe(v => (value = v));
    return value;
  }

  get decisionsState(): Observable<OclDecision[]> {
    return this._decisionsState;
  }

  initDecisions(decisions: Array<OclDecision>): void {
    this.store.dispatch(new InitDecisions(decisions));
  }

  updateDecisionsFromPooling(decisions: Array<OclDecision>, currentModule): void {
    if (this.getValue(this.$currentOclModule) === currentModule) {
      this.store.dispatch(new UpdateDecisionsFromPooling(decisions));
    }
  }

  updateManyDecisions(decisions: Array<OclDecision>, currentModule): void {
    if (this.getValue(this.$currentOclModule) === currentModule) {
      this.store.dispatch(new UpdateManyDecisions(decisions));
    }
  }

  updateDecisionsFromLinkedValue(events: Array<OclDecision>, linkUid: string): void {
    this.store.dispatch(new UpdateDecisionsFromLinkedValue(events, linkUid));
  }

  removeDecisionsFromLinkedValue(linkUid: string): void {
    this.store.dispatch(new RemoveDecisionsFromLinkedValue(linkUid));
  }

  removeAllDecisionsLinked(): void {
    this.store.dispatch(new RemoveAllDecisionsLinked());
  }

  addOneDecision(decision: OclDecision): void {
    this.store.dispatch(new AddOneDecision(decision));
  }

  addManyDecisions(decisions: OclDecision[]): void {
    this.store.dispatch(new AddManyDecisions(decisions));
  }

  updateOneDecision(decision: OclDecision): void {
    this.store.dispatch(new UpdateOneDecision(decision));
  }

  resetDecisions(): void {
    this.store.dispatch(new ResetDecisions());
  }

  deleteOneDecison(decisionObjectId: string): void {
    this.store.dispatch(new DeleteOneDecision(decisionObjectId));
  }

  get decisionList(): Observable<OclDecision[]> {
    return this._decisionsState;
  }
}
