import { inject, Inject, Injectable } from '@angular/core';

import { MailSenderService } from 'src/app/common/services/mail/mail-sender.service';
import { MailTemplateService } from 'src/app/common/services/mail/mail-template.service';

import { OclMailService } from '../../../ocl/services/ocl-mail-service/ocl-mail.service';
import { OpsModuleConfigService } from '../ops-module-config/ops-module-config.service';

import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class OpsMailService extends OclMailService {
  readonly translate: TranslateService = inject(TranslateService);

  protected constructor(
    protected readonly mailSenderService: MailSenderService,
    @Inject('CONSTANTS') protected CONSTANTS,
    protected mailTemplateService: MailTemplateService,
    protected moduleConfig: OpsModuleConfigService,
  ) {
    super(mailSenderService, CONSTANTS, mailTemplateService, moduleConfig);
    this.translate.onLangChange.subscribe(() => {
      this.SENDER_SUFFIX = this.translate.instant('MAIL.OPS.SENDER_SUFFIX') || 'OPS';
    });
  }
}
