import { Injectable } from '@angular/core';
import moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  private storage: Storage;
  private session_Storage: Storage;

  constructor() {
    this.storage = this.getLocalStorage();
    this.session_Storage = this.getSessionStorage();

    /*
    // Old AngularJS version
    try {
      $window.sessionStorage.setItem('test', '');
      $window.sessionStorage.removeItem('test');
      storage = new BrowserStorage($window.sessionStorage);
    } catch (err) {
      storage = new MemoryStorage();
    }
    */
  }

  public get localStorage(): Storage {
    return this.getLocalStorage();
  }

  public get sessionStorage(): Storage {
    return this.getSessionStorage();
  }

  public setUrlLocation(location) {
    this.storage.setItem('locationUrl', location);
  }

  public setPrevUrlLocation(location) {
    this.sessionStorage.setItem('prevLocationUrl', location);
  }

  public setUrlParams(params) {
    this.storage.setItem('locationParams', params);
  }

  public getUrlLocation() {
    return this.storage.getItem('locationUrl');
  }

  public getSessionUrlLocation() {
    return this.sessionStorage.getItem('locationUrl');
  }

  public getSessionPrevUrlLocation() {
    return this.sessionStorage.getItem('prevLocationUrl');
  }

  public getBufferLinkedUid() {
    return this.sessionStorage.getItem('bufferLinkedUid');
  }

  public setBufferLinkedUid(linkedUid) {
    this.sessionStorage.setItem('bufferLinkedUid', linkedUid);
  }

  public removeBufferLinkedUid() {
    this.sessionStorage.removeItem('bufferLinkedUid');
  }

  public getUrlParams() {
    return this.storage.getItem('locationParams');
  }

  public setOccDashboardLastDisplayTime(time) {
    this.storage.setItem('occDashboardLastDisplayTime', time);
  }

  public setEclDashboardLastDisplayTime(time) {
    this.storage.setItem('eclDashboardLastDisplayTime', time);
  }

  public setOpsDashboardLastDisplayTime(time) {
    this.storage.setItem('opsDashboardLastDisplayTime', time);
  }

  public setGocDashboardLastDisplayTime(time) {
    this.storage.setItem('gocDashboardLastDisplayTime', time);
  }

  public setErpDashboardLastDisplayTime(time) {
    this.storage.setItem('erpDashboardLastDisplayTime', time);
  }

  public getOccDashboardLastDisplayTime(): Date {
    return moment(this.storage.getItem('occDashboardLastDisplayTime'), moment.ISO_8601).toDate();
  }

  public getErpDashboardLastDisplayTime(): Date {
    return moment(this.storage.getItem('erpDashboardLastDisplayTime'), moment.ISO_8601).toDate();
  }

  public getEclCrisisSelectedId() {
    return this.sessionStorage.getItem('eclCrisisSelectedId');
  }

  // public getEclDashboardLastDisplayTime(): Date {
  //  return moment(this.storage.getItem('eclDashboardLastDisplayTime')).toDate();
  // }

  // public getOpsDashboardLastDisplayTime(): Date {
  //   return moment(this.storage.getItem('opsDashboardLastDisplayTime')).toDate();
  // }

  // public getGocDashboardLastDisplayTime(): Date {
  //  return moment(this.storage.getItem('gocDashboardLastDisplayTime')).toDate();
  // }

  public setEclCrisisSelectedId(id: string) {
    this.sessionStorage.setItem('eclCrisisSelectedId', id);
  }

  private getLocalStorage(): Storage {
    return localStorage;
  }

  private getSessionStorage(): Storage {
    return sessionStorage;
  }
}
