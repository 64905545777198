'use strict';

/**
 * @ngdoc overview
 * @name gestiondecriseApp
 * @description
 * # gestiondecriseApp
 *
 * Main module of the application.
 */
angular.module('gestiondecriseApp', [
  'ngAnimate',
  'ngCookies',
  'ngResource',
  'ngSanitize',
  'pascalprecht.translate',
  'ui.bootstrap',
  'ui.router',
  'ui.router.upgrade',
  'angularMoment',
  'ngMaterial',
  'monospaced.elastic',
  'ngPromiseExtras',
  'angular-js-xlsx',
  'ngRaven',
  'hc.marked'
]);
