/* eslint-disable no-restricted-syntax */
import { Inject, Injectable } from '@angular/core';
import { take } from 'rxjs/operators';

import { OccGroupService } from 'src/app/occ/services/occ-group-service/occ-group.service';
import { OccEventService } from 'src/app/occ/services/occ-event-service/occ-event.service';
import { OccLogbookService } from 'src/app/occ/services/occ-logbook-service/occ-logbook.service';
import { OccLinksService } from 'src/app/occ/services/occ-links-service/occ-links.service';
import { OccHistoryService } from 'src/app/occ/services/occ-history-service/occ-history.service';
import { EclGroupService } from 'src/app/ecl/services/ecl-group-service/ecl-group.service';
import { EclEventService } from 'src/app/ecl/services/ecl-event-service/ecl-event.service';
import { EclFlightStatusService } from 'src/app/ecl/services/ecl-flight-status-service/ecl-flight-status.service';
import { EclLogbookService } from 'src/app/ecl/services/ecl-logbook-service/ecl-logbook.service';
import { EclLinksService } from 'src/app/ecl/services/ecl-links-service/ecl-links.service';
import { EclHistoryService } from 'src/app/ecl/services/ecl-history-service/ecl-history.service';

import { OpsDecisionService } from 'src/app/ops/services/ops-decision-service/ops-decision.service';
import { OpsGroupService } from 'src/app/ops/services/ops-group-service/ops-group.service';
import { OpsEventService } from 'src/app/ops/services/ops-event-service/ops-event.service';
import { OpsFlightStatusService } from 'src/app/ops/services/ops-flight-status-service/ops-flight-status.service';
import { OpsLogbookService } from 'src/app/ops/services/ops-logbook-service/ops-logbook.service';
import { OpsLinksService } from 'src/app/ops/services/ops-links-service/ops-links.service';
import { OpsHistoryService } from 'src/app/ops/services/ops-history-service/ops-history.service';

import { OccGlobalInstructionService } from 'src/app/occ/services/occ-global-instruction-service/occ-global-instruction.service';
import { OpsGlobalInstructionService } from 'src/app/ops/services/ops-global-instruction-service/ops-global-instruction.service';
import { EclGlobalInstructionService } from 'src/app/ecl/services/ecl-global-instruction-service/ecl-global-instruction.service';
import { GocDecisionService } from 'src/app/goc/services/goc-decision-service/goc-decision.service';
import { GocFlightStatusService } from 'src/app/goc/services/goc-flight-status-service/goc-flight-status.service';
import { GocGlobalInstructionService } from 'src/app/goc/services/goc-global-instruction-service/goc-global-instruction.service';
import { GocGroupService } from 'src/app/goc/services/goc-group-service/goc-group.service';
import { GocHistoryService } from 'src/app/goc/services/goc-history-service/goc-history.service';
import { GocLinksService } from 'src/app/goc/services/goc-links-service/goc-links.service';
import { GocLogbookService } from 'src/app/goc/services/goc-logbook-service/goc-logbook.service';
import { GocEventService } from 'src/app/goc/services/goc-event-service/goc-event.service';
import { GocFlightService } from 'src/app/goc/services/goc-flight-service/goc-flight.service';

import { CrewGlobalInstructionService } from '../../../crew/services/crew-global-instruction.service';
import { EclDecisionService } from '../../../ecl/services/ecl-decision-service/ecl-decision.service';
import { EclTasksService } from '../../../ecl/services/ecl-tasks.service';
import { FltFlightService } from '../../../flt/services/flt-flight.service';
import { GocTasksService } from '../../../goc/services/goc-tasks.service';
import { MccAircraftService } from '../../../mcc/services/mcc-aircraft.service';
import { OccDecisionService } from '../../../occ/services/occ-decision-service/occ-decision.service';
import { OccFlightStatusService } from '../../../occ/services/occ-flight-status-service/occ-flight-status.service';
import { OccTasksService } from '../../../occ/services/occ-tasks.service';
import { OpsFlightService } from '../../../ops/services/ops-flight-service/ops-flight.service';
import { OpsTasksService } from '../../../ops/services/ops-tasks.service';
import { CommonStoreManager } from '../../store/common.store-manager';
import { CrisisService } from '../crisis/crisis.service';
import { HolUserFunctionService } from '../hol-user-function.service';

import { BreakingNewsService } from '../breaking-news.service';

import { RequestService } from '../request.service';
import { UserService } from '../user.service';
import { VacationService } from '../vacation.service';
import { EclCrisisService } from '../../../ecl/services/ecl-crisis-service/ecl-crisis.service';
import { EclAnnoucementService } from 'src/app/ecl/services/ecl-annoucement.service';
import { EclAssetService } from '../../../ecl/services/ecl-asset-service/ecl-asset.service';
import { EclSummaryService } from '../../../ecl/services/ecl-summary-service/ecl-summary-service';
import { EclTagsService } from '../../../ecl/services/ecl-tags-service/ecl-tags.service';
import { EclUsersService } from '../../../ecl/services/ecl-users-service/ecl-users.service';
import moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class PollingService {
  public lastDatePooling?: Date = null;
  // tslint:disable:no-console
  private timer = null;
  private mustRestartTimerOnPageFocused = false;
  private mustPlanNextPool = true;
  private lastUnivers = '';

  constructor(
    @Inject('CONSTANTS') private CONSTANTS,
    private requestService: RequestService,
    @Inject('$state') private $state,
    private userService: UserService,
    private breakingNewsService: BreakingNewsService,
    @Inject('CrewEventsService') private crewEventsService,
    @Inject('CrewSituationsService') private crewSituationsService,
    @Inject('CrewTeamService') private crewTeamService,
    @Inject('CrewHistoryService') private crewHistoryService,
    private crewGlobalInstructionService: CrewGlobalInstructionService,
    private mccAirCraftService: MccAircraftService,
    private flightService: FltFlightService,
    private opsFlightService: OpsFlightService,
    private vacationService: VacationService,
    private occDecisionService: OccDecisionService,
    private occGlobalInstructionService: OccGlobalInstructionService,
    private occGroupService: OccGroupService,
    private occEventService: OccEventService,
    private occFlightStatusService: OccFlightStatusService,
    private occLogbookService: OccLogbookService,
    private holUserFunctionService: HolUserFunctionService,
    private occTasksService: OccTasksService,
    private occLinksService: OccLinksService,
    private occHistoryService: OccHistoryService,
    private eclCrisisService: EclCrisisService,
    private eclDecisionService: EclDecisionService,
    private eclGlobalInstructionService: EclGlobalInstructionService,
    private eclGroupService: EclGroupService,
    private eclEventService: EclEventService,
    private eclFlightStatusService: EclFlightStatusService,
    private eclLogbookService: EclLogbookService,
    private eclTasksService: EclTasksService,
    private eclLinksService: EclLinksService,
    private eclHistoryService: EclHistoryService,
    private eclUserService: EclUsersService,
    private eclAssetService: EclAssetService,
    private opsDecisionService: OpsDecisionService,
    private opsGlobalInstructionService: OpsGlobalInstructionService,
    private opsGroupService: OpsGroupService,
    private opsEventService: OpsEventService,
    private opsFlightStatusService: OpsFlightStatusService,
    private opsLogbookService: OpsLogbookService,
    private opsTasksService: OpsTasksService,
    private opsLinksService: OpsLinksService,
    private opsHistoryService: OpsHistoryService,
    // private gocDashboardService: GocDashboardService,
    // private gocEventService: GocEventService,
    // private gocAgendaService: GocAgendaService
    private gocDecisionService: GocDecisionService,
    private gocGlobalInstructionService: GocGlobalInstructionService,
    private gocGroupService: GocGroupService,
    private gocEventService: GocEventService,
    private gocFlightStatusService: GocFlightStatusService,
    private gocLogbookService: GocLogbookService,
    private gocTasksService: GocTasksService,
    private gocLinksService: GocLinksService,
    private gocHistoryService: GocHistoryService,
    private gocFlightService: GocFlightService,
    private crisisService: CrisisService,
    private commonStoreManager: CommonStoreManager,
    private eclAnnoucementService: EclAnnoucementService,
    private eclSummaryService: EclSummaryService,
    private tagsService: EclTagsService,
  ) {
    if (this.CONSTANTS.POOL_ENABLED === true) {
      this.lastDatePooling = moment().subtract(1, 'days').toDate();
      document.addEventListener('visibilitychange', this.visibilityChange.bind(this));
    }
  }

  public startLongPooling() {
    if (this.CONSTANTS.POOL_ENABLED !== true) {
      console.debug('[PollingService] Service disabled');
      return;
    }

    console.debug('[PollingService] Start pooling service');
    this._planNextPool();
  }

  public stopIt() {
    if (this.CONSTANTS.POOL_ENABLED !== true) {
      return;
    }

    console.debug('[PollingService] Stop pooling service');
    this.mustRestartTimerOnPageFocused = false;
    this.mustPlanNextPool = false;
    this._stopIt();
  }

  private visibilityChange() {
    if (document.hidden) {
      this.lastDatePooling = null;
      if (this._isPollingRunning()) {
        console.debug('[PollingService] Stop pooling on page blurred');

        // Indicate we must restart timer on page focused
        this.mustRestartTimerOnPageFocused = true;

        // Stop the polling
        this._stopIt();
      }
    } else {
      if (this.mustRestartTimerOnPageFocused === true) {
        console.debug('[PollingService] Restart pooling on page focused');

        this.mustRestartTimerOnPageFocused = false;

        this._planNextPool();
      }
    }
  }

  private _isPollingRunning() {
    return this.timer !== null;
  }

  private async _pool() {
    const queriesCount = this.requestService.getQueriesCount();
    if (queriesCount > 0) {
      console.debug(`[PollingService] ==> No polling because there are '${queriesCount}' requests in progress`);
      this._planNextPool();
      return;
    }

    console.debug('[PollingService] Start pooling...');

    this.mustPlanNextPool = true;

    const filterDataStartDate = await this.commonStoreManager.filterDataStartDate
      .pipe(take(1))
      .toPromise()
      .then(filterDataStartDate => filterDataStartDate);
    const promises = [];

    promises.push(this.crisisService.get().then(c => this.commonStoreManager.initErpCrisis(c)));
    // console.log('ERP MESS', this.crisis.announcements);
    promises.push(
      /*
      this.eclCrisisService.getCurrentOrLastCrisis().then(async c => {
        let lastCrisis = await this.commonStoreManager.lastEclCrisis.pipe(take(1)).toPromise();
        if (c.objectId !== lastCrisis.objectId) {
          this.commonStoreManager.initLastEclCrisis(c);
        }
      }),
      */
      this.eclCrisisService.getAll().then(async c => {
        if (c && c.length > 0) {
          const lastCrisisInBDD = c[0];
          let lastCrisis = await this.commonStoreManager.lastEclCrisis.pipe(take(1)).toPromise();
          if (lastCrisisInBDD.objectId !== lastCrisis.objectId) {
            this.commonStoreManager.initLastEclCrisis(lastCrisisInBDD);
          }
        }
      }),
    );
    promises.push(this.holUserFunctionService.getAll());
    if (this.$state.includes('app')) {
      promises.push(this.eclAnnoucementService.fetchNewData());
    }
    if (this.$state.includes('app.crisis')) {
      this.lastUnivers = 'app.crisis';
      // ERP
      promises.push(this.breakingNewsService.fetchNewData('crisis'));
    }

    if (this.$state.includes('app.occ')) {
      this.lastUnivers = 'app.occ';
      // OCC
      promises.push(this.flightService.getAllFromYesterday());
      promises.push(this.breakingNewsService.fetchNewData('occ'));
      if (this.$state.includes('app.occ.dashboard')) {
        promises.push(this.occDecisionService.fetchNewData(true));
        promises.push(this.occGlobalInstructionService.fetchNewData(true));
        promises.push(this.occGroupService.fetchNewDataEventGroup());
        promises.push(this.occGroupService.fetchNewDataLogBookGroup());
        promises.push(this.occGroupService.fetchNewDataDecisionGroup());
        promises.push(this.occGroupService.fetchNewDataGlobalInstructionGroup());
        promises.push(this.occEventService.fetchNewData());
        promises.push(this.occFlightStatusService.fetchNewData());
        promises.push(this.occLogbookService.fetchNewData(true));

        this.occLinksService.fetchNewData();
      }
      if (this.$state.includes('app.occ.history')) {
        promises.push(this.occHistoryService.fetchNewData());
      }
      if (this.$state.includes('app.occ.tasks')) {
        promises.push(this.occEventService.fetchNewCheckListData());
        promises.push(this.userService.fetchNewData());
        promises.push(this.vacationService.initVacationsByModule('occ'));
        promises.push(this.occTasksService.initTasksStore());
      }
    }

    if (this.$state.includes('app.ecl')) {
      if (this.lastUnivers != 'app.ecl') {
        this.lastUnivers = 'app.ecl';
        this.lastDatePooling = null;
      }

      /*
      if (this.lastDatePooling != null) {
        await this.requestService.performCloudCode('getPolling', this.lastDatePooling, (res) => {
          if (res && res['ecl']) {
            if (res['ecl']['crisis']) {
              this.eclCrisisService.lastUpdatedCrisisFromPolling(res['ecl']['crisis']);
            }
            if (res['ecl']['logBook']) {
              console.log('Polling ecl logbook', res['ecl']['logBook']);
              this.eclLogbookService.lastUpdatedLogBookFromPolling(res['ecl']['logBook']);
            }
          }
        }, error => {
          this.lastDatePooling = null;
        });
      } else {
        promises.push(this.eclCrisisService.fetchNewData(this.lastDatePooling));
        promises.push(this.eclLogbookService.fetchNewData(true));

      }

      */
      // ECL
      //no use
      // promises.push(this.flightService.getAllFromYesterday(this.lastDatePooling));

      promises.push(this.breakingNewsService.fetchNewData('ecl'));
      promises.push(this.eclUserService.getAllUserWithLastSeenUpdatedAfterLastPolling(this.lastDatePooling));
      promises.push(this.eclCrisisService.fetchNewData(this.lastDatePooling));
      promises.push(this.eclAssetService.fetchNewData(this.lastDatePooling));

      if (this.$state.includes('app.ecl.dashboard')) {
        promises.push(this.eclDecisionService.fetchNewData(true, this.lastDatePooling));
        promises.push(this.eclGlobalInstructionService.fetchNewData(true));
        //  promises.push(this.eclGroupService.fetchNewDataEventGroup());
        promises.push(this.eclGroupService.fetchNewDataLogBookGroup());
        promises.push(this.eclGroupService.fetchNewDataDecisionGroup());
        promises.push(this.eclGroupService.fetchNewDataGlobalInstructionGroup());
        //  promises.push(this.eclEventService.fetchNewData());
        promises.push(this.eclFlightStatusService.fetchNewData());
        promises.push(this.eclLogbookService.fetchNewData(true, this.lastDatePooling));
        promises.push(this.eclAnnoucementService.fetchNewData());
        promises.push(this.eclSummaryService.fetchNewData());

        promises.push(this.tagsService.fetchNewData());
        //  this.eclLinksService.fetchNewData();
      }
      if (this.$state.includes('app.ecl.history')) {
        promises.push(this.eclHistoryService.fetchNewData());
      }
      if (this.$state.includes('app.ecl.tasks')) {
        promises.push(this.eclEventService.fetchNewCheckListData());
        promises.push(this.userService.fetchNewData());
        promises.push(this.vacationService.initVacationsByModule('ecl'));
        promises.push(this.eclTasksService.initTasksStore());
      }
    }

    if (this.$state.includes('app.ops')) {
      this.lastUnivers = 'app.ops';
      // OPS
      promises.push(this.opsFlightService.getAllFromYesterday(filterDataStartDate));
      promises.push(this.breakingNewsService.fetchNewData('ops'));
      if (this.$state.includes('app.ops.dashboard')) {
        promises.push(this.opsDecisionService.fetchNewData(true, filterDataStartDate));
        promises.push(this.opsGlobalInstructionService.fetchNewData(true));
        promises.push(this.opsGroupService.fetchNewDataEventGroup());
        promises.push(this.opsGroupService.fetchNewDataLogBookGroup());
        promises.push(this.opsGroupService.fetchNewDataDecisionGroup());
        promises.push(this.opsGroupService.fetchNewDataGlobalInstructionGroup());
        promises.push(this.opsEventService.fetchNewData(filterDataStartDate));
        promises.push(this.opsFlightStatusService.fetchNewData());
        promises.push(this.opsLogbookService.fetchNewData(true, filterDataStartDate));
        this.opsLinksService.fetchNewData();
      }
      if (this.$state.includes('app.ops.history')) {
        promises.push(this.opsHistoryService.fetchNewData());
      }
      if (this.$state.includes('app.ops.tasks')) {
        promises.push(this.opsEventService.fetchNewCheckListData());
        promises.push(this.userService.fetchNewData());
        promises.push(this.vacationService.initVacationsByModule('ops'));
        promises.push(this.opsTasksService.initTasksStore());
      }
    }

    if (this.$state.includes('app.crew')) {
      this.lastUnivers = 'app.crew';
      promises.push(this.flightService.getAllFromYesterday());
      promises.push(this.breakingNewsService.fetchNewData('crew'));
      if (this.$state.includes('app.crew.dashboard')) {
        promises.push(this.crewEventsService.fetchAllEvents());
        promises.push(this.crewSituationsService.fetchAllTodos());
        promises.push(this.crewGlobalInstructionService.fetchNewData(true));
      }
      if (this.$state.includes('app.crew.checklist')) {
        promises.push(this.crewEventsService.fetchAllEvents());
        promises.push(this.vacationService.initVacationsByModule('crew'));
      }
      if (this.$state.includes('app.crew.team')) {
        promises.push(this.crewTeamService.fetchAllFunctions());
      }
      if (this.$state.includes('app.crew.history')) {
        promises.push(this.crewHistoryService.fetchNewData());
      }
    }
    if (this.$state.includes('app.mcc')) {
      this.lastUnivers = 'app.mcc';
      promises.push(this.flightService.getAllFromYesterday());
      promises.push(this.breakingNewsService.fetchNewData('mcc'));
      if (this.$state.includes('app.mcc.dashboard')) {
        promises.push(this.mccAirCraftService.getAll());
      }
      if (this.$state.includes('app.mcc.checklist')) {
        promises.push(this.vacationService.initVacationsByModule('mcc'));
      }
    }
    if (this.$state.includes('app.goc')) {
      this.lastUnivers = 'app.goc';
      // GOC
      promises.push(this.gocFlightService.getAllFromYesterday(filterDataStartDate));
      promises.push(this.breakingNewsService.fetchNewData('goc'));
      if (this.$state.includes('app.goc.dashboard')) {
        promises.push(this.gocDecisionService.fetchNewData(true, filterDataStartDate));
        promises.push(this.gocGlobalInstructionService.fetchNewData(true));
        promises.push(this.gocGroupService.fetchNewDataEventGroup());
        promises.push(this.gocGroupService.fetchNewDataLogBookGroup());
        promises.push(this.gocGroupService.fetchNewDataDecisionGroup());
        promises.push(this.gocGroupService.fetchNewDataGlobalInstructionGroup());
        promises.push(this.gocEventService.fetchNewData(filterDataStartDate));
        promises.push(this.gocFlightStatusService.fetchNewData());
        promises.push(this.gocLogbookService.fetchNewData(true, filterDataStartDate));
        this.gocLinksService.fetchNewData();
      }
      if (this.$state.includes('app.goc.history')) {
        promises.push(this.gocHistoryService.fetchNewData());
      }
      if (this.$state.includes('app.goc.tasks')) {
        promises.push(this.gocEventService.fetchNewCheckListData());
        promises.push(this.userService.fetchNewData());
        promises.push(this.vacationService.initVacationsByModule('goc'));
        promises.push(this.gocTasksService.initTasksStore());
      }
    }
    Promise.all(promises.map(p => p.catch(e => e))).then(() => {
      console.debug('[PollingService] All pooling finished!');
      this.lastDatePooling = new Date();
      this.userService.updateLastSeenDateForCurrentUser();
      if (this.mustPlanNextPool) {
        this._planNextPool();
      }
    });
  }

  private _planNextPool() {
    if (this.CONSTANTS.POOL_ENABLED !== true) {
      return;
    }

    this._stopIt();
    console.debug('[PollingService] First ============================================================');
    //  this._pool().then(r => {
    this.timer = setTimeout(() => {
      console.debug('[PollingService] Timer ============================================================');
      this._pool();
    }, this.CONSTANTS.POOL_DELAY);
    //   });
  }

  private _stopIt() {
    if (this.CONSTANTS.POOL_ENABLED !== true) {
      return;
    }

    if (this._isPollingRunning()) {
      clearTimeout(this.timer);
      this.timer = null;
      this.lastDatePooling = null;
    }
  }
}
