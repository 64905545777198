import { Component, inject, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, combineLatest, Subject, Subscription } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

import { MatDialog } from '@angular/material/dialog';

import { CommonStoreManager } from 'src/app/common/store/common.store-manager';
import { HolNotification } from 'src/app/common/models/hol-notification.model';
import { TagChangeService } from 'src/app/common/services/tag-change.service';
import { HolTag } from 'src/app/common/models/hol-tag';

import { FlyIn } from '../../../common/models/hol-animations';
import { ModuleConfigService } from '../../../common/services/module-config/module-config.service';
import { RolesService } from '../../../common/services/roles.service';
import { OPSLayoutInfos } from '../../../common/store/common.model';
import { OclCriticalityMatrixModalComponent } from '../../modals/ocl-criticality-matrix-modal/ocl-criticality-matrix-modal.component';
import { OCL_DECISIONS_MODAL_TOKEN } from '../../modals/ocl-decisions-modal/ocl-decisions-modal.token';
import { OclGroupModalComponent } from '../../modals/ocl-group-modal/ocl-group-modal.component';
import { OclDecisionGroup } from '../../models/ocl-decision-group.model';
import { OclDecision } from '../../models/ocl-decision.model';
import { OclDecisionService } from '../../services/ocl-decision-service/ocl-decision.service';
import { OclFlightStatusService } from '../../services/ocl-flight-status-service/ocl-flight-status.service';
import { OclGroupService } from '../../services/ocl-group-service/ocl-group.service';
import { OclNotificationsService } from '../../services/ocl-notifications-service/ocl-notifications.service';
import { OclOptionsService } from '../../services/ocl-options-service/ocl-options.service';
import { OclDecisionsStoreManager } from '../../store/decisions/ocl-decisions.store-manager';
import { cloneDeep, extend, orderBy } from 'lodash';
import { HolUTCDisplay, utcEval } from '../../../common/enums/hol-utc-display.enum';
import { MatDialogRef } from '@angular/material/dialog';
import { OclDecisionsListItemComponent } from '../ocl-decisions-list-item/ocl-decisions-list-item.component';
import { EclDecisionGroup } from '../../../ecl/models/ecl-decision-group.model';
import { EclDecision } from '../../../ecl/models/ecl-decision';
import { OclLogBooksStoreManager } from '../../store/logbooks/ocl-log-books-store-manager.service';
import { OclLogbook } from '../../models/ocl-logbook.model';
import { EclLogbook } from '../../../ecl/models/ecl-logbook';
import { TranslateService } from '@ngx-translate/core';
import { OclGroup } from '../../models/ocl-group.model';

@Component({
  selector: 'app-ocl-status-decisions-box',
  templateUrl: './ocl-status-decisions-box.component.html',
  styleUrls: ['./ocl-status-decisions-box.component.scss'],
  animations: [FlyIn],
})
export class OclStatusDecisionsBoxComponent implements OnInit, OnDestroy {
  @Input() isReadOnly;
  @Input() isSummaryActivated? = false;
  @Input() utcDisplay: HolUTCDisplay;
  @Input() logBookItems;
  @Input() events;
  @Input() linkedIds;
  @Input() showOnlyIsPinned;
  @Input() airportsCode;
  @Input() isItemsRequired?: boolean = true;
  @Input() showLoadMore = true;
  @Input() userCanEditCards?: boolean;
  public modeLinkIsActivate = false;
  public loading = false;
  public decisionsGrouped: OclDecisionGroup[] = [];
  public decisionItems: OclDecision[] = [];
  public groupsFromApi: OclDecisionGroup[] = [];
  public emptyDecisionsGroup: OclDecisionGroup[] = [];
  public mostCriticalDecisionInfo: OclDecision;
  public notifications: HolNotification[];
  public searchCriteriasForPipe: string;
  public context = 'decision';
  public currentFlightStatus = undefined;
  public flightsStatusHistory;
  public decisionTitle: string = '';
  public decisionItemsSub: Subscription;
  public decisionGroupsSub: Subscription;
  public RolesService = RolesService;
  public filterDataStartDate: Date = undefined;
  unregisterPoolServiceStatus = this.$scope.$on('occPoolService-flight-status', (e, flightStatus) => {
    if (flightStatus.length) {
      this.currentFlightStatus = flightStatus[flightStatus.length - 1];
    }
  });
  public logbooksSubscription: Subscription;
  public logbooks: OclLogbook[];
  private groupsAvailable: OclDecisionGroup[] = [];
  private currentTags: HolTag[];
  private ngUnsubscribe: Subject<void> = new Subject();
  private _decisionItems = new BehaviorSubject<OclDecision[]>([]);
  private _decisionGroups = new BehaviorSubject<OclDecisionGroup[]>([]);
  private _modeLinkIsActivate = new BehaviorSubject<boolean>(false);
  private storeLayoutInfos: OPSLayoutInfos;

  protected readonly translate = inject(TranslateService);

  constructor(
    public decisionsService: OclDecisionService,
    private logbookStoreManager: OclLogBooksStoreManager,
    protected flightsStatusService: OclFlightStatusService,
    protected optionsService: OclOptionsService,
    @Inject('$scope') protected $scope,
    protected dialog: MatDialog,
    protected groupService: OclGroupService,
    protected notificationsService: OclNotificationsService,
    protected occDecisionsStoreManager: OclDecisionsStoreManager,
    public moduleConfig: ModuleConfigService,
    @Inject(OCL_DECISIONS_MODAL_TOKEN) private decisionModal,
    private commonStoreManager: CommonStoreManager,
    protected tagChangeService: TagChangeService,
  ) {
    this.decisionTitle = this.optionsService.getDecisionsTitle()
      ? this.optionsService.getDecisionsTitle()
      : this.translate.instant(this.moduleConfig.config.translateKey + '.DASHBOARD.STATUS_DECISIONS.TITLE');
  }

  get modeLinkIsActivateInput() {
    return this._modeLinkIsActivate.getValue();
  }

  @Input()
  set modeLinkIsActivateInput(value) {
    this._modeLinkIsActivate.next(value);
  }

  get decisionItemsInput() {
    return this._decisionItems.getValue();
  }

  @Input()
  set decisionItemsInput(value) {
    this._decisionItems.next(value);
  }

  get decisionGroupsInput() {
    return this._decisionGroups.getValue();
  }

  @Input()
  set decisionGroupsInput(value) {
    this._decisionGroups.next(value);
  }

  get searchCriteriasdata() {
    return this._searchCriterias.getValue();
  }

  private _searchCriterias = new BehaviorSubject<string>('');

  @Input()
  set searchCriterias(value) {
    this._searchCriterias.next(value);
  }

  get enableViewGroup(): boolean {
    return (
      (!this.currentTags || this.currentTags.length === 0) &&
      this.searchCriteriasForPipe.length === 0 &&
      (this.groupsAvailable.length || this.emptyDecisionsGroup) &&
      !this.modeLinkIsActivate
    );
  }

  get isSetGroups(): boolean {
    return this.enableViewGroup ? this.storeLayoutInfos.groups.decisionsGroupEnabled : false;
  }

  set isSetGroups(value: boolean) {
    this.commonStoreManager.updateLayoutGroups({
      decisionsGroupEnabled: value,
    });
  }

  public get localHourOnly(): boolean {
    return this.utcDisplay === HolUTCDisplay.FORCE_LOCAL;
  }

  public get isUtc(): boolean {
    return utcEval(this.utcDisplay, this.isReadOnly);
  }

  ngOnInit(): void {
    this.notificationsService.getAll().then(notif => (this.notifications = notif));

    this.getAll();

    combineLatest([this._decisionItems, this._decisionGroups])
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(([decisionItems, decisionGroups]) => {
        this.decisionItems = decisionItems ? cloneDeep(decisionItems) : [];

        (this.groupsFromApi = decisionGroups ? decisionGroups : []),
          (this.groupsAvailable =
            decisionGroups && decisionGroups.length
              ? this.groupService.generateGroupAvailable(cloneDeep(decisionGroups), this.decisionItems)
              : []);
        this.decisionsGrouped = this.generateDecisionsGrouped(this.groupsAvailable, this.decisionItems);
        this.emptyDecisionsGroup = decisionGroups.filter(o => !o.items.length) || [];
      });

    this.tagChangeService.tagsChanged.pipe(takeUntil(this.ngUnsubscribe)).subscribe(tags => (this.currentTags = cloneDeep(tags)));

    this._modeLinkIsActivate.pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
      this.modeLinkIsActivate = cloneDeep(res);
    });

    this._searchCriterias.pipe(takeUntil(this.ngUnsubscribe)).subscribe(cristerias => {
      this.searchCriteriasForPipe = cloneDeep(cristerias);
    });

    this.commonStoreManager.filterDataStartDate.pipe(takeUntil(this.ngUnsubscribe)).subscribe(filterDataStartDate => {
      if (this.moduleConfig.config.canChooseDataStartDate) {
        this.filterDataStartDate = filterDataStartDate;
      }
    });

    this.commonStoreManager.layoutInfos
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(layoutInfosData => (this.storeLayoutInfos = layoutInfosData));

    this.logbooksSubscription = this.logbookStoreManager.logbookList.pipe().subscribe(logbooks => {
      this.logbooks = logbooks;
    });
  }

  ngOnDestroy(): void {
    this.unregisterPoolServiceStatus();
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  getAll() {
    this.flightsStatusService.getHistory().then(data => {
      this.flightsStatusHistory = data;
      if (data.length) {
        this.currentFlightStatus = data[data.length - 1];
      }
    });
  }

  public openAddModal(event): void {
    event.stopPropagation();
    const dialogRef = this.dialog.open(this.decisionModal, {
      data: {
        notifications: this.notifications,
        events: this.events,
        logBookItems: this.logBookItems,
        isUtc: utcEval(this.utcDisplay, this.isReadOnly),
        decision: null,
        modeLinkIsActivate: this.modeLinkIsActivate,
        airportsCode: this.airportsCode,
        isSummaryActivated: this.isSummaryActivated,
      },
    });
    dialogRef.afterClosed().subscribe(decision => {
      if (decision) {
        this.flightsStatusService.setNeedsUpdate(true);
      }
    });
  }

  public openGroupModal(): void {
    const dialogRef = this.dialog.open(OclGroupModalComponent, {
      data: {
        decisionItems: this.decisionItems,
        events: null,
        logBookItems: null,
        isReadOnly: this.isReadOnly,
        group: null,
        groupsFromApi: this.groupsFromApi,
        type: 'DECISION',
        isItemsRequired: this.isItemsRequired,
        isSummaryActivated: this.isSummaryActivated,
      },
    });
    dialogRef.afterClosed().subscribe(group => {
      this.groupService.fetchNewDataDecisionGroup();
    });
  }

  openCriticityMatrix($event) {
    this.dialog.open(OclCriticalityMatrixModalComponent, {
      data: {
        flightStatus: this.currentFlightStatus,
        isReadOnly: this.isReadOnly,
      },
    });
  }

  markAsDone(item, $event) {
    $event.stopPropagation();
    this.decisionsService.markAsDone(item, !item.done).then(eventInfo => {
      // STORE
      this.occDecisionsStoreManager.updateOneDecision(extend(item, eventInfo));
      this.flightsStatusService.setNeedsUpdate(true);
    });
  }

  loadMore() {
    this.loading = true;

    this.decisionsService.getAll(true, true, false, false, this.filterDataStartDate).then(newDecisions => {
      this.occDecisionsStoreManager.addManyDecisions(newDecisions);
      this.loading = false;
    });
  }

  findAndOpenDecision(decision: EclDecision) {
    const dialogRef = this.dialog.open(this.decisionModal, {
      data: {
        notifications: this.notifications,
        events: this.events,
        logBookItems: this.logBookItems,
        isUtc: this.isUtc,
        decision: cloneDeep(decision),
        isReadOnly: this.isReadOnly,
        modeLinkIsActivate: this.modeLinkIsActivate,
        airportsCode: this.airportsCode,
        itemComponent: OclDecisionsListItemComponent,
        isHistory: false,
        isSummaryActivated: this.isSummaryActivated,
      },
    });
    dialogRef.afterClosed().subscribe(decision => {
      if (decision) {
        this.flightsStatusService.setNeedsUpdate(true);
      }
    });
  }

  async findAndOpenDecisionGroup(decisionGroup: EclDecisionGroup, selectedCrisisId: string) {
    const allDecisions = await this.occDecisionsStoreManager.decisionList.pipe(take(1)).toPromise();
    const decisionsLogbooks = allDecisions.filter(d => (d as EclDecision).crisis.objectId == selectedCrisisId);

    const dialogRef = this.dialog.open(OclGroupModalComponent, {
      data: {
        decisionItems: decisionsLogbooks,
        events: null,
        logBookItems: null,
        globalInstructionItems: null,
        isReadOnly: this.isReadOnly,
        group: this.groupsAvailable.find(g => g.objectId === decisionGroup.objectId) || cloneDeep(decisionGroup),
        groupsFromApi: this.groupsFromApi,
        type: 'DECISION',
        isItemsRequired: this.isItemsRequired,
        isSummaryActivated: this.isSummaryActivated,
        selectedCrisisId: selectedCrisisId,
      },
    });
    dialogRef.afterClosed().subscribe(group => {
      if (group) {
        this.groupService.fetchNewDataLogBookGroup().then(value => {});
      }
    });
  }

  trackByFn(index, item) {
    return item.objectId || item.items[0].objectId;
  }

  public hideOptionStatus() {
    return this.optionsService.getHideOptionsStatus();
  }

  private generateDecisionsGrouped(group: OclDecisionGroup[], decisions: OclDecision[]) {
    return orderBy(
      this.groupService.generateGroupForDisplay(group, decisions, 'DECISION'),
      item => {
        if ((item as OclDecisionGroup).getMostRecentItem) {
          return (item as OclDecisionGroup).getMostRecentItem.createdAt;
        }
        return null;
      },
      'desc',
    );
  }
}
