'use strict';

/**
 * @ngdoc service
 * @name gestiondecriseApp.CrewUsersFunctionsService
 * @description
 * # CrewUsersFunctionsService
 * Service in the gestiondecriseApp.
 */
angular.module('gestiondecriseApp').service('CrewUsersFunctionsService', function ($q, RequestService) {
  const CrewUserFunction = Parse.Object.extend('CrewUserFunction');
  const CrewFunction = Parse.Object.extend('CrewFunction');

  this.getAll = function () {
    const deferred = $q.defer();

    RequestService.performCountQuery(
      new Parse.Query(CrewUserFunction),
      function (count) {
        const query = new Parse.Query(CrewUserFunction);
        query.limit(count);
        RequestService.performFindQuery(
          query,
          function (parseUFs) {
            deferred.resolve(_.map(parseUFs, _transformParseCrewUserFunctionToObject));
          },
          function (error) {
            deferred.reject(error);
          }
        );
      },
      deferred.reject
    );

    return deferred.promise;
  };

  this.getFunctionsForUser = function (user) {
    const deferred = $q.defer();
    const userFunctionQuery = new Parse.Query(CrewUserFunction);
    userFunctionQuery.equalTo('userId', user.userId);
    //userFunctionQuery.include('function');

    const functionQuery = new Parse.Query(CrewFunction);
    functionQuery.matchesKeyInQuery('code', 'code', userFunctionQuery);

    RequestService.performFindQuery(
      functionQuery,
      function (parseFunctions) {
        deferred.resolve(_.map(parseFunctions, _transformParseCrewFunctionToObject));
      },
      function (error) {
        deferred.reject(error);
      }
    );

    return deferred.promise;
  };

  var _transformParseCrewFunctionToObject = function (parseObject) {
    return {
      objectId: parseObject.id,
      code: parseObject.get('code'),
      label: parseObject.get('label'),
      isTechnique: parseObject.get('isTechnique')
    };
  };

  var _transformParseCrewUserFunctionToObject = function (parseObject) {
    return {
      objectId: parseObject.id,
      code: parseObject.get('code'),
      userId: parseObject.get('userId')
    };
  };
});
