import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';

import { CommonStoreManager } from 'src/app/common/store/common.store-manager';
import { HolTag } from 'src/app/common/models/hol-tag';
import { MarkdownService } from 'src/app/common/components/markdown-editor/markdown.service';
import { FilesService } from 'src/app/common/services/files.service';
import { startsWith } from 'lodash';

import { NotificationsService } from '../../../common/services/notifications/notifications.service';
import { ParseMapperService } from '../../../common/services/parse-mapper.service';
import { RequestService } from '../../../common/services/request.service';
import { FltApplicabilityService } from '../../../flt/services/flt-applicability.service';
import { FltDecisionService } from '../../../flt/services/flt-decision.service';
import { OclDecisionsStoreManager } from '../../../ocl/store/decisions/ocl-decisions.store-manager';
import { GocDecision } from '../../models/goc-decision.model';
import { GocFlight } from '../../models/goc-flight.model';
import { GocDecisionTagService } from '../goc-decision-tag-service/goc-decision-tag.service';
import { GocFlightInstructionService } from '../goc-flight-instruction-service/goc-flight-instruction.service';
import { GocFlightLogbookService } from '../goc-flight-logbook-service/goc-flight-logbook.service';
import { GocFlightService } from '../goc-flight-service/goc-flight.service';
import { GocHistoryService } from '../goc-history-service/goc-history.service';
import { GocMailService } from '../goc-mail-service/goc-mail.service';
import { GocModuleConfigService } from '../goc-module-config/goc-module-config.service';
import { GocOptionsService } from '../goc-options-service/goc-options.service';
import { GocSmsService } from '../goc-sms-service/goc-sms.service';

@Injectable({
  providedIn: 'root',
})
export class GocDecisionService extends FltDecisionService<GocDecision> {
  // tslint:disable:variable-name
  protected ParseDecision = Parse.Object.extend('GOCDecisions');
  protected ParseEvent = Parse.Object.extend('GOCEvents');
  protected ParseDecisionTag = Parse.Object.extend('GOCDecisionTag');
  protected ParseTag = Parse.Object.extend('GOCTag');
  protected ParseFlightInstruction = Parse.Object.extend('GOCFlightInstruction');
  protected ParseFlightLogbook = Parse.Object.extend('GOCFlightLogbook');

  // tslint:enabled

  public constructor(
    protected readonly datePipe: DatePipe,
    protected readonly filesService: FilesService,
    protected decisionsStoreManager: OclDecisionsStoreManager,
    protected parseMapper: ParseMapperService,
    protected requestService: RequestService,
    protected optionsService: GocOptionsService,
    protected notificationsService: NotificationsService,
    protected smsService: GocSmsService,
    protected mailService: GocMailService,
    protected historyService: GocHistoryService,
    protected decisionTagService: GocDecisionTagService,
    public moduleConfig: GocModuleConfigService,
    protected flightInstructionService: GocFlightInstructionService,
    protected flightService: GocFlightService,
    protected flightLogbookService: GocFlightLogbookService,
    protected markdownService: MarkdownService,
    protected applicabilityService: FltApplicabilityService,
    public commonStoreManager: CommonStoreManager,
  ) {
    super(
      datePipe,
      filesService,
      decisionsStoreManager,
      parseMapper,
      requestService,
      optionsService,
      notificationsService,
      smsService,
      mailService,
      historyService,
      decisionTagService,
      moduleConfig,
      flightInstructionService,
      flightService,
      flightLogbookService,
      markdownService,
      applicabilityService,
      commonStoreManager,
    );
  }

  public setAdditionalFields(decision: GocDecision, parseDecision: Parse.Object) {
    if (decision.applicability) {
      decision.applicability.updateParseObject(parseDecision);
    }
    parseDecision.set('toInstruction', decision.toInstruction);
    if (decision.isFromFlight && decision.isFromFlight.objectId !== null) {
      parseDecision.set(
        'isFromFlight',
        new this.ParseFlight({
          id: decision.isFromFlight.objectId,
        }),
      );
    }
    parseDecision.set('isPrivate', decision.isPrivate);
  }

  protected newDecision(parseObject?: Parse.Object, tags?: Parse.Object[]): GocDecision {
    return new GocDecision(parseObject, tags && tags.map(t => new HolTag(t.get('tag'))));
  }

  protected initFlightInstructionFromDecision(flight: GocFlight, decision: GocDecision) {
    const flightInstruction = new this.ParseFlightInstruction();
    flightInstruction.set('flight', new this.ParseFlight({ id: flight.objectId }));
    flightInstruction.set('decision', new this.ParseDecision({ id: decision.objectId }));
    flightInstruction.set('isPrivate', decision.isPrivate);
    if (!flight.acl.getPublicWriteAccess()) {
      Object.entries(flight.acl.permissionsById).forEach(([key]) => {
        if (key.startsWith('role:') && !startsWith(key.replace('role:', ''), this.moduleConfig.config.moduleName.toUpperCase())) {
          delete flight.acl.permissionsById[key];
        }
      });
      flightInstruction.setACL(flight.acl);
    } else {
      flightInstruction.setACL(decision.acl);
    }
    return flightInstruction;
  }

  protected initFlightLogbookFromDecision(flight: GocFlight, decision: GocDecision) {
    const flightLogbook = new this.ParseFlightLogbook();
    flightLogbook.set('flight', new this.ParseFlight({ id: flight.objectId }));
    flightLogbook.set('decision', new this.ParseDecision({ id: decision.objectId }));
    flightLogbook.set('isPrivate', decision.isPrivate);
    if (!flight.acl.getPublicWriteAccess()) {
      Object.entries(flight.acl.permissionsById).forEach(([key]) => {
        if (key.startsWith('role:') && !startsWith(key.replace('role:', ''), this.moduleConfig.config.moduleName.toUpperCase())) {
          delete flight.acl.permissionsById[key];
        }
      });
      flightLogbook.setACL(flight.acl);
    } else {
      flightLogbook.setACL(decision.acl);
    }
    return flightLogbook;
  }
}
