import { OclLogbook } from '../../ocl/models/ocl-logbook.model';
import { HolTag } from '../../common/models/hol-tag';
import { EclFunction } from './ecl-function';
import { EclCrisis } from './ecl-crisis';
import { HolObject } from '../../common/models/hol-object';
import { EclLogbook } from './ecl-logbook';
import { EclDecision } from './ecl-decision';
import { EclAsset } from './ecl-asset';
import { HolisFile } from '../../common/models/hol-attachments.model';
import { EclLogbookGroup } from './ecl-logbook-group.model';
import { EclDecisionGroup } from './ecl-decision-group.model';

export class EclSummary extends HolObject {
  public static ParseSummary = Parse.Object.extend('ECLCrisisSummary');

  public crisis: EclCrisis;
  public logBook: EclLogbook;
  public decision: EclDecision;
  public asset: EclAsset;
  public order: number;
  public isDisplayInBriefing: boolean;
  public title: string;
  public type: SummaryTypeData;
  public date: Date;
  public noteFile?: HolisFile;
  public note?: string;
  public image?: HolisFile;
  public logBookGroup?: EclLogbookGroup;
  public decisionGroup?: EclDecisionGroup;
  public icon: string;
  public isEmpty: boolean = false;

  protected ParseEclCrisis? = Parse.Object.extend('ECLCrisis');
  protected ParseDecision? = Parse.Object.extend('ECLDecisions');
  protected ParseLogBook? = Parse.Object.extend('ECLLogBook');
  protected ParseAsset? = Parse.Object.extend('ECLAsset');
  protected ParseDecisionGroup = Parse.Object.extend('ECLDecisionGroup');
  protected ParseLogBookGroup = Parse.Object.extend('ECLLogBookGroup');

  constructor(parseObject?: Parse.Object) {
    super(parseObject);
    if (!parseObject) {
      return;
    }
    this.crisis = new EclCrisis(parseObject.get('crisis'));
    this.order = parseObject.get('briefingOrder');
    this.isDisplayInBriefing = parseObject.get('shouldDisplayInBriefing');
    this.logBook = parseObject.get('logbook') ? new EclLogbook(parseObject.get('logbook')) : null;
    this.decision = parseObject.get('decision') ? new EclDecision(parseObject.get('decision')) : null;
    this.logBookGroup = parseObject.get('logbookGroup') ? new EclLogbookGroup(parseObject.get('logbookGroup')) : null;
    this.decisionGroup = parseObject.get('decisionGroup') ? new EclDecisionGroup(parseObject.get('decisionGroup')) : null;
    this.asset = parseObject.get('asset') ? new EclAsset(parseObject.get('asset')) : null;
    this.loadDisplayData();
  }

  public loadDisplayData() {
    this.title = this.getTitle();
    this.noteFile = this.getNoteFile();
    this.note = this.getNote();
    this.image = this.getImage();
    this.type = this.geType();
    this.icon = this.getIcon();
    this.date = this.getDate();
    if (this.decisionGroup) {
      if (this.decisionGroup.decisionsInSummary.length == 0) {
        this.isEmpty = true;
      }
    } else if (this.logBookGroup) {
      if (this.logBookGroup.logbooksInSummary.length == 0) {
        this.isEmpty = true;
      }
    } else if (!this.asset && !this.image && !this.note && !this.decisionGroup && !this.logBookGroup) {
      this.isEmpty = true;
    }
  }

  public getTitle?(): string {
    if (this.asset) {
      return this.asset.name;
    } else if (this.logBook) {
      return this.logBook.text;
    } else if (this.decision) {
      return this.decision.message;
    } else if (this.logBookGroup) {
      return this.logBookGroup.title;
    } else if (this.decisionGroup) {
      return this.decisionGroup.title;
    }
    return '';
  }

  public geType?(): SummaryTypeData {
    if (this.asset) {
      return SummaryTypeData.ASSET;
    } else if (this.logBook) {
      return SummaryTypeData.LOGBOOK;
    } else if (this.decision) {
      return SummaryTypeData.DECISION;
    } else if (this.logBookGroup) {
      return SummaryTypeData.LOGBOOK_GROUP;
    } else if (this.decisionGroup) {
      return SummaryTypeData.DECISION_GROUP;
    }
    return SummaryTypeData.NONE;
  }

  public getIcon(): string {
    switch (this.type) {
      case SummaryTypeData.LOGBOOK:
        return 'pin_invoke';
      case SummaryTypeData.DECISION:
        return 'mediation';
      case SummaryTypeData.DECISION_GROUP:
      case SummaryTypeData.LOGBOOK_GROUP:
        return 'scatter_plot';
      case SummaryTypeData.ASSET:
        return 'location_on';
      default:
        return 'check_box_outline_blank';
    }
  }

  /*
  public get contentType(): SummaryTypeContent {
    if (this.asset) {
      return SummaryTypeContent.PARAMETER;
    } else if (this.logBook) {
      if (this.logBook.attachments) {
        if (this.logBook.attachments.image) {
          return SummaryTypeContent.IMAGE;
        }
      }
    }

    return SummaryTypeContent.NONE;
  }
  */

  public getImage?(): HolisFile {
    if (this.logBook && this.logBook.attachments) {
      if (this.logBook.attachments.image) {
        return this.logBook.attachments.image;
      }
    } else if (this.decision && this.decision.attachments) {
      if (this.decision.attachments.image) {
        return this.decision.attachments.image;
      }
    } else if (this.asset && this.asset.attachments) {
      if (this.asset.attachments.image) {
        return this.asset.attachments.image;
      }
    }
    return null;
  }

  public getNote?(): string {
    if (this.logBook && this.logBook.attachments) {
      if (this.logBook.attachments.note) {
        return this.logBook.attachments.note;
      }
    } else if (this.decision && this.decision.attachments) {
      if (this.decision.attachments.note) {
        return this.decision.attachments.note;
      }
    } else if (this.asset && this.asset.attachments) {
      if (this.asset.attachments.note) {
        return this.asset.attachments.note;
      }
    }
    return null;
  }

  public getNoteFile?(): HolisFile {
    if (this.logBook && this.logBook.attachments) {
      if (this.logBook.attachments.noteFile) {
        return this.logBook.attachments.noteFile;
      }
    } else if (this.decision && this.decision.attachments) {
      if (this.decision.attachments.noteFile) {
        return this.decision.attachments.noteFile;
      }
    } else if (this.asset && this.asset.attachments) {
      if (this.asset.attachments.noteFile) {
        return this.asset.attachments.noteFile;
      }
    }
    return null;
  }

  public getDate?(): Date {
    if (this.asset) {
      return this.asset.customCreatedAt ? this.asset.customCreatedAt : this.asset.createdAt;
    } else if (this.logBook) {
      return this.logBook.customCreatedAt ? this.logBook.customCreatedAt : this.logBook.createdAt;
    } else if (this.decision) {
      return this.decision.customCreatedAt ? this.decision.customCreatedAt : this.decision.createdAt;
    } else if (this.logBookGroup) {
      if (this.logBookGroup.getMostRecentLogBook()) {
        return (
          this.logBookGroup.getMostRecentLogBook().customCreatedAt ||
          this.logBookGroup.getMostRecentLogBook().createdAt ||
          this.logBookGroup.createdAt
        );
      } else {
        return this.logBookGroup.createdAt;
      }
    } else if (this.decisionGroup) {
      if (this.decisionGroup.getMostRecentItem) {
        return (
          this.decisionGroup.getMostRecentItem.customCreatedAt ||
          this.decisionGroup.getMostRecentItem.createdAt ||
          this.decisionGroup.createdAt
        );
      }
      return this.decisionGroup.createdAt;
    }
    return this.customCreatedAt ? this.customCreatedAt : this.createdAt;
  }

  public parseToObject?() {
    //TODO gerer le create
    const parseObject = new EclSummary.ParseSummary({ id: this.objectId });
    parseObject.set('crisis', new this.ParseEclCrisis({ id: this.crisis.objectId }));
    parseObject.set('logbook', this.logBook ? new this.ParseLogBook({ id: this.logBook.objectId }) : null);
    parseObject.set('decision', this.decision ? new this.ParseDecision({ id: this.decision.objectId }) : null);
    parseObject.set('asset', this.asset ? new this.ParseAsset({ id: this.asset.objectId }) : null);
    parseObject.set('briefingOrder', this.order);
    parseObject.set('shouldDisplayInBriefing', this.isDisplayInBriefing);
    parseObject.set('logbookGroup', this.logBookGroup ? new this.ParseLogBookGroup({ id: this.logBookGroup.objectId }) : null);
    parseObject.set('decisionGroup', this.decisionGroup ? new this.ParseDecisionGroup({ id: this.decisionGroup.objectId }) : null);

    return parseObject;
  }

  toString() {
    return this.title + ' | ' + this.date + ' | ' + this.note + ' | ' + this.type + ' | ' + this.isDisplayInBriefing;
  }
}

export enum SummaryTypeData {
  ASSET = 'ASSET',
  LOGBOOK = 'LOGBOOK',
  DECISION = 'DECISION',
  LOGBOOK_GROUP = 'LOGBOOK_GROUP',
  DECISION_GROUP = 'DECISION_GROUP',
  NONE = '', //
}

export enum SummaryTypeContent {
  IMAGE = 'IMAGE',
  NOTE = 'NOTE',
  PARAMETER = 'PARAMETER',
  NONE = '', //
}
