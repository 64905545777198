import { FltFlightEvent } from 'src/app/flt/models/flt-flight-event';
import { FltApplicability } from './../models/flt-applicability';
import { Injectable } from '@angular/core';
import { RequestService } from '../../common/services/request.service';
import { HolTag } from '../../common/models/hol-tag';
import { Dictionary, groupBy } from 'lodash';
import { ENDED_STATUS, HolFlight } from '../../common/models/hol-flight.model';
import { FltEvent } from '../models/flt-event';
import { HolNextInfo } from 'src/app/common/models/hol-next-info.model';
import moment from 'moment';
import { HolScenario } from 'src/app/common/models/hol-scenario';

@Injectable({
  providedIn: 'root',
})
export abstract class FltFlightEventService<T extends FltFlightEvent = FltFlightEvent> {
  // tslint:disable:variable-name
  protected abstract ParseFlightEvent;
  protected abstract ParseFlightEventTag;
  protected abstract ParseEvent;
  protected abstract ParseEventTag;
  protected abstract ParseTag;
  protected abstract ParseEventInfos;
  protected abstract ParseScenario;

  protected ParseFlight = Parse.Object.extend('GOCFlight');
  private ParseOccEventInfos = Parse.Object.extend('OCCEventInfos');
  private ParseOccEventTag = Parse.Object.extend('OCCEventTag');
  private ParseOccScenario = Parse.Object.extend('OCCScenario');
  // tslint:enable

  protected constructor(protected requestService: RequestService) {}

  public create(flightEvent: T, applicability?: FltApplicability): Promise<T> {
    const parseFlightevent = new this.ParseFlightEvent();
    parseFlightevent.set('station', flightEvent.station);
    parseFlightevent.set('attachments', flightEvent.attachments);
    parseFlightevent.set('createdBy', Parse.User.current());
    parseFlightevent.setACL(flightEvent.acl);
    parseFlightevent.set('event', flightEvent.event ? new this.ParseEvent({ id: flightEvent.event.objectId }) : null);
    if (flightEvent.flight) {
      parseFlightevent.set('flight', new this.ParseFlight({ id: flightEvent.flight.objectId }));
    }
    if (applicability) {
      if (applicability.validFrom) {
        parseFlightevent.set('validFrom', applicability.validFrom);
      }
      if (applicability.validTo) {
        parseFlightevent.set('validTo', applicability.validTo);
      }
      if (applicability.flightsDirection === 'DEP' || applicability.stationsDirection === 'OUT') {
        parseFlightevent.set('station', flightEvent.flight.departure);
      } else if (applicability.flightsDirection === 'ARR' || applicability.stationsDirection === 'IN') {
        parseFlightevent.set('station', flightEvent.flight.destination);
      }
    }
    this.setAdditionalFields(flightEvent, parseFlightevent);
    return this.requestService.performSaveQuery(parseFlightevent).then(async savedFlightEvent => {
      return this.newFlightEvent(savedFlightEvent, savedFlightEvent.tags);
    });
  }

  public getForFlights(flights: HolFlight[], isPrivate?: boolean): Promise<Dictionary<T[]>> {
    const parseFlights = flights.map(f => new this.ParseFlight({ id: f.objectId }));
    const query = new Parse.Query(this.ParseFlightEvent);
    query.containedIn('flight', parseFlights);
    query.notEqualTo('archived', true);
    query.include('event');
    query.include('event.occEvent');
    query.include('scenario');
    query.addDescending('createdAt');
    if (isPrivate) {
      query.notEqualTo('isPrivate', true);
    }
    return this.requestService.performFindQuery(query).then(parseFltEvents => {
      const parseEvents = parseFltEvents.filter(pi => pi.get('event')).map(pi => pi.get('event'));
      const flightEventTagsQuery = new Parse.Query(this.ParseFlightEventTag);
      flightEventTagsQuery.containedIn('flightEvent', parseFltEvents);
      flightEventTagsQuery.include('tag');
      const eventagsQuery = new Parse.Query(this.ParseEventTag);
      eventagsQuery.containedIn('event', parseEvents);
      eventagsQuery.include('tag');
      const eventInfosQuery = new Parse.Query(this.ParseEventInfos);
      eventInfosQuery.containedIn('event', parseEvents);

      const parseOccEvents = parseFltEvents.filter(pi => pi.get('event').get('occEvent')).map(e => e.get('event').get('occEvent'));
      const occEventInfoQuery = new Parse.Query(this.ParseOccEventInfos);
      occEventInfoQuery.containedIn('event', parseOccEvents);

      const occEventTagQuery = new Parse.Query(this.ParseOccEventTag);
      occEventTagQuery.containedIn('event', parseOccEvents);
      occEventTagQuery.include('tag');

      const occScenarioQuery = new Parse.Query(this.ParseOccScenario);

      return Promise.all([
        this.requestService.performFindQuery(flightEventTagsQuery),
        this.requestService.performFindQuery(eventagsQuery),
        this.requestService.performFindQuery(eventInfosQuery),
        this.requestService.performFindQuery(occEventInfoQuery),
        this.requestService.performFindQuery(occEventTagQuery),
        this.requestService.performFindQuery(occScenarioQuery),
      ]).then(([fets, ets, eventInfos, occEventInfos, occEts, occSc]) => {
        const fltEvents = [];
        parseFltEvents.forEach(parseFltEvent => {
          const fltEvent = this.newFlightEvent(parseFltEvent, occSc);
          if (fltEvent.event) {
            if (parseFltEvent.get('event').get('occEvent')) {
              fltEvent.tags = occEts
                .filter(et => et.get('event').id === parseFltEvent.get('event').get('occEvent').id)
                .map(et => new HolTag(et.get('tag')));
              fltEvent.event.scenario = new HolScenario(
                occSc.find(sc => sc.id === parseFltEvent.get('event').get('occEvent').get('scenario').id),
              );
              fltEvent.event.infos = occEventInfos
                .filter(ei => {
                  return ei.get('event').id === parseFltEvent.get('event').get('occEvent').id;
                })
                .map(et => new HolNextInfo(et));
            } else {
              fltEvent.tags = ets.filter(et => et.get('event').id === fltEvent.event.objectId).map(et => new HolTag(et.get('tag')));
              fltEvent.event.infos = eventInfos
                .filter(ei => {
                  return ei.get('event').id === fltEvent.event.objectId;
                })
                .map(et => new HolNextInfo(et));
            }
          } else {
            fltEvent.tags = fets.filter(fet => fet.get('flightEvent').id === fltEvent.objectId).map(fet => new HolTag(fet.get('tag')));
          }
          fltEvents.push(fltEvent);
        });
        return groupBy(fltEvents, l => l.flight.objectId);
      });
    });
  }

  getAllFromEvent(event: FltEvent): Promise<Parse.Object[]> {
    const parseEvent = new this.ParseEvent({ id: event.objectId });
    const flightQuery = new Parse.Query(this.ParseFlight);
    flightQuery.notContainedIn('status', ENDED_STATUS);
    const flightEventQuery = new Parse.Query(this.ParseFlightEvent);
    flightEventQuery.equalTo('event', parseEvent);
    flightEventQuery.notEqualTo('archived', true);
    flightEventQuery.include('flight');
    flightEventQuery.matchesQuery('flight', flightQuery);

    return this.requestService.performFindAllQuery(flightEventQuery);
  }

  public async deleteAllFtlEventsFromEvent(eventId) {
    const queryFlight = new Parse.Query(this.ParseFlight);
    queryFlight.greaterThanOrEqualTo('std', moment.utc().toDate());

    const query = new Parse.Query(this.ParseFlightEvent);
    query.equalTo('event', new this.ParseEvent({ id: eventId }));
    query.matchesQuery('flight', queryFlight);

    const fltEventsToDelete = await this.requestService.performFindAllQuery(query);

    return await this.requestService.performDestroyAllQuery(fltEventsToDelete);
  }

  public async deleteFltFlightEvent(flightEventId) {
    const queryFlight = new Parse.Query(this.ParseFlight);
    queryFlight.greaterThanOrEqualTo('std', moment.utc().toDate());

    const query = new Parse.Query(this.ParseFlightEvent);
    query.equalTo('event', new this.ParseFlightEvent({ id: flightEventId }));
    query.matchesQuery('flight', queryFlight);

    const intructionsToDelete = await this.requestService.performFindAllQuery(query);

    return await this.requestService.performDestroyAllQuery(intructionsToDelete);
  }

  protected setAdditionalFields(flightEvent: T, parseFlightEvent: Parse.Object) {}

  protected newFlightEvent(parseObject?: Parse.Object, tags?: Parse.Object[], scenariosFromOtherModule?: Parse.Object[]): T {
    return new FltFlightEvent(parseObject, tags && tags.map(t => new HolTag(t.get('tag'))), scenariosFromOtherModule) as T;
  }
}
