import { Inject, Injectable } from '@angular/core';

import { UserService } from 'src/app/common/services/user.service';
import { CommonStoreManager } from 'src/app/common/store/common.store-manager';
import { HolNotification } from 'src/app/common/models/hol-notification.model';
import { HelperService } from 'src/app/common/services/helper.service';
import { HolFlight } from 'src/app/common/models/hol-flight.model';
import { HolTag } from 'src/app/common/models/hol-tag';
import { NotificationsService } from 'src/app/common/services/notifications/notifications.service';
import moment from 'moment';
import { FltEventService } from 'src/app/flt/services/flt-event.service';
import { MarkdownService } from 'src/app/common/components/markdown-editor/markdown.service';
import { FltApplicabilityService } from 'src/app/flt/services/flt-applicability.service';
import { OclEvent } from 'src/app/ocl/models/ocl-event.model';
import { map, orderBy, startsWith } from 'lodash';
import { FilesService } from 'src/app/common/services/files.service';
import { OclScenariosStoreManager } from 'src/app/ocl/store/scenarios/ocl-scenarios.store-manager';
import { OclTagsStoreManager } from 'src/app/ocl/store/tags/ocl-tags.store-manager';

import { HolNextInfo } from '../../../common/models/hol-next-info.model';
import { ParseMapperService } from '../../../common/services/parse-mapper.service';
import { RequestService } from '../../../common/services/request.service';
import { RolesService } from '../../../common/services/roles.service';
import { MccEventService } from '../../../mcc/services/mcc-event.service';
import { MccOptionsService } from '../../../mcc/services/mcc-options.service';
import { OclEventsStoreManager } from '../../../ocl/store/events/ocl-events-store-manager.service';
import { OclTasksStoreManager } from '../../../ocl/store/tasks/ocl-tasks.store-manager';
import { GocEvent } from '../../models/goc-event.model';
import { GocEventTagService } from '../goc-event-tag-service/goc-event-tag.service';
import { GocFlightEventService } from '../goc-flight-event-service/goc-flight-event-service';
import { GocFlightService } from '../goc-flight-service/goc-flight.service';
import { GocHistoryService } from '../goc-history-service/goc-history.service';
import { GocMailService } from '../goc-mail-service/goc-mail.service';
import { GocModuleConfigService } from '../goc-module-config/goc-module-config.service';
import { GocSmsService } from '../goc-sms-service/goc-sms.service';
import { GocTagsService } from '../goc-tags-service/goc-tags.service';
import { GocTasksService } from '../goc-tasks.service';

@Injectable({
  providedIn: 'root',
})
export class GocEventService extends FltEventService<GocEvent> {
  public ParseUser = Parse.Object.extend('_User');
  // tslint:disable:variable-name
  protected ParseEvents = Parse.Object.extend('GOCEvents');
  protected ParseScenario = Parse.Object.extend('GOCScenario');
  protected ParseEventInfos = Parse.Object.extend('GOCEventInfos');
  protected ParseTaskRef = Parse.Object.extend('GOCTask_REF');
  protected ParseTag = Parse.Object.extend('GOCTag');
  protected ParseEventTag = Parse.Object.extend('GOCEventTag');
  protected ParseFlightEvents = Parse.Object.extend('GOCFlightEvents');
  // tslint:enable
  protected taskEventColumn = 'gocEvent';

  public constructor(
    protected requestService: RequestService,
    protected mccEventsService: MccEventService,
    protected mccOptionsService: MccOptionsService,
    protected userService: UserService,
    protected parseMapper: ParseMapperService,
    protected notificationsService: NotificationsService,
    protected historyService: GocHistoryService,
    protected eventTagService: GocEventTagService,
    protected eventsStoreManager: OclEventsStoreManager,
    protected mailService: GocMailService,
    protected smsService: GocSmsService,
    @Inject('ChatService') protected chatService,
    @Inject('$rootScope') protected $rootScope,
    public moduleConfig: GocModuleConfigService,
    public markdownService: MarkdownService,
    protected flightService: GocFlightService,
    protected readonly filesService: FilesService,
    protected applicabilityService: FltApplicabilityService,
    protected flightEventService: GocFlightEventService,
    private helperService: HelperService,
    protected tasksStoreManager: OclTasksStoreManager,
    protected tasksService: GocTasksService,
    protected tagsService: GocTagsService,
    protected rolesService: RolesService,
    protected commonStoreManager: CommonStoreManager,
    protected tagsStoreManager: OclTagsStoreManager,
    protected scenariosStoreManager: OclScenariosStoreManager,
  ) {
    super(
      requestService,
      mccOptionsService,
      userService,
      parseMapper,
      notificationsService,
      historyService,
      eventTagService,
      eventsStoreManager,
      mailService,
      smsService,
      chatService,
      $rootScope,
      moduleConfig,
      markdownService,
      flightService,
      filesService,
      applicabilityService,
      flightEventService,
      tasksStoreManager,
      tasksService,
      tagsService,
      rolesService,
      commonStoreManager,
      tagsStoreManager,
      scenariosStoreManager,
    );
  }

  public activateECLModule(eclOptionsId: string, moduleName: string, event: GocEvent, infos: any[]): void {}

  public deactivateECLModule(eclOptionsId: string): void {}

  public setAdditionalFields(event: GocEvent, parseEvent: Parse.Object) {
    if (event.applicability) {
      event.applicability.updateParseObject(parseEvent);
    }
    parseEvent.set('isPrivate', event.isPrivate);
  }

  // protected getAllGOCEvents(): Promise<any[]> {
  //   return Promise.resolve([]);
  // }

  close(event: GocEvent, reason: number, reasonText: string, notifications: HolNotification[]): Promise<void> {
    return super.close(event, reason, reasonText, notifications).then(() => {
      if (event.toOCC) {
        const parseEvent = new this.ParseEvents({ id: event.objectId });
        parseEvent.set('toOCC', false);
        this.requestService.performSaveAllQuery(parseEvent).then(e => {
          this.deleteDuplicateEventFromModule(event);
        });
      }
    });
  }

  protected markPreviousInfosAsDone(event: GocEvent, nextInfoTime: Date): Promise<HolNextInfo[]> {
    const infosToMarkAsDone =
      event.infos && event.infos.filter(info => !info.done && moment(info.nextInfoTime).isSameOrBefore(nextInfoTime));
    const promises = infosToMarkAsDone.map(info => this.markInfoAsDone(info, event, true, false));
    return Promise.all(promises);
  }

  protected getAllMCCEvents(): Promise<any[]> {
    return Promise.resolve([]);
  }

  protected newEvent(
    parseObject?: Parse.Object,
    eventTags?: Parse.Object[],
    infosToMap?: [],
    scenariosFromOtherModule?: Parse.Object[],
  ): GocEvent {
    if (!parseObject) {
      return null;
    }
    const infos = map(infosToMap, info => new HolNextInfo(info));
    const tags = orderBy(
      map(eventTags, et => new HolTag(et.get('tag'))),
      'name',
    );
    return new GocEvent(parseObject, tags, infos, scenariosFromOtherModule);
  }

  protected initFlightEventFromEvent(flight: HolFlight, event: GocEvent) {
    const flightEvent = new this.ParseFlightEvents();
    flightEvent.set('flight', new this.ParseFlight({ id: flight.objectId }));
    flightEvent.set('event', new this.ParseEvents({ id: event.objectId }));
    flightEvent.set('isPrivate', event.isPrivate);
    flightEvent.set('toOCC', event.toOCC);
    if (!flight.acl.getPublicWriteAccess()) {
      Object.entries(flight.acl.permissionsById).forEach(([key, value]) => {
        if (key.startsWith('role:') && !startsWith(key.replace('role:', ''), this.moduleConfig.config.moduleName.toUpperCase())) {
          delete flight.acl.permissionsById[key];
        }
      });
      flightEvent.setACL(flight.acl);
    } else {
      flightEvent.setACL(event.acl);
    }
    return flightEvent;
  }

  protected async duplicateEventToOtherModule(event: GocEvent) {
    const parseOccEvent = new this.ParseOccEvents();
    parseOccEvent.set('gocEvent', new this.ParseEvents({ id: event.objectId }));
    const companies = this.helperService.parseACL(event.acl);
    const acl: Parse.ACL = await this.rolesService.getACLForCompanies(['OCC'], companies);
    parseOccEvent.setACL(acl);
    this.requestService.performSaveAllQuery(parseOccEvent).then();
  }

  protected deleteDuplicateEventFromModule(event: GocEvent) {
    const parseGocEvent = new this.ParseEvents({ id: event.objectId });
    const query = new Parse.Query(this.ParseOccEvents);
    query.equalTo('gocEvent', parseGocEvent);

    this.requestService.performFirstQuery(query).then(result => {
      if (result) {
        const occEvent = new OclEvent(result);
        const parseOccEvent = new this.ParseOccEvents({ id: occEvent.objectId });
        this.requestService.performDestroyQuery(parseOccEvent).then();
      }
    });
  }
}
