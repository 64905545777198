import { inject, Inject, Injectable } from '@angular/core';
import { map } from 'lodash';
import { HolNextInfo } from 'src/app/common/models/hol-next-info.model';
import { MailSenderService } from 'src/app/common/services/mail/mail-sender.service';
import { MailTemplateService } from 'src/app/common/services/mail/mail-template.service';
import { MccEvent } from '../../../mcc/models/mcc-event.model';
import { MccMel } from '../../../mcc/models/mcc-mel.model';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class MailService {
  readonly translate = inject(TranslateService);

  constructor(
    @Inject('CONSTANTS') private CONSTANTS,
    private readonly mailSenderService: MailSenderService,
    private mailTemplateService: MailTemplateService,
  ) {}

  public sendNewMELInfoMail(mel: MccMel, melInfo: HolNextInfo, mails: string[]) {
    if (!mel) {
      console.warn('MailService::sendNewMELInfoMail: MEL empty');
    }
    if (!melInfo) {
      console.warn('MailService::sendNewMELInfoMail: MELInfo empty');
    }
    if (!mails || !mails.length) {
      console.warn('MailService::sendNewMELInfoMail: no mailing list found');
    }

    this.mailSenderService.sendMail(
      {
        recipients: map(mails, function (m) {
          return { email: m };
        }),
        subject: this.translate.instant('MAIL.MCC.NEW_MEL_INFO.SUBJECT', {
          faultName: mel.faultName,
        }),
        contentHtml: this.mailTemplateService.getNewMELInfoMessage(mel, melInfo),
        sender: this.CONSTANTS.COMPANY_NAME + this.CONSTANTS.MCC_SUFFIX,
      },
      true,
      true,
    );
  }

  //this is a crew event, not occ
  public sendNewSituationEmail(situation: any) {
    if (!situation.user || !situation.user.email) {
      console.warn('MailService::sendNewSituationEmail: no user mail');
      return;
    }

    this.mailSenderService.sendMail(
      {
        recipients: [{ email: situation.user.email }],
        subject: 'Contact with ' + situation.createdBy.firstName + ' ' + situation.createdBy.lastName,
        contentHtml: this.mailTemplateService.getSituationMessage(situation),
        sender: this.CONSTANTS.COMPANY_NAME + this.CONSTANTS.CREW_SUFFIX,
      },
      true,
      true,
    );
  }

  //MCC
  public sendNewMCCEventMail(event: MccEvent, mails: string[]) {
    if (!event) {
      console.warn('MailService::sendNewMCCEventMail: event empty');
    }
    if (!mails || !mails.length) {
      console.warn('MailService::sendNewMCCEventMail: no mailing list found');
    }

    this.mailSenderService.sendMail(
      {
        recipients: map(mails, function (m) {
          return { email: m };
        }),
        subject: this.translate.instant('MAIL.MCC.NEW_EVENT.SUBJECT', {
          aircraft: event.aircraft && event.aircraft.registration,
        }),
        contentHtml: this.mailTemplateService.getMCCEventMessage(event, [{ info: event.infos[0] }]),
        sender: this.CONSTANTS.COMPANY_NAME + this.CONSTANTS.MCC_SUFFIX,
      },
      true,
      true,
    );
  }

  public sendNewMCCEventInfoMail(event: MccEvent, eventInfo: HolNextInfo, mails: string[]) {
    if (!event) {
      console.warn('MailService::sendNewMCCEventInfoMail: MCCEvent empty');
    }
    if (!eventInfo) {
      console.warn('MailService::sendNewMCCEventInfoMail: MCCEventInfo empty');
    }
    if (!mails || !mails.length) {
      console.warn('MailService::sendNewMCCEventInfoMail: no mailing list found');
    }

    this.mailSenderService.sendMail(
      {
        recipients: map(mails, function (m) {
          return { email: m };
        }),
        subject: this.translate.instant('MAIL.MCC.NEW_EVENT_INFO.SUBJECT', {}),
        contentHtml: this.mailTemplateService.getNewMCCEventInfoMessage(event, eventInfo),
        sender: this.CONSTANTS.COMPANY_NAME + this.CONSTANTS.MCC_SUFFIX,
      },
      true,
      true,
    );
  }
}
