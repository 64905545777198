import { Inject, Injectable } from '@angular/core';
import { OpsOptionsService } from '../ops-options-service/ops-options.service';
import { RequestService } from '../../../common/services/request.service';
import { OpsHistoryService } from '../ops-history-service/ops-history.service';
import { OclFlightStatusService } from '../../../ocl/services/ocl-flight-status-service/ocl-flight-status.service';

@Injectable({
  providedIn: 'root',
})
export class OpsFlightStatusService extends OclFlightStatusService {
  // tslint:disable:variable-name
  protected FlightsStatus = Parse.Object.extend('OPSFlightsStatus');
  // tslint:enabled

  public constructor(
    protected optionsService: OpsOptionsService,
    protected requestService: RequestService,
    @Inject('$rootScope') protected $rootScope,
    protected historyService: OpsHistoryService,
  ) {
    super(optionsService, requestService, $rootScope, historyService);
  }
}
