import { Inject, Injectable } from '@angular/core';
import { GocOptionsService } from '../goc-options-service/goc-options.service';
import { RequestService } from '../../../common/services/request.service';
import { GocHistoryService } from '../goc-history-service/goc-history.service';
import { OclFlightStatusService } from '../../../ocl/services/ocl-flight-status-service/ocl-flight-status.service';

@Injectable({
  providedIn: 'root',
})
export class GocFlightStatusService extends OclFlightStatusService {
  // tslint:disable:variable-name
  protected FlightsStatus = Parse.Object.extend('GOCFlightsStatus');
  // tslint:enabled

  public constructor(
    protected optionsService: GocOptionsService,
    protected requestService: RequestService,
    @Inject('$rootScope') protected $rootScope,
    protected historyService: GocHistoryService,
  ) {
    super(optionsService, requestService, $rootScope, historyService);
  }
}
