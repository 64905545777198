import { Injectable } from '@angular/core';
import { OclGroupService } from '../../ocl/services/ocl-group-service/ocl-group.service';
import { CrewModuleConfigService } from './crew-module-config.service';
import { OclGroupsStoreManager } from '../../ocl/store/groups/ocl-groups.store-manager';
import { RequestService } from '../../common/services/request.service';

@Injectable({
  providedIn: 'root',
})
export class CrewGroupService extends OclGroupService {
  // tslint:disable:variable-name
  ParseGlobalInstructionGroup = Parse.Object.extend('CrewGlobalInstructionGroup');
  ParseGlobalInstruction = Parse.Object.extend('CrewGlobalInstruction');

  // tslint:enable

  protected constructor(
    protected requestService: RequestService,
    protected groupStoreManager: OclGroupsStoreManager,
    protected moduleConfig: CrewModuleConfigService,
  ) {
    super(requestService, groupStoreManager, moduleConfig);
  }
}
