import { HolRole } from './hol-role';
import { identity, uniqBy } from 'lodash';

export class HolNotification {
  objectId: string;
  name: string;
  mailingList: string[] = [];
  smsList: string[] = [];
  sendByMail?: boolean;
  sendBySms?: boolean;
  acl?: Parse.ACL;
  companies?: string[];
  disabled?: boolean = false;
  readOnly?: boolean = false;

  constructor(parseObject: Parse.Object) {
    if (!parseObject) {
      return;
    }
    this.objectId = parseObject.id;
    this.name = parseObject.get('name') || parseObject.get('code');
    this.sendBySms = false;
    this.sendByMail = false;
    this.mailingList = parseObject.get('mailingList') ? parseObject.get('mailingList').split('|') : [];
    this.smsList = parseObject.get('smsList') ? parseObject.get('smsList').split('|') : [];
    this.acl = parseObject.getACL();
    this.parseACL(this.acl);
  }

  public parseACL?(acl: Parse.ACL) {
    this.companies = [];
    if (!acl) {
      return;
    }
    Object.entries<any>(acl.permissionsById).forEach(([key, value]) => {
      if ((key.startsWith('role:') && value.read) || value.write) {
        const roleName = key.replace('role:', '');
        if (HolRole.ROLE_PARTS_REGEXP.test(roleName)) {
          const parts = HolRole.ROLE_PARTS_REGEXP.exec(roleName);
          this.companies.push(parts[2]);
        }
      }
    });
    this.companies = uniqBy(this.companies, identity).sort((a, b) => (a < b ? -1 : 1));
  }
}

export class HolNotifyFunction {
  sendByMail: boolean;
  sendBySms: boolean;
  disabled?: boolean = false;
  functionTitle?: string;
}
