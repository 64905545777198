import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-password-strength',
  templateUrl: './password-strength.component.html',
  styleUrls: ['./password-strength.component.scss'],
})
export class PasswordStrengthComponent {
  @Input() password = '';

  getProgressWidth() {
    const passwordLength = this.password.length;
    if (passwordLength >= 6 && this.hasLowerCase() && this.hasUpperCase() && this.hasDigit() && this.hasSpecialChar()) {
      return '100%';
    } else if (
      passwordLength >= 6 &&
      (this.hasLowerCase() ? 1 : 0) + (this.hasUpperCase() ? 1 : 0) + (this.hasDigit() ? 1 : 0) + (this.hasSpecialChar() ? 1 : 0) >= 3
    ) {
      return '50%';
    } else if (passwordLength >= 6) {
      return '25%';
    } else {
      return '0%';
    }
  }

  getProgressColor() {
    const passwordLength = this.password.length;
    if (passwordLength >= 6 && this.hasLowerCase() && this.hasUpperCase() && this.hasDigit() && this.hasSpecialChar()) {
      return '#28A745';
    } else if (
      passwordLength >= 6 &&
      (this.hasLowerCase() ? 1 : 0) + (this.hasUpperCase() ? 1 : 0) + (this.hasDigit() ? 1 : 0) + (this.hasSpecialChar() ? 1 : 0) >= 3
    ) {
      return '#FFC107';
    } else if (passwordLength >= 6) {
      return '#DC3545';
    } else {
      return '#DC3545';
    }
  }

  hasLowerCase() {
    return /[a-z]/.test(this.password);
  }

  hasUpperCase() {
    return /[A-Z]/.test(this.password);
  }

  hasDigit() {
    return /[0-9]/.test(this.password);
  }

  hasSpecialChar() {
    return /[!@#$%^&*(),.?“:{}|<>]/.test(this.password);
  }
}
