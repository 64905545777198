'use strict';

/**
 * @ngdoc service
 * @name gestiondecriseApp.CrewFunctionsService
 * @description
 * # CrewFunctionsService
 * Service in the gestiondecriseApp.
 */
angular.module('gestiondecriseApp').service('CrewFunctionsService', function ($q, RequestService, ParseMapper) {
  const CrewFunction = Parse.Object.extend('CrewFunction');

  this.getAll = function () {
    const deferred = $q.defer();

    RequestService.performCountQuery(
      new Parse.Query(CrewFunction),
      function (count) {
        const query = new Parse.Query(CrewFunction);
        query.limit(count);
        query.ascending('order');
        RequestService.performFindQuery(
          query,
          function (parseFunctions) {
            deferred.resolve(_.map(parseFunctions, f => ParseMapper.crewFunctionToObject(f)));
          },
          function (error) {
            deferred.reject(error);
          }
        );
      },
      deferred.reject
    );

    return deferred.promise;
  };
});
