import { Component, inject, Inject, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';

import { MatDialog } from '@angular/material/dialog';

import { TimeOrDayPipe } from 'src/app/common/pipes/time-or-day/time-or-day.pipe';
import { TranslateService } from '@ngx-translate/core';
import { SystemNotificationService } from 'src/app/common/services/system-notification.service';
import moment from 'moment';

import { cloneDeep, extend } from 'lodash';
import { LocalStorageService } from 'src/app/common/services/local-storage.service';
import { CommonService } from 'src/app/common/services/common.service';

import { HolContext } from '../../../common/models/hol-context.model';
import { HolNotification } from '../../../common/models/hol-notification.model';
import { ModuleConfigService } from '../../../common/services/module-config/module-config.service';
import { OCL_DECISIONS_MODAL_TOKEN } from '../../modals/ocl-decisions-modal/ocl-decisions-modal.token';
import { OclDecisionGroup } from '../../models/ocl-decision-group.model';
import { OclDecision } from '../../models/ocl-decision.model';
import { OclDecisionService } from '../../services/ocl-decision-service/ocl-decision.service';
import { OclFlightStatusService } from '../../services/ocl-flight-status-service/ocl-flight-status.service';
import { OclLinksHelperService } from '../../services/ocl-links-helper-service/ocl-links-helper.service';

import { OclNotificationsService } from '../../services/ocl-notifications-service/ocl-notifications.service';
import { OclEventsStoreManager } from '../../store/events/ocl-events-store-manager.service';
import { OclLinkedDisplayedItemsStoreManager } from '../../store/linked-display-items/ocl-linked-displayed-items-store-manager.service';
import { OclLogBooksStoreManager } from '../../store/logbooks/ocl-log-books-store-manager.service';
import { OclDecisionsStoreManager } from '../../store/decisions/ocl-decisions.store-manager';
import { HolUTCDisplay, utcEval } from '../../../common/enums/hol-utc-display.enum';
import { EclSummary } from '../../../ecl/models/ecl-summary';
import { OclGroup } from '../../models/ocl-group.model';

@Component({
  selector: 'app-ocl-decisions-list-item',
  templateUrl: './ocl-decisions-list-item.component.html',
  styleUrls: ['./ocl-decisions-list-item.component.scss'],
})
export class OclDecisionsListItemComponent implements OnInit, OnDestroy, OnChanges {
  @Input() userCanEditCards?: boolean;
  @Input() isReadOnly;
  @Input() isSummaryActivated? = false;
  @Input() utcDisplay: HolUTCDisplay;
  @Input() events;
  @Input() item: OclDecision;
  @Input() linkedIds;
  @Input() logBookItems;
  @Input() modeLinkIsActivate;
  @Input() decisionGroupsInput: OclDecisionGroup[];
  @Input() airportsCode;
  @Input() isHistory = false;
  @Input() public summaryGroup?: EclSummary;

  @Input() public group?: OclGroup<any>;

  notifications: HolNotification[];

  public timerId;
  public isTodoConfigActivated = false;
  public linkedUid: string;
  public randomColor: string;
  public context: HolContext;
  private elapsed = false;

  protected readonly translate = inject(TranslateService);

  constructor(
    protected dialog: MatDialog,
    public moduleConfig: ModuleConfigService,
    protected systemNotificationService: SystemNotificationService,
    protected timeOrDayPipe: TimeOrDayPipe,
    protected decisionsService: OclDecisionService,
    protected flightsStatusService: OclFlightStatusService,
    @Inject('CONSTANTS') protected CONSTANTS,
    protected readonly localStorage: LocalStorageService,
    protected readonly commonService: CommonService,
    protected linksHelperService: OclLinksHelperService,
    protected occLinkedDisplayedItemsStoreManager: OclLinkedDisplayedItemsStoreManager,
    protected occLogBooksStoreManager: OclLogBooksStoreManager,
    protected occEventsStoreManager: OclEventsStoreManager,
    protected decisionsStoreManager: OclDecisionsStoreManager,
    protected notificationsService: OclNotificationsService,
    @Inject(OCL_DECISIONS_MODAL_TOKEN) protected decisionModal,
  ) {
    this.isTodoConfigActivated = this.moduleConfig.config.activateTODO;
  }

  public get isUtc(): boolean {
    return utcEval(this.utcDisplay, this.isReadOnly);
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (this.item.linkedData && !this.item.linkedData.linkedUid && this.item.linkedData.linkedUids.length === 0) {
      delete this.item.linkedData;
    }
    if (changes.item && changes.item.currentValue && changes.item.currentValue.linkedData && changes.item.currentValue.linkedData) {
      this.linkedUid = changes.item.currentValue.linkedData.linkedUid;
      this.localStorage.setBufferLinkedUid(this.item.linkedData.objectId);
    } else if (
      changes.item &&
      changes.item.previousValue &&
      changes.item.previousValue.linkedData &&
      changes.item.currentValue &&
      !changes.item.currentValue.linkedData
    ) {
      this.linkedUid = undefined;
      this.localStorage.removeBufferLinkedUid();
    }
  }

  ngOnInit(): void {
    // this.loadStorage();
    this.notificationsService.getAll().then(notif => (this.notifications = notif));
    this.elapsed = this.isElapsed(this.item.nextInfoTime);
    if (!this.isReadOnly && this.item.nextInfoTime && !this.elapsed) {
      this.timerId = setInterval(() => {
        if (this.isElapsed(this.item.nextInfoTime) && !this.elapsed) {
          this.elapsed = true;
          this.sendNotification(this.item);
          clearInterval(this.timerId);
        }
      }, 5000);
    }

    this.context = {
      module: this.moduleConfig.config.translateKey,
      category: 'DECISION',
      htmlTitle: this.item.contentText,
      htmlDate: this.item.createdAt ? this.item.createdAt : '',
      htmlTemplate: 'A',
    };
  }

  ngOnDestroy(): void {
    if (this.timerId) {
      clearInterval(this.timerId);
    }
  }

  /*public loadStorage() {
    const prevUrl = this.localStorage.getSessionPrevUrlLocation().split('.', 2)[1];
    const actualUrl = this.localStorage.getSessionUrlLocation().split('.', 2)[1];
    const linkedUid = this.localStorage.getBufferLinkedUid();
    console.log('linkedUid ocl-decision-- ', linkedUid);
    if (linkedUid && prevUrl && prevUrl !== actualUrl) {
      this.hideLinkedItems(linkedUid);
    }
  }

  public hideLinkedItems(linkedUid: string) {
    console.log('hideLinkedItems decision');
    const bufferLinkedUid = 'LinkFrom_' + linkedUid;
    let randColor;
    this.linksHelperService.getLinkFromLogBook(linkedUid).then(data => {
      randColor = data[0];
    });
    // this.localStorage.removeBufferLinkedUid();
    this.occLinkedDisplayedItemsStoreManager.removeLinkedDiplayedItems(bufferLinkedUid, this.randomColor);
    this.occEventsStoreManager.removeEventsFromLinkedValue(bufferLinkedUid);
    this.occLogBooksStoreManager.removeLogBooksFromLinkedValue(bufferLinkedUid);
    this.linkedUid = undefined;
    this.moduleConfig.unsetLoadingItemsLinked();
  }*/

  public openDetailModal(event, item): void {
    event.stopPropagation();
    if (this.userCanEditCards || this.userCanEditCards === undefined) {
      const dialogRef = this.dialog.open(this.decisionModal, {
        data: {
          notifications: this.notifications,
          events: this.events,
          logBookItems: this.logBookItems,
          isUtc: this.isUtc,
          decision: cloneDeep(item),
          isReadOnly: this.isReadOnly,
          modeLinkIsActivate: this.modeLinkIsActivate,
          airportsCode: this.airportsCode,
          itemComponent: OclDecisionsListItemComponent,
          isHistory: this.isHistory,
          isSummaryActivated: this.isSummaryActivated,
        },
      });
      dialogRef.afterClosed().subscribe(decision => {
        if (decision) {
          this.flightsStatusService.setNeedsUpdate(true);
        }
      });
    }
  }

  public showLinkedItems($event: MouseEvent): void {
    $event.stopPropagation();
    this.moduleConfig.setLoadingItemsLinked();
    const bufferLinkedUid = 'LinkFrom_' + this.item.objectId;

    if (!this.item.linkedData || !this.item.linkedData.linkedUid) {
      this.localStorage.setBufferLinkedUid(this.item.objectId);
      this.linksHelperService.getLinkFromDecision(this.item.objectId).then(data => {
        this.randomColor = data[0];
        const linkedItemsToDisplay = data[1];
        const logBooksToUpdate = data[2];
        const eventsToUpdate = data[3];
        this.occLinkedDisplayedItemsStoreManager.addOrUpdateLinkedDiplayedItems(bufferLinkedUid, this.randomColor, linkedItemsToDisplay);
        if (eventsToUpdate.length) {
          this.occEventsStoreManager.updateEventsFromLinkedValue(eventsToUpdate, bufferLinkedUid);
        }
        if (logBooksToUpdate.length) {
          this.occLogBooksStoreManager.updateLogBooksFromLinkedValue(logBooksToUpdate, bufferLinkedUid);
        }
        this.moduleConfig.unsetLoadingItemsLinked();
      });
    } else {
      this.occLinkedDisplayedItemsStoreManager.removeLinkedDiplayedItems(bufferLinkedUid, this.randomColor);
      this.occEventsStoreManager.removeEventsFromLinkedValue(bufferLinkedUid);
      this.occLogBooksStoreManager.removeLogBooksFromLinkedValue(bufferLinkedUid);
      this.linkedUid = undefined;
      this.moduleConfig.unsetLoadingItemsLinked();
      // this.localStorage.removeBufferLinkedUid();
    }
  }

  public isElapsed(date): boolean {
    return moment(date).isBefore(moment());
  }

  public isAlmostElapsed(date): boolean {
    return moment(date).diff(moment(), 'minutes') < this.CONSTANTS.TIME_BEFORE_WARNING;
  }

  public markAsDone(item, $event) {
    $event.stopPropagation();
    this.decisionsService.markAsDone(item, !item.done, this.context).then(eventInfo => {
      this.decisionsStoreManager.updateOneDecision(extend(item, eventInfo));
      this.flightsStatusService.setNeedsUpdate(true);
    });
  }

  public sendNotification(decision) {
    let title = 'Decision ';
    title += this.timeOrDayPipe.transform(decision.createdAt, !this.isReadOnly) + ' ';
    title += decision.message;
    const message = this.translate.instant('COMMON.NOTIFICATIONS.REASON.NEXT_INFO_ELAPSED');
    this.systemNotificationService.tryToNotify(title, message, true);
  }

  public getLastDisplayTime(): Date {
    return this.localStorage.getOccDashboardLastDisplayTime() || new Date();
  }

  public setLastDisplayTime(): void {
    this.localStorage.setOccDashboardLastDisplayTime(new Date());
  }
}
