import { filter } from 'rxjs/operators';
import { OclGlobalInstruction } from './../../../ocl/models/ocl-global-instruction.model';
import { Pipe, PipeTransform } from '@angular/core';
import { cloneDeep } from 'lodash';
import { OclLogbook } from 'src/app/ocl/models/ocl-logbook.model';
import { HolEvent } from '../../models/hol-event';
import { OclDecision } from 'src/app/ocl/models/ocl-decision.model';

@Pipe({
  name: 'occSearchDashboard',
})
export class OccSearchDashboardPipe implements PipeTransform {
  public transform<T>(value: T[], searchCriterias?: string, context?: string, scenarioId?: string): T[] {
    const searchCriteriasArray = searchCriterias ? this.sanitize(this.stringToArray(searchCriterias)) : [];

    if (!searchCriteriasArray.length && !scenarioId) {
      return value;
    }

    // Fonction générique pour filtrer les groupes
    const filterGroups = (items: T[], getAvailableData: (item: any) => string): T[] => {
      let filteredGroups = cloneDeep(items);
      searchCriteriasArray.forEach(searchTerm => {
        filteredGroups = filteredGroups.filter((group: any) => {
          group.items = group.items.filter((item: any) => {
            const availableData = getAvailableData(item);
            return availableData.toLowerCase().includes(searchTerm);
          });
          return group.items.length > 0;
        });
      });
      return filteredGroups;
    };

    // Logique basée sur le contexte
    switch (context) {
      case 'decision':
        return filterGroups(value, (decision: any) => `${decision.message} ${decision.createdBy.monogram()}`);

      case 'logbook':
        return filterGroups(value, (logbook: any) => `${logbook.text} ${logbook.createdBy.monogram()}`);

      case 'event':
        let eventsAfterSearch = cloneDeep(value);
        if (!searchCriteriasArray.length && scenarioId) {
          return this.filterEvents(eventsAfterSearch, '', scenarioId);
        }
        searchCriteriasArray.forEach(searchTerm => {
          eventsAfterSearch = this.filterEvents(eventsAfterSearch, searchTerm, scenarioId);
        });
        return eventsAfterSearch;

      case 'global_instruction':
        return filterGroups(
          value,
          (globalInstruction: any) => `${globalInstruction.description} ${globalInstruction.createdBy.monogram()}`,
        );

      default:
        return value; // Si le contexte n'est pas reconnu, retournez la valeur initiale
    }
  }

  /*
  public transform<T>(value: T[], searchCriterias?: string, context?: string, scenarioId?: string): T[] {
    const searchCriteriasArray = searchCriterias.length > 0 ? this.sanitize(this.stringToArray(searchCriterias)) : [];
    if (!searchCriteriasArray.length && !scenarioId) {
      return value;
    }
    if (context === 'decision') {
      let desicionsAfterSearch = cloneDeep(value);
      searchCriteriasArray.forEach(searchTerm => {
        desicionsAfterSearch = desicionsAfterSearch.filter(group => {
          group.items = group.items.filter((decision: OclDecision) => {
            const availableDataForSearch = `${decision.message} ${decision.createdBy.monogram()}`;
            if (availableDataForSearch.toLowerCase().includes(searchTerm)) {
              return decision;
            }
          });
          if (group.items.length) {
            return group;
          }
        });
      });
      return desicionsAfterSearch;
    }
    if (context === 'logbook') {
      let logbooksAfterSearch = cloneDeep(value);
      searchCriteriasArray.forEach(searchTerm => {
        logbooksAfterSearch = logbooksAfterSearch.filter(group => {
          group.items = group.items.filter((logbook: OclLogbook) => {
            const availableDataForSearch = `${logbook.text} ${logbook.createdBy.monogram()}`;
            if (availableDataForSearch.toLowerCase().includes(searchTerm)) {
              return logbook;
            }
          });
          if (group.items.length) {
            return group;
          }
        });
      });
      return logbooksAfterSearch;
    }
    if (context === 'event') {
      let eventsAfterSearch = cloneDeep(value);
      if (!searchCriteriasArray.length && scenarioId) {
        eventsAfterSearch = this.filterEvents(eventsAfterSearch, '', scenarioId);
        return eventsAfterSearch;
      }
      searchCriteriasArray.forEach(searchTerm => {
        eventsAfterSearch = this.filterEvents(eventsAfterSearch, searchTerm, scenarioId);
      });
      return eventsAfterSearch;
    }
    if (context === 'global_instruction') {
      let globalInstructionsAfterSearch = cloneDeep(value);
      searchCriteriasArray.forEach(searchTerm => {
        globalInstructionsAfterSearch = globalInstructionsAfterSearch.filter(group => {
          group.items = group.items.filter((globalInstruction: OclGlobalInstruction) => {
            const availableDataForSearch = `${globalInstruction.description} ${globalInstruction.createdBy.monogram()}`;
            if (availableDataForSearch.toLowerCase().includes(searchTerm)) {
              return globalInstruction;
            }
          });
          if (group.items.length) {
            return group;
          }
        });
      });
      return globalInstructionsAfterSearch;
    }
    return value;
  }

  */

  public filterEvents(eventsAfterSearch: any, searchTerm: string, scenarioId: string): any {
    return eventsAfterSearch.filter(tupleEvent => {
      tupleEvent.items = tupleEvent.items.filter((eventInGroup: HolEvent) => {
        if (scenarioId && scenarioId !== eventInGroup.scenario.objectId) {
          return null;
        }
        const availableDataForSearch = `${eventInGroup.description}
                ${eventInGroup.createdBy ? eventInGroup.createdBy.monogram() : ''}
                ${
                  eventInGroup.getNextInfo()
                    ? eventInGroup.getNextInfo().message +
                      `${eventInGroup.getNextInfo().createdBy ? eventInGroup.getNextInfo().createdBy.monogram() : ''}`
                    : ''
                }`;
        if (availableDataForSearch.toLowerCase().includes(searchTerm)) {
          return eventInGroup;
        }
      });
      if (tupleEvent.items.length) {
        return tupleEvent;
      }
    });
  }

  public stringToArray(string): any {
    return string.trim().split(' ');
  }

  public sanitize(terms: string[]): string[] {
    return terms.map(term => term.toLowerCase());
  }
}
