import { MarkdownService } from 'src/app/common/components/markdown-editor/markdown.service';
import { Inject, Injectable } from '@angular/core';

import { UserService } from 'src/app/common/services/user.service';
import { OclScenariosStoreManager } from 'src/app/ocl/store/scenarios/ocl-scenarios.store-manager';
import { OclTagsStoreManager } from 'src/app/ocl/store/tags/ocl-tags.store-manager';
import { HolNotification } from 'src/app/common/models/hol-notification.model';
import { HelperService } from 'src/app/common/services/helper.service';
import { NotificationsService } from 'src/app/common/services/notifications/notifications.service';
import moment from 'moment';
import { CommonStoreManager } from 'src/app/common/store/common.store-manager';
import { RolesService } from 'src/app/common/services/roles.service';
import { OclEvent } from 'src/app/ocl/models/ocl-event.model';

import { HolNextInfo } from '../../../common/models/hol-next-info.model';
import { ParseMapperService } from '../../../common/services/parse-mapper.service';
import { RequestService } from '../../../common/services/request.service';
import { GocEvent } from '../../../goc/models/goc-event.model';
import { MccEventService } from '../../../mcc/services/mcc-event.service';
import { MccOptionsService } from '../../../mcc/services/mcc-options.service';
import { OclEventService } from '../../../ocl/services/ocl-event-service/ocl-event.service';
import { OclEventsStoreManager } from '../../../ocl/store/events/ocl-events-store-manager.service';
import { OclTasksStoreManager } from '../../../ocl/store/tasks/ocl-tasks.store-manager';
import { OccEventTagService } from '../occ-event-tag-service/occ-event-tag.service';
import { OccHistoryService } from '../occ-history-service/occ-history.service';
import { OccMailService } from '../occ-mail-service/occ-mail.service';
import { OccModuleConfigService } from '../occ-module-config/occ-module-config.service';
import { OccSmsService } from '../occ-sms-service/occ-sms.service';
import { OccTagsService } from '../occ-tags-service/occ-tags.service';
import { OccTasksService } from '../occ-tasks.service';
import { FilesService } from 'src/app/common/services/files.service';
import { EventInfos } from '../../../common/models/hol-event';

@Injectable({
  providedIn: 'root',
})
export class OccEventService extends OclEventService {
  // tslint:disable:variable-name
  protected ParseEvents = Parse.Object.extend('OCCEvents');
  protected ParseScenario = Parse.Object.extend('OCCScenario');
  protected ParseEventInfos = Parse.Object.extend('OCCEventInfos');
  protected ParseTaskRef = Parse.Object.extend('OCCTask_REF');
  protected ParseTag = Parse.Object.extend('OCCTag');
  protected ParseEventTag = Parse.Object.extend('OCCEventTag');

  protected ParseGocEvent;
  protected ParseFlight;
  protected ParseGocFlightEvents;
  // tslint:enable
  protected taskEventColumn = 'occEvent';

  public constructor(
    protected requestService: RequestService,
    protected mccEventsService: MccEventService,
    protected mccOptionsService: MccOptionsService,
    protected userService: UserService,
    protected parseMapper: ParseMapperService,
    protected notificationsService: NotificationsService,
    protected historyService: OccHistoryService,
    protected eventTagService: OccEventTagService,
    protected eventsStoreManager: OclEventsStoreManager,
    protected mailService: OccMailService,
    protected smsService: OccSmsService,
    @Inject('ChatService') protected chatService,
    @Inject('$rootScope') protected $rootScope,
    public moduleConfig: OccModuleConfigService,
    private roleService: RolesService,
    private helperService: HelperService,
    protected tasksStoreManager: OclTasksStoreManager,
    protected tasksService: OccTasksService,
    protected tagsService: OccTagsService,
    protected rolesService: RolesService,
    protected commonStoreManager: CommonStoreManager,
    protected tagsStoreManager: OclTagsStoreManager,
    protected scenariosStoreManager: OclScenariosStoreManager,
    protected readonly filesService: FilesService,
    protected markdownService: MarkdownService,
  ) {
    super(
      requestService,
      mccOptionsService,
      userService,
      parseMapper,
      notificationsService,
      historyService,
      eventTagService,
      eventsStoreManager,
      mailService,
      smsService,
      chatService,
      $rootScope,
      moduleConfig,
      tasksStoreManager,
      tasksService,
      tagsService,
      rolesService,
      commonStoreManager,
      tagsStoreManager,
      scenariosStoreManager,
      filesService,
      markdownService,
    );
  }

  protected markPreviousInfosAsDone(event: OclEvent, nextInfoTime: Date): Promise<HolNextInfo[]> {
    const infosToMarkAsDone = event.infos.filter(info => !info.done && moment(info.nextInfoTime).isSameOrBefore(nextInfoTime));
    const promises = infosToMarkAsDone.map(info => this.markInfoAsDone(info, event, true, false));
    return Promise.all(promises);
  }

  public activateECLModule(eclOptionsId: string, moduleName: string, event: OclEvent, infos: EventInfos[]): void {
    const ParseOptions = Parse.Object.extend('ECLOptions');
    const parseObject = new ParseOptions({ id: eclOptionsId });
    const parseEventObject = new this.ParseEvents({ id: event.objectId });
    parseObject.set('moduleIsActivated', true);
    parseObject.set('moduleName', moduleName);
    parseObject.set('occEvent', parseEventObject);
    this.requestService.performSaveQuery(parseObject).then(() => {
      this.roleService.getUsersByRolesUnivers('ECL').then(users => {
        let emails = [];
        let phoneNumbers = [];
        if (users.length) {
          emails = users.map(el => el.email).filter(el => el !== undefined);
          phoneNumbers = users.map(el => el.phone).filter(el => el !== undefined);
        }

        const emailsOCCECL = event.scenario.notificationsEmailECL.filter(el => el !== undefined);
        const phoneNumbersOCCECL = event.scenario.notificationsSmsECL.filter(el => el !== undefined);

        emailsOCCECL.forEach(el => {
          if (!emails.includes(el)) {
            emails.push(el);
          }
        });

        phoneNumbersOCCECL.forEach(el => {
          if (!phoneNumbers.includes(el)) {
            phoneNumbers.push(el);
          }
        });

        if (emails.length) {
          this.mailService.sendEclActivatedMail(event, infos, emails);
        }
        if (phoneNumbers.length) {
          this.smsService.sendEclActivatedSMS(event, event.infos, phoneNumbers);
        }
      });

      this.commonStoreManager.updateEclInfos({
        moduleName,
        moduleIsActivated: true,
        occEventId: event.objectId,
        eclOptionsId,
      });
    });
  }

  public deactivateECLModule(eclOptionsId: string): void {
    const ParseOptions = Parse.Object.extend('ECLOptions');
    const parseObject = new ParseOptions({ id: eclOptionsId });
    parseObject.set('moduleIsActivated', false);
    parseObject.unset('moduleName');
    parseObject.unset('occEvent');
    this.requestService.performSaveQuery(parseObject).then(() => {
      this.commonStoreManager.updateEclInfos({
        moduleName: undefined,
        moduleIsActivated: false,
        occEventId: undefined,
        eclOptionsId,
      });
    });
  }

  // protected getAllGOCEvents(): Promise<any[]> {
  //   return this.gocEventService.getAllToOCC();
  // }

  protected getAllMCCEvents(): Promise<any[]> {
    return this.mccEventsService.getAll({ toOCC: true });
  }

  close(event: GocEvent, reason: number, reasonText: string, notifications: HolNotification[]): Promise<void> {
    return super.close(event, reason, reasonText, notifications).then(() => {
      if (event.toGOC) {
        const parseEvent = new this.ParseEvents({ id: event.objectId });
        parseEvent.set('toGOC', false);
        this.requestService.performSaveAllQuery(parseEvent).then(e => {
          this.deleteDuplicateEventFromModule(event);
        });
      }
    });
  }

  protected async duplicateEventToOtherModule(event: OclEvent): Promise<void> {
    const parseGocEvent = new this.ParseGocEvent();
    parseGocEvent.set('occEvent', new this.ParseEvents({ id: event.objectId }));
    const companies = this.helperService.parseACL(event.acl);
    const acl: Parse.ACL = await this.rolesService.getACLForCompanies(['GOC'], companies);
    parseGocEvent.setACL(acl);
    return this.requestService.performSaveAllQuery(parseGocEvent).then(() => null);
  }

  protected deleteDuplicateEventFromModule(event: OclEvent) {
    const parseOccEvent = new this.ParseEvents({ id: event.objectId });
    const query = new Parse.Query(this.ParseGocEvent);
    query.equalTo('occEvent', parseOccEvent);

    return this.requestService.performFirstQuery(query).then(parseEvent => {
      if (parseEvent) {
        const parseGocEvent = new this.ParseGocEvent({ id: parseEvent.id });
        const parseGocFlight = new Parse.Query(this.ParseFlight);
        const parseGocFltEvents = new Parse.Query(this.ParseGocFlightEvents);
        parseGocFltEvents.equalTo('event', parseGocEvent);
        parseGocFltEvents.matchesQuery('flight', parseGocFlight);

        return Promise.all([
          this.requestService.performDestroyQuery(parseGocEvent),
          this.requestService.performFindAllQuery(parseGocFltEvents),
        ]).then(result => {
          if (result) {
            const fltEventsToDelete = result[1];
            return this.requestService.performDestroyAllQuery(fltEventsToDelete).then();
          }
        });
      }
    });
  }
}
