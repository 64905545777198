import { EclGlobalInstruction } from './ecl-global-instruction';
import { OclGlobalInstructionGroup } from '../../ocl/models/ocl-global-instruction-group.model';

export class EclGlobalInstructionGroup extends OclGlobalInstructionGroup<EclGlobalInstruction> {
  //crisis: EclCrisis;

  constructor(parseObject?: Parse.Object) {
    super(parseObject);
    if (!parseObject) {
      return;
    }
    //this.crisis = new EclCrisis(parseObject.get('crisis'));
  }

  protected newItem(parseObject?: Parse.Object): EclGlobalInstruction {
    return new EclGlobalInstruction(parseObject);
  }
}
