import { CommonStoreManager } from 'src/app/common/store/common.store-manager';
import { Component, inject, Input, OnDestroy, OnInit } from '@angular/core';
import { HolVacation } from '../../../models/hol-vacation.model';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ArchiveModalComponent } from '../../archive-modal/archive-modal.component';
import { cloneDeep } from 'lodash';
import { BaseComponent } from '../../base/base.component';
import { VacationService } from 'src/app/common/services/vacation.service';
import { BehaviorSubject, Subscription } from 'rxjs';
import { VACATION_LOG_STATUS } from '../../../models/hol-vacation.model';
import { ModuleConfigService } from '../../../services/module-config/module-config.service';

@Component({
  selector: 'app-vacation',
  templateUrl: './vacation.component.html',
  styleUrls: ['./vacation.component.scss'],
})
export class VacationComponent extends BaseComponent implements OnInit, OnDestroy {
  @Input()
  public mod: string;
  @Input()
  public isReadOnly: boolean;

  @Input()
  set vacationInput(value) {
    this._vacation.next(value);
  }

  get vacationInput() {
    return this._vacation.getValue();
  }

  private readonly translate = inject(TranslateService);
  public countChecklistDisabledOrCompleted: number;
  public isUtc: boolean;
  public vacation: HolVacation;
  public vacationSub: Subscription;
  public vacationTitle: string;
  private _vacation = new BehaviorSubject<HolVacation>(undefined);

  constructor(
    private dialog: MatDialog,
    private vacationService: VacationService,
    private moduleConfig: ModuleConfigService,
    private commonStoreManager: CommonStoreManager,
  ) {
    super();
  }

  public ngOnInit(): void {
    if (this.moduleConfig.config.localHours) {
      this.isUtc = false;
    } else {
      this.isUtc = !this.isReadOnly;
    }

    this.vacationSub = this._vacation.subscribe(vac => {
      if (!vac) {
        return;
      }
      this.vacation = vac;
      this.vacationTitle = this.vacation.holFunction
        ? this.vacation.holFunction.title
        : '' + ' ' + this.vacation.user.firstName + ' ' + this.vacation.user.lastName;
      this.countChecklistDisabledOrCompleted = this.vacation.checklists.filter(el => el.disabled || el.completedAt).length;
      if (this.countChecklistDisabledOrCompleted === this.vacation.checklists.length && !this.vacation.completedAt) {
        this.completeVacation(true);
      } else if (this.countChecklistDisabledOrCompleted !== this.vacation.checklists.length && this.vacation.completedAt) {
        this.completeVacation(false);
      }
    });
  }

  public ngOnDestroy(): void {
    this.vacationSub.unsubscribe();
  }

  public completeVacation(status): void {
    this.vacationService.completedVacationUpdate(this.vacation, status).then(result => {
      this.commonStoreManager.updateHolVacation(this.vacation.objectId, null, null, { completedAt: result.completedAt });
      this.vacationService.logChecklist(
        this.mod,
        VACATION_LOG_STATUS.END_VACATION_CHECKLIST,
        this.vacation,
        null,
        null,
        this.vacationTitle,
        null,
        this.vacation.objectId,
      );
    });
  }

  public onArchiveItem($event, vacation): void {
    $event.stopPropagation();
    const dialogRef = this.dialog.open(ArchiveModalComponent, {
      data: {
        title: this.translate.instant('TASKS.ARCHIVE'),
        item: cloneDeep(vacation),
        parseObjectClassName: 'HOLVacations',
      },
    });
    dialogRef.afterClosed().subscribe((item: HolVacation) => {
      if (item && item.closeReason) {
        this.commonStoreManager.removeHolVacations(item.objectId);
        this.vacationService.logChecklist(
          this.mod,
          VACATION_LOG_STATUS.ARCHIVE_VACATION_CHECKLIST,
          item,
          null,
          null,
          this.vacationTitle,
          null,
          this.vacation.objectId,
        );
      }
    });
  }
}
