import { Injectable } from '@angular/core';
import { FltFlightEventService } from 'src/app/flt/services/flt-flight-event.service';

@Injectable({
  providedIn: 'root',
})
export class OpsFlightEventService extends FltFlightEventService {
  // tslint:disable:variable-name
  protected ParseFlightEvent = Parse.Object.extend('OPSFlightEvents');
  protected ParseFlightEventTag = Parse.Object.extend('OPSFlightEventTag');
  protected ParseEvent = Parse.Object.extend('OPSEvents');
  protected ParseEventTag = Parse.Object.extend('OPSEventTag');
  protected ParseTag = Parse.Object.extend('OPSTag');
  protected ParseEventInfos = Parse.Object.extend('OPSEventInfos');
  protected ParseScenario = Parse.Object.extend('OPSScenario');
  protected ParseFlight = Parse.Object.extend('GOCFlight');
}
