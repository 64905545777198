import moment from 'moment';
import { HolAttachments, HolisFile } from '../../models/hol-attachments.model';
import { TranslateService } from '@ngx-translate/core';

export type Styles = Record<string, string>;

export const DEFAULT_TABLE_STYLES: Styles = {
  border: '1px solid #E7E7E7',
  width: '100%!important',
  'border-collapse': 'collapse',
};

export const DEFAULT_CELL_STYLES: Styles = {
  padding: '5px',
  'white-space': 'nowrap',
};

export const DEFAULT_TIME_CELL_STYLES: Styles = {
  'white-space': 'nowrap',
  width: '1px',
};

export function table(rows: string[], styles: Styles = {}): string {
  const rowContent = rows.join('');
  const defaultStyle: Record<string, string> = {
    ...DEFAULT_TABLE_STYLES,
    ...styles,
  };
  return `<table style="${styleToString(defaultStyle)}">${rowContent}</table>`;
}

export function row(cells: string[], includeTd = false): string {
  return `<tr>${includeTd ? cells.reduce((r, c) => r + cell(c)) : cells.join('')}</tr>`;
}

export function cell(content: string, styles: Styles = {}): string {
  const st: Styles = {
    ...DEFAULT_CELL_STYLES,
    ...styles,
  };
  return `<td style="${styleToString(st)}">${content}</td>`;
}

export function styleToString(styleObject: Styles): string {
  return Object.entries(styleObject)
    .map(([k, v]) => `${k}:${v}`)
    .join(';');
}

export function timeCell(date: Date, styles: Record<string, string> = {}): string {
  const cellStyles: Styles = {
    ...DEFAULT_TIME_CELL_STYLES,
    ...styles,
  };
  return cell(timeContent(date), cellStyles);
}

export function timeContent(date: Date): string {
  const hour = moment(date).utc().format('HH:mm[UTC]');
  const day = moment(date).utc().format('ddd[&nbsp;]DD');
  return `${hour}<br/>${day}`;
  // return wrapInCell ? `<td style="white-space: nowrap: width: 1px;">${content}</td>` : content;
}

export function hasAttachments(item: { attachments?: HolAttachments }): boolean {
  const { attachments } = item;
  return !!attachments && !!(attachments.note || attachments.noteFile || attachments.file || attachments.files || attachments.image);
}

export function hiddenAttachmentsContent(attachments: HolAttachments, translateService: TranslateService): string {
  let content = '';
  if (attachments && !attachments.isEmpty) {
    content = `<span>${translateService.instant('MAIL.ATTACHMENTS.HAS_ATTACHMENTS')} :</span>`;
    content += '<ul style="list-style-type: none">';
    const notes = attachments.noteFile ? 1 : 0;
    const files = (attachments.file ? 1 : 0) + (attachments.files || []).length;
    const images = attachments.image ? 1 : 0;
    if (notes) {
      content += `<li>${emoji('note')} ${notes} ${singularOrPlural('MAIL.ATTACHMENTS.NOTE', notes, translateService)}</li>`;
    }
    if (files) {
      content += `<li>${emoji('file')} ${files} ${singularOrPlural('MAIL.ATTACHMENTS.FILE', files, translateService)}</li>`;
    }
    if (images) {
      content += `<li>${emoji('image')} ${images} ${singularOrPlural('MAIL.ATTACHMENTS.IMAGE', images, translateService)}</li>`;
    }
  }
  return content;
}

function singularOrPlural(key: string, n: number, translateService: TranslateService): string {
  const sp = n === 1 ? 'SINGULAR' : 'PLURAL';
  return translateService.instant(`${key}.${sp}`);
}

export function attachmentsContent(attachments: HolAttachments, hideLinks: boolean, translateService: TranslateService): string {
  let cell = '';
  if (hideLinks) {
    cell = hiddenAttachmentsContent(attachments, translateService);
  } else if (attachments) {
    if (attachments.noteFile) {
      cell += attachmentLink(attachments.noteFile, 'note');
    } else if (attachments.note) {
      cell += `<span title="${attachments.note}">${emoji('note')}</span>&nbsp;`;
    }
    if (attachments.file) {
      cell += attachmentLink(attachments.file, 'file');
    }
    if (attachments.files) {
      attachments.files.forEach((f: HolisFile) => {
        cell += attachmentLink(f, 'file');
      });
    }
    if (attachments.image) {
      cell += attachmentLink(attachments.image, 'image');
    }
  }
  return cell;
}

export function messageWithAttachments(
  message: string,
  attachments: HolAttachments,
  hideLinks: boolean,
  translateService: TranslateService,
): string {
  return messageWithAttachmentsContents(message, attachments, hideLinks, translateService).reduce((list, cc, idx, array) => {
    const styles: Styles = idx === array.length - 1 ? { width: '1px' } : {};
    return list + cell(cc, styles);
  }, '');
}

export function messageWithAttachmentsContents(
  message: string,
  attachments: HolAttachments,
  hideLinks: boolean,
  translateService: TranslateService,
): string[] {
  const contents = [message];
  if (attachments) {
    if (hideLinks) {
      contents[0] += `<p>${hiddenAttachmentsContent(attachments, translateService)}</p>`;
    } else {
      contents.push(attachmentsContent(attachments, hideLinks, translateService));
    }
  }
  return contents;
  // let msg = `<td>${message}`;
  // if (attachments) {
  //   if (hideLinks) {
  //     msg += `<p>${hiddenAttachmentsContent(attachments, translatePipe)}</p></td>`;
  //   } else {
  //     msg += `</td>${attachmentsContent(attachments, hideLinks, translatePipe, true)}`;
  //   }
  // } else {
  //   msg += '</td>';
  // }
  // return msg;
}

function attachmentLink(file: HolisFile, type: 'note' | 'file' | 'image'): string {
  if (!file) {
    return '';
  } else {
    let attrs = `href="${file.url}" title="${file.fileName}" style="border:4px solid orange;width:64px;height:64px;border-radius: 24px;padding:4px;"`;
    if (type === 'note') {
      attrs += ' target="_blank"';
    }
    return `<a ${attrs} >${emoji(type)}</a>&nbsp;`;
  }
}

function emoji(type: 'note' | 'file' | 'image'): string {
  switch (type) {
    case 'note':
      return '📝';
    case 'file':
      return '📎';
    case 'image':
      return '🖼';
    default:
      return '';
  }
}
