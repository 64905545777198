import { EclFunctionState } from '../../ecl.model';
import { EclFunctionActions, EclFunctionActionTypes } from './function.action';

import { EclFunction, EclUserFunction, EclUserFunctionRef } from '../../models/ecl-function';
import { HolUser, HolUserWithCompanies } from '../../../common/models/hol-user.model';
import { EclCrisis } from '../../models/ecl-crisis';

const functionInitialState: Readonly<EclFunctionState> = {
  allUserFunctionsRef: [],
  functions: [],
  currentUserFunctions: [],
  selectedUserFunction: undefined,
  filterFunction: undefined,
  allUserFunctions: [],
  allEclUsers: [],
  isReferential: false,
};

export function eclFunctionsReducer(state: EclFunctionState = functionInitialState, action: EclFunctionActions): EclFunctionState {
  switch (action.type) {
    case EclFunctionActionTypes.UPDATE_ECL_TEAM_FUNCTION_REF:
      return state;
    case EclFunctionActionTypes.INIT_ECL_FUNCTION: {
      const { allFunctions, allUserFunctions, allUsers, currentUserId, allUserFunctionsRef, currentCrisis } = action.payload;
      return generateInitialState(state, allFunctions, allUserFunctions, allUsers, currentUserId, allUserFunctionsRef, currentCrisis);
    }
    case EclFunctionActionTypes.UPDATE_ECL_FUNCTION_REFERENTIAL_MODE:
      return {
        ...state,
        isReferential: action.payload,
      };
    case EclFunctionActionTypes.UPDATE_ECL_CURRENT_USER_FUNCTION:
      return {
        ...state,
        selectedUserFunction: action.payload,
      };
    case EclFunctionActionTypes.UPDATE_ECL_FILTER_FUNCTION:
      return {
        ...state,
        filterFunction: action.payload,
      };
    case EclFunctionActionTypes.UPDATE_ECL_TEAM_FUNCTION:
      return {
        ...state,
        teamSelectedFunction: action.payload,
      };
    case EclFunctionActionTypes.UPDATE_ECL_TEAM_USER:
      return {
        ...state,
        teamSelectedUser: action.payload,
      };
    case EclFunctionActionTypes.ADD_ECL_USER_FUNCTION:
      const { addUserFunction, addCurrentUserId } = action.payload;
      return {
        ...state,
        allUserFunctions: [...state.allUserFunctions, addUserFunction],
        currentUserFunctions:
          addUserFunction.userId === addCurrentUserId
            ? [...state.currentUserFunctions, addUserFunction.function]
            : state.currentUserFunctions,
      };

    case EclFunctionActionTypes.ADD_ECL_USER_FUNCTIONS:
      const { addUserFunctions, addCurrentUserIds } = action.payload;
      const filteredUF = addUserFunctions.filter(uf => uf.userId === addCurrentUserIds).map(uf => uf.function);

      return {
        ...state,
        allUserFunctions: [...state.allUserFunctions, ...addUserFunctions],
        currentUserFunctions: [...state.currentUserFunctions, ...filteredUF],
      };

    case EclFunctionActionTypes.ADD_ECL_USER_FUNCTION_REF:
      const { userFunctionRef } = action.payload;
      return {
        ...state,
        allUserFunctionsRef: [...state.allUserFunctionsRef, userFunctionRef],
      };
    case EclFunctionActionTypes.DELETE_ECL_USER_FUNCTION:
      const { userFunction, currentUserId } = action.payload;
      return {
        ...state,
        allUserFunctions: state.allUserFunctions.filter(uf => uf.objectId !== userFunction.objectId),
        currentUserFunctions:
          userFunction.userId === currentUserId
            ? state.currentUserFunctions.filter(f => f.functionId !== userFunction.functionId)
            : state.currentUserFunctions,
      };
    case EclFunctionActionTypes.DELETE_ECL_USER_FUNCTION_REF:
      const { delUserFunctionRef } = action.payload;
      return {
        ...state,
        allUserFunctionsRef: state.allUserFunctionsRef.filter(uf => uf.objectId !== delUserFunctionRef.objectId),
      };
    case EclFunctionActionTypes.ADD_ECL_FUNCTION:
      return {
        ...state,
        functions: [...state.functions, action.payload],
      };
    case EclFunctionActionTypes.UPDATE_ECL_FUNCTION:
      const updatedFunction = action.payload;
      return {
        ...state,
        functions: state.functions.map(f => (f.functionId === updatedFunction.functionId ? updatedFunction : f)),
      };
    case EclFunctionActionTypes.ADD_ECL_USER:
      return {
        ...state,
        allEclUsers: [...state.allEclUsers, action.payload],
      };

    case EclFunctionActionTypes.UPDATE_ECL_USER:
      const updatedUser = action.payload;
      return {
        ...state,
        allEclUsers: state.allEclUsers.map(user =>
          user.objectId === updatedUser.objectId ? ({ ...user, ...updatedUser } as HolUserWithCompanies) : user,
        ),
      };
    case EclFunctionActionTypes.UPDATE_MANY_ECL_USER:
      const updatedUsers = action.payload;
      return {
        ...state,
        allEclUsers: state.allEclUsers.map(user => {
          const matchingUser = updatedUsers.find(u => u.objectId === user.objectId);
          return matchingUser ? ({ ...user, ...matchingUser } as HolUserWithCompanies) : user;
        }),
      };

    case EclFunctionActionTypes.CHANGE_CRISIS:
      const { baseFs, eclFunction } = action.payload;
      const currentUserIdCrisis = Parse.User.current().get('userId');

      const functionMap = new Map(baseFs.map((f: EclFunction) => [f.functionId, f]));
      const userMap = new Map(state.allEclUsers.map((u: HolUser) => [u.userId, u]));

      const userFunctions = eclFunction
        .map(
          uf =>
            ({
              ...uf,
              function: functionMap.get(uf.functionId),
              user: userMap.get(uf.userId),
            } as EclUserFunction),
        )
        .filter(uf => uf.function && uf.user);

      const currentUserFunctions = userFunctions
        .filter(uf => uf.userId === currentUserIdCrisis)
        .map(uf => uf.function)
        .filter((value, index, array) => array.indexOf(value) === index && !value.deleted);

      const selectedUserFunction =
        state.selectedUserFunction && currentUserFunctions.some(func => func.functionId === state.selectedUserFunction.functionId)
          ? state.selectedUserFunction
          : currentUserFunctions.length === 1
          ? currentUserFunctions[0]
          : null;

      return {
        ...state,
        currentUserFunctions,
        functions: baseFs,
        allUserFunctions: userFunctions,
        selectedUserFunction,
      };
    default:
      return state;
  }
}

function generateInitialState(
  currentState: EclFunctionState,
  baseFs: EclFunction[],
  baseUserFs: EclUserFunction[],
  baseUsers: HolUserWithCompanies[],
  currentUserId: string,
  baseUserFsRef: EclUserFunctionRef[],
  currentCrisis: EclCrisis,
): EclFunctionState {
  const functionMap = new Map(baseFs.map(f => [f.functionId, f]));
  const userMap = new Map(baseUsers.map(u => [u.userId, u]));

  const userFunctions = baseUserFs
    .map(
      uf =>
        ({
          ...uf,
          function: functionMap.get(uf.functionId),
          user: userMap.get(uf.userId),
        } as EclUserFunctionRef),
    )
    .filter(uf => uf.function && uf.user);

  const currentUserFunctions = userFunctions
    .filter(uf => uf.userId === currentUserId)
    .map(uf => uf.function)
    .filter((value, index, array) => array.indexOf(value) === index && !value.deleted);

  const selectedUserFunction = currentUserFunctions.length === 1 ? currentUserFunctions[0] : currentState.selectedUserFunction;

  const userFunctionsRef = baseUserFsRef.map(
    uf =>
      ({
        ...uf,
        function: functionMap.get(uf.functionId),
        user: userMap.get(uf.userId),
      } as EclUserFunctionRef),
  );

  return {
    ...currentState,
    allUserFunctionsRef: userFunctionsRef,
    functions: baseFs,
    allUserFunctions: userFunctions,
    currentUserFunctions,
    selectedUserFunction,
    allEclUsers: baseUsers,
  };
}
