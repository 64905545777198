import moment from 'moment';

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'time',
})
export class TimePipe implements PipeTransform {
  transform(value: any, utc?: any): any {
    if (value) {
      let momentDate = moment(value);
      let dateFormat = '';
      if (utc) {
        momentDate = momentDate.utc();
      }

      if (!momentDate.isSame(moment(), 'days')) {
        dateFormat = dateFormat + 'DD/MM[\r\n]';
      }
      dateFormat += 'HH:mm';
      if (utc) {
        dateFormat += '[Z]';
      }

      return momentDate.format(dateFormat);
    }

    return null;
  }
}
