import { take } from 'rxjs/operators';
import { OclScenariosStoreManager } from './../../store/scenarios/ocl-scenarios.store-manager';
import { Component, inject, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { OclEventService } from '../../services/ocl-event-service/ocl-event.service';
import { OclHistoryService } from '../../services/ocl-history-service/ocl-history.service';
import { OclHistoryLog } from '../../models/ocl-history-log.model';
import { ModuleConfigService } from 'src/app/common/services/module-config/module-config.service';
import { Translate } from 'ol/interaction';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-ocl-timeline-search-modal',
  templateUrl: './ocl-timeline-search-modal.component.html',
  styleUrls: ['./ocl-timeline-search-modal.component.scss'],
})
export class OclTimelineSearchModalComponent implements OnInit {
  form: UntypedFormGroup;
  archivedEvents: boolean;
  isReadOnly: boolean;
  searchLaunched: boolean;
  events = [];
  scenarios = [];
  loading = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<OclTimelineSearchModalComponent>,
    private fb: UntypedFormBuilder,
    private historyService: OclHistoryService<OclHistoryLog>,
    private eventService: OclEventService,
    private scenariosStoreManager: OclScenariosStoreManager,
    public moduleConfig: ModuleConfigService,
  ) {
    this.archivedEvents = false;
    this.searchLaunched = false;
    this.createForm();
  }

  get description() {
    return this.form.get('description') as UntypedFormControl;
  }

  get scenario() {
    return this.form.get('scenario') as UntypedFormControl;
  }

  createForm() {
    this.form = this.fb.group({
      scenario: [undefined, undefined],
      description: [undefined],
    });
  }

  ngOnInit(): void {
    this.scenariosStoreManager.scenariosState
      .pipe(take(1))
      .toPromise()
      .then(scenarios => {
        this.scenarios = scenarios;
      });
  }

  onEventUpdated(event) {
    this.search();
    this.historyService.fetchNewData();
  }

  search() {
    this.events = [];
    this.searchLaunched = true;
    this.loading = true;
    const searchedData = {
      scenario: this.form.value.scenario,
      description: this.form.value.description,
      archived: this.archivedEvents,
    };
    this.eventService.getByData(searchedData).then(results => {
      this.loading = false;
      this.events = results;
    });
  }
}
