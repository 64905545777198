import { Injectable } from '@angular/core';
import { OclDecisionTagService } from '../../../ocl/services/ocl-decision-tag-service/ocl-decision-tag.service';

@Injectable({
  providedIn: 'root',
})
export class OccDecisionTagService extends OclDecisionTagService {
  // tslint:disable:variable-name
  protected ParseDecisionTag = Parse.Object.extend('OCCDecisionTag');
  protected ParseDecision = Parse.Object.extend('OCCDecisions');
  protected ParseTag = Parse.Object.extend('OCCTag');
  // tslint:enabled
}
