import { OclTagsStoreManager } from '../../../ocl/store/tags/ocl-tags.store-manager';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { HolTag } from '../../models/hol-tag';
import { TagChangeService } from '../../services/tag-change.service';
import { BaseComponent } from '../base/base.component';
import { TagsService } from '../../services/tags.service';
import { ModuleConfigService } from '../../services/module-config/module-config.service';
import { HelperService } from '../../services/helper.service';
import { cloneDeep, find, orderBy, remove } from 'lodash';
import { take } from 'rxjs/operators';
import { EclCrisisStoreManager } from '../../../ecl/store/crisis/crisis.store-manager';
import { EclTagsService } from '../../../ecl/services/ecl-tags-service/ecl-tags.service';
import { CrisisStoreManager } from '../../../erp/store/crisis/crisis.store-manager';
import { RolesService } from '../../services/roles.service';
import { MccCabinTagService } from '../../../mcc/services/mcc-cabin-tag.service';
import { Observable, Subscription } from 'rxjs';
import { ErpTagsService } from 'src/app/erp/services/erp-tag.service';

@Component({
  selector: 'app-tag-selector',
  templateUrl: './tag-selector.component.html',
  styleUrls: ['./tag-selector.component.scss'],
})
export class TagSelectorComponent extends BaseComponent implements OnInit, OnDestroy {
  @Input()
  public showOnlyDefaultTags = false;
  @Input()
  public readOnly: boolean;
  @Input()
  public showTitle? = true;
  @Input()
  public defaultTags: HolTag[] = [];
  @Input()
  public viewOnly: boolean;
  @Output()
  public selectedTagsChange = new EventEmitter();
  @Output()
  public updateTags = new EventEmitter();
  public tags: HolTag[] = [];
  public tagForm: UntypedFormGroup;
  public showInput = false;
  public selectedCrisis;
  public isLoading = false;

  public eclTagsIsLoading = false;
  isLoading$ = new Observable(observer => {
    observer.next(this.isLoading);
  });
  subscription: Subscription;
  public isOverflowing = false;
  public isExpanded = true;
  public isPushing = false;
  @Input()
  public isCabin? = false;
  private isAlive = true; // Contrôle si le composant est vivant

  constructor(
    private fb: UntypedFormBuilder,
    public moduleConfig: ModuleConfigService,
    private tagsService: TagsService,
    private tagChangeService: TagChangeService,
    @Inject('$rootScope') public $rootScope,
    private tagsStoreManager: OclTagsStoreManager,
    private eclCrisisStoreManager: EclCrisisStoreManager,
    private erpCrisisStoreManager: CrisisStoreManager,
    private eclTagService: EclTagsService,
    private erpTagService: ErpTagsService,
    private moduleConfigService: ModuleConfigService,
    private roleService: RolesService,
    private helperService: HelperService,
    private mccTagsService: MccCabinTagService,
    private cd: ChangeDetectorRef,
  ) {
    super();

    this.isLoading = true;
    if (this.defaultTags) {
      this.defaultTags = [];
    }
    if (this.viewOnly && this.defaultTags.length == 0) {
      this.tags = this.selectedTags;
      this.selectedTags = this.selectedTags || [];
    } else if (this.defaultTags.length > 0) {
      this.tags = this.defaultTags;
      this.selectedTags = this.defaultTags;
    } else {
      this.tagsStoreManager.tagsState
        .pipe(take(1))
        .toPromise()
        .then((tags: HolTag[]) => {
          this.roleService.$companiesRolesFilter.subscribe(v => {
            if (this.moduleConfigService.config.moduleName !== 'ecl') {
              const tagFilterByAclFilter = tags.filter(t => this.helperService.allowedACL(v, t.acl));
              this.tags = orderBy(tagFilterByAclFilter, ['name'], ['asc']);
              this.selectedTags = this.selectedTags || [];
            }
          });
        });
    }
  }

  public _selectedTags: HolTag[] = [];

  @Input()
  get selectedTags() {
    return this._selectedTags || [];
  }

  set selectedTags(value: HolTag[]) {
    this._selectedTags = value;
    this.selectedTagsChange.emit(this._selectedTags);
  }

  ngOnInit() {
    super.ngOnInit();
    if (this.moduleConfigService.config.moduleName === 'mcc' && this.isCabin) {
      this.isLoading = true;
      this.mccTagsService.getAll().then(tags => {
        this.tags = tags;
        this.isLoading = false;
        this.cd.detectChanges();
      });
    }

    if (this.moduleConfigService.config.moduleName === 'ecl' && this.readOnly === true) {
      this.tags = this.selectedTags;
      this.cd.detectChanges();
    } else if (this.moduleConfigService.config.moduleName === 'ecl') {
      this.eclCrisisStoreManager.$eclSelectedCrisis.subscribe(async c => {
        this.eclTagsIsLoading = true;

        if (c) {
          this.selectedCrisis = c;
          await new Promise(resolve => setTimeout(resolve, 500)); // 2000ms = 2s
          const tags = await this.eclTagService.getAllTagByCrisis(c, true);
          this.tags = tags;
          if (this.isAlive) {
            this.cd.detectChanges();
          }
        }
        this.eclTagsIsLoading = false;
        if (this.isAlive) {
          this.cd.detectChanges();
        }
      });
    } else if (this.moduleConfigService.config.moduleName === 'erp') {
      if (this.defaultTags !== undefined && this.defaultTags.length > 0) {
        this.tags = this.defaultTags;
        this.selectedTags = this.defaultTags;
        this.viewOnly = true;
        this.readOnly = true;
        if (this.isAlive) {
          this.cd.detectChanges();
        }

        this.selectedTagsChange.emit(this.selectedTags);
        this.updateTags.emit(true);
      } else {
        this.erpCrisisStoreManager.crisisErpState.subscribe(c => {
          this.isLoading = true;
          this.erpTagService.getAll().then(tags => {
            this.tags = tags;
            this.isLoading = false;
            if (this.isAlive) {
              this.cd.detectChanges();
            }
          });
          if (c) {
            this.selectedCrisis = c;
            if (this.isAlive) {
              this.cd.detectChanges();
            }
          }
          this.isLoading = false;
        });
      }
    } else {
      this.isLoading = false;
    }

    this.subscription = this.isLoading$.subscribe(() => {
      this.checkListHeight();
      this.cd.detectChanges();
    });
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    if (this.subscription) this.subscription.unsubscribe();
    this.isAlive = false;
  }

  public showToggleAll(): any {
    return !this.readOnly && this.tags.length > 0 && this.selectedTags.length !== this.tags.length;
  }

  public showUntoggleAll(): any {
    return !this.readOnly && this.selectedTags.length > 0;
  }

  public toggleTag($event, tag): void {
    if (!this.readOnly && !this.viewOnly) {
      const removed = remove(this.selectedTags, { objectId: tag.objectId });
      if (removed.length === 0) {
        this.selectedTags.push(tag);
      }
      this.selectedTagsChange.emit(this.selectedTags);
      this.updateTags.emit(true);
    } else {
      $event.preventDefault();
    }
  }

  public setAllTag(): void {
    this.selectedTags = cloneDeep(this.tags);
  }

  public unsetAllTag(): void {
    this.selectedTags = [];
  }

  public isSelectedTag(tag): any {
    return !!find(this.selectedTags, { objectId: tag.objectId });
  }

  // https://gist.github.com/jedfoster/7939513
  public mixColors(color1, color2, weight): any {
    return HelperService.mixColors(color1, color2, weight);
  }

  public cancelAddTag(): void {
    this.tagForm = null;
    this.showInput = false;
  }

  public onSubmitAddTag(): void {
    this.isPushing = true;
    const { color, name } = this.tagForm.value;
    this.tagsService.create(color, name, this.selectedCrisis).then(async () => {
      const tagsN = await this.eclTagService.getAllTagByCrisis(this.selectedCrisis, false);
      this.tags = tagsN;

      this.tagForm = null;
      this.showInput = false;
      this.tags = orderBy(this.tags, ['name'], ['asc']);
      this.isPushing = false;
      this.checkListHeight();

      this.cd.detectChanges();
    });
  }

  public buttonStyle(tag: HolTag): any {
    return {
      'background-color': this.isSelectedTag(tag) ? tag.color : this.mixColors('#FFFFFF', tag.color, 90),
      'border-color': tag.color,
      color: this.isSelectedTag(tag) ? 'white' : tag.color,
    };
  }

  public noWhitespaceValidator(control: UntypedFormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { whitespace: true };
  }

  onClickExpand() {
    this.isExpanded = !this.isExpanded;
  }

  // I don't know why it works in some modules, but not in others.
  checkListHeight() {
    // Element as it may or may not be displayed, I have to check that it exists.
    const interval = setInterval(() => {
      const list = document.getElementById('tagList');
      if (list) {
        this.isOverflowing = list.offsetHeight < list.scrollHeight;
        clearInterval(interval);
      }
    }, 1000);
  }
}
