import { HolCrisis, HolCrisisType } from '../../common/models/hol-crisis';

export class EclCrisis extends HolCrisis {
  public type: EclCrisisType;
  public erpType?: EclCrisisERPType;
  public closedAt: Date;
  functionsToNotify: string[];

  public constructor(parseObject?: Parse.Object) {
    super(parseObject);
    if (parseObject) {
      this.type = new EclCrisisType(parseObject.get('crisisType'));
      let value = parseObject.get('functionsToNotify');
      this.functionsToNotify = value ? value.trim().split('|') : [];
      this.closedAt = parseObject.get('closedAt');
      this.erpType = parseObject.get('erpType') ? new EclCrisisERPType(parseObject.get('erpType')) : null;
    }
  }
}

export class EclCrisisType extends HolCrisisType {
  functionsToNotify: string[] = [];
  erpAvailable: string[] = [];

  public constructor(parseObject?: Parse.Object) {
    super(parseObject);
    if (parseObject) {
      let parseFunctionIdToNotify = parseObject.get('functionIdsToNotify') || '';
      this.functionsToNotify = parseFunctionIdToNotify ? parseFunctionIdToNotify.trim().split('|') : [];
      this.erpAvailable = parseObject.get('erpAvailable') ? parseObject.get('erpAvailable').trim().split('|') : [];
    }
  }
}

export class EclCrisisERPType extends EclCrisisType {
  public constructor(parseObject?: Parse.Object) {
    super(parseObject);
    if (parseObject) {
    }
  }
}
