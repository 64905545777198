import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AppState } from 'src/app/store';
import { InitLinkedItems, ResetLinkedItems, UpdateLinkedItems, UpdateLinkedItemsFromPooling } from './ocl-linked-items.actions';

@Injectable({
  providedIn: 'root',
})
export class OclLinkedItemsStoreManager {
  private readonly _linkedItemsState: Observable<any>;
  protected $currentOclModule: Observable<string>;

  constructor(private store: Store<AppState>) {
    this._linkedItemsState = this.store.select('ocl', 'linkedItemsId');
    this.$currentOclModule = store.select('common', 'currentOclModule');
  }

  getValue(obj: Observable<any>) {
    let value: any;
    obj.subscribe(v => (value = v));
    return value;
  }

  get oclLinkedItemsState(): Observable<any> {
    return this._linkedItemsState;
  }

  initLinkedItems(linkedItems: { [key: string]: any }): void {
    this.store.dispatch(new InitLinkedItems(linkedItems));
  }

  updateLinkedItems(linkedItems: { [key: string]: any }, currentModule?): void {
    if (!currentModule || (currentModule && this.getValue(this.$currentOclModule) === currentModule)) {
      this.store.dispatch(new UpdateLinkedItems(linkedItems));
    }
  }

  updateLinkedItemsFromPooling(linkedItems: { [key: string]: any }, currentModule): void {
    if (this.getValue(this.$currentOclModule) === currentModule) {
      this.store.dispatch(new UpdateLinkedItemsFromPooling(linkedItems));
    }
  }

  resetLinkedItems(): void {
    this.store.dispatch(new ResetLinkedItems());
  }
}
