import { Component, Input, OnInit, Inject, Output, EventEmitter } from '@angular/core';
import moment from 'moment';

export class nextInfoType {
  nextInfoTime: Date;
  nextInfoDone?: boolean;
}

@Component({
  selector: 'app-hol-next-info',
  templateUrl: './hol-next-info.component.html',
  styleUrls: ['./hol-next-info.component.scss'],
})
export class HolNextInfoComponent implements OnInit {
  @Input() nextInfo: nextInfoType;
  @Input() isReadOnly = false;
  @Input() isUTC = true;
  @Input() hasDoneBtn = true;

  nextInfoTime;
  nextInfoDate;

  @Output() saveNextInfo = new EventEmitter<nextInfoType>();
  @Output() isValideDate = new EventEmitter<boolean>();

  constructor(@Inject('CONSTANTS') protected CONSTANTS) {}

  ngOnInit() {
    if (this.nextInfo) {
      this.nextInfoDate = this.nextInfo.nextInfoTime;
      let defaultNIT;
      if (this.isUTC) {
        defaultNIT = moment.utc(this.nextInfo.nextInfoTime);
      } else {
        defaultNIT = moment(this.nextInfo.nextInfoTime);
      }
      this.nextInfoTime = this.nextInfo.nextInfoTime && defaultNIT.format('HH:mm');
    }
  }

  isElapsed(date): boolean {
    return date ? moment(date).isBefore(moment()) : false;
  }

  isAlmostElapsed(date): boolean {
    return date ? moment(date).diff(moment(), 'minutes') < this.CONSTANTS.TIME_BEFORE_WARNING : false;
  }

  getNextInfoTime() {
    if (!this.nextInfoDate || !this.nextInfoTime) {
      this.saveNextInfo.emit(undefined);
      return undefined;
    }
    const now = moment.utc();
    let nextInfo: moment.Moment;
    const splitedTime = this.nextInfoTime ? this.nextInfoTime.split(':') : undefined;

    if (this.nextInfoTime) {
      if (this.nextInfoDate) {
        if (this.isUTC) {
          nextInfo = moment.utc({
            hour: splitedTime[0],
            minute: splitedTime[1],
            day: moment(this.nextInfoDate).date(),
            month: moment(this.nextInfoDate).month(),
            year: moment(this.nextInfoDate).year(),
          });
        } else {
          nextInfo = moment({
            hour: moment(this.nextInfoTime, 'HH:mm').hours(),
            minute: moment(this.nextInfoTime, 'HH:mm').minutes(),
            day: moment(this.nextInfoDate).date(),
            month: moment(this.nextInfoDate).month(),
            year: moment(this.nextInfoDate).year(),
          });
        }
      } else {
        if (this.isUTC) {
          nextInfo = moment.utc({
            hour: splitedTime[0],
            minute: splitedTime[1],
          });
        } else {
          nextInfo = moment({
            hour: splitedTime[0],
            minute: splitedTime[1],
          });
        }

        while (nextInfo.isBefore(now)) {
          nextInfo.add(1, 'days');
        }
      }
      return nextInfo.toDate();
    }
  }

  markAsDone($event) {
    $event.stopPropagation();
    this.nextInfo.nextInfoDone = !this.nextInfo.nextInfoDone ? true : false;
    this.callSaveInfo();
  }

  callSaveInfo() {
    if (!this.nextInfoDate || !this.nextInfoTime) {
      this.isValideDate.emit(false);
      return;
    }
    this.isValideDate.emit(true);
    if (!moment(this.nextInfo.nextInfoTime).isSame(this.getNextInfoTime())) {
      this.nextInfo.nextInfoDone = false;
    }
    this.nextInfo.nextInfoTime = this.getNextInfoTime();
    this.saveNextInfo.emit(this.nextInfo);
  }

  onDateTimeSelected($event: Date) {
    this.nextInfoDate = $event;
    this.nextInfoTime = moment(this.nextInfoTime, 'HH:mm');

    this.nextInfo.nextInfoTime = $event;
    this.saveNextInfo.emit(this.nextInfo);
    //  this.callSaveInfo();
  }
}
