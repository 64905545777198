import { GocModuleConfigService } from './../goc-module-config/goc-module-config.service';
import { Inject, Injectable } from '@angular/core';
import { RequestService } from '../../../common/services/request.service';
import { GocOptionsService } from '../goc-options-service/goc-options.service';
import { OclHistoryService } from '../../../ocl/services/ocl-history-service/ocl-history.service';
import { ParseMapperService } from '../../../common/services/parse-mapper.service';
import moment, { Moment } from 'moment';
import { GocFlightService } from '../goc-flight-service/goc-flight.service';
import { OclHistoryLog } from '../../../ocl/models/ocl-history-log.model';

@Injectable({
  providedIn: 'root',
})
export class GocHistoryService extends OclHistoryService<OclHistoryLog> {
  // tslint:disable:variable-name
  protected ParseHistory = Parse.Object.extend('GOCLogs');
  protected ParseEvents = Parse.Object.extend('GOCEvents');
  protected ParseDecisions = Parse.Object.extend('GOCDecisions');
  protected ParseLogbooks = Parse.Object.extend('GOCLogBook');
  // tslint:enable

  public constructor(
    protected requestService: RequestService,
    @Inject('$rootScope') protected $rootScope,
    protected optionsService: GocOptionsService,
    protected parseMapper: ParseMapperService,
    private gocFlightService: GocFlightService,
    protected moduleConfig: GocModuleConfigService,
  ) {
    super(requestService, $rootScope, optionsService, parseMapper, moduleConfig);
  }

  public getLogsByTimeSlots(forceToRefresh: boolean, fromDate?: Moment, toDate?: Moment): Promise<any[]> {
    return super.getLogsByTimeSlots(forceToRefresh, fromDate, toDate).then(timeSlots => {
      return this.gocFlightService.getByTimeSlots(forceToRefresh, fromDate, toDate).then(flights => {
        flights.forEach(flight => {
          const bufferIndex = timeSlots.findIndex(tm => moment(flight.std).isBetween(tm.timeEnd, tm.timeStart, undefined, '[)'));
          if (bufferIndex !== -1) {
            if (!timeSlots[bufferIndex].logs.flights) {
              timeSlots[bufferIndex].logs.flights = [];
            }
            timeSlots[bufferIndex].logs.flights.unshift(flight);
          }
        });
        return timeSlots;
      });
    });
  }
}
