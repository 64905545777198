<div class="hol-box logbook-box">
  <div class="hol-box__header">
    <div class="header-title-container title-box-occ">
      <span><mat-icon>pin_invoke</mat-icon></span>
      <span class="word-break">{{ logbooksTitle }}</span
      >&nbsp;
      <small *ngIf="isReadOnly || localHourOnly" class="hol-box__subtitle">
        {{ moduleConfig.config.translateKey + '.COMMON.TIMES_LT' | translate }}</small
      >
      <small *ngIf="!isReadOnly && !localHourOnly" class="hol-box__subtitle">
        {{ moduleConfig.config.translateKey + '.COMMON.TIMES_UTC' | translate }}</small
      >
      <div class="fill-space"></div>
      <button
        (click)="openGroupModal()"
        *ngIf="
          (userCanEditCards || userCanEditCards === undefined) &&
          !isReadOnly &&
          !modeLinkIsActivate &&
          (!isItemsRequired || logBookItems.length)
        "
        aria-label="Add new group"
        class="md-icon-button md-raised hol-invert-no-border"
        mat-icon-button=""
        tabindex="-1"
      >
        <mat-icon>scatter_plot</mat-icon>
      </button>
      <button
        (click)="openAddModal($event)"
        *ngIf="(userCanEditCards || userCanEditCards === undefined) && !isReadOnly && !modeLinkIsActivate"
        aria-label="Add logbook"
        class="md-icon-button md-raised"
        mat-icon-button=""
      >
        <i class="fa fa-plus"></i>
      </button>
    </div>
    <div class="header-action-container">
      <mat-checkbox
        *ngIf="enableViewGroup"
        [(ngModel)]="isSetGroups"
        [disabled]="isReadOnly || modeLinkIsActivate"
        [labelPosition]="'after'"
        color="primary"
      >
        <span class="checkbox-label">{{ moduleConfig.config.translateKey + '.DASHBOARD.EVENTS.GROUP_CHECKBOX' | translate }}</span>
      </mat-checkbox>
    </div>
  </div>
  <div class="hol-box__content">
    <div class="occ-table">
      <div class="iscroll-wrapper" iscroll iscroll-indicator iscroll-instance="indicator">
        <div class="iscroll-scroller">
          <ul *ngIf="emptyLogBooksGroup.length" class="hol-list">
            <ng-container *ngFor="let emptyGroup of emptyLogBooksGroup; trackBy: trackByFn">
              <li *ngIf="isSetGroups && emptyGroup.objectId" [@EnterLeave]="'flyIn'">
                <app-ocl-container-group-item
                  [airportsCode]="airportsCode"
                  [decisionItems]="decisionItems"
                  [events]="events"
                  [group]="emptyGroup"
                  [groupsFromApi]="groupsFromApi"
                  [isReadOnly]="isReadOnly"
                  [isSummaryActivated]="isSummaryActivated"
                  [linkedIds]="linkedIds"
                  [logBookItems]="logBookItems"
                  [modeLinkIsActivate]="modeLinkIsActivate"
                  [type]="'LOGBOOK'"
                  [userCanEditCards]="userCanEditCards"
                  [utcDisplay]="utcDisplay"
                ></app-ocl-container-group-item>
              </li>
            </ng-container>
          </ul>
          <ul *ngIf="logBooksGrouped.length; else noResultLogBookOccTemplate" class="hol-list">
            <ng-container
              *ngIf="
                logBooksGrouped
                  | occSearchDashboard : searchCriteriasForPipe : context
                  | aclGroupFilter : RolesService.companiesRolesFilter as logBookItemsFilteredFromPipe
              "
            >
              <ng-container *ngFor="let logBookGroup of logBookItemsFilteredFromPipe; trackBy: trackByFn">
                <li
                  *ngIf="
                    logBookGroup.displayInGroupViewStatus === 'ALWAYS_VISIBLE' ||
                    (isSetGroups && logBookGroup.objectId) ||
                    (!isSetGroups && logBookGroup.displayInGroupViewStatus === 'NOT_VISIBLE_IN_GROUP_VIEW')
                  "
                  [@EnterLeave]="'flyIn'"
                >
                  <app-ocl-container-group-item
                    [airportsCode]="airportsCode"
                    [decisionItems]="decisionItems"
                    [events]="events"
                    [group]="logBookGroup"
                    [groupsFromApi]="groupsFromApi"
                    [isReadOnly]="isReadOnly"
                    [isSummaryActivated]="isSummaryActivated"
                    [linkedIds]="linkedIds"
                    [logBookItems]="logBookItems"
                    [modeLinkIsActivate]="modeLinkIsActivate"
                    [mostRecentLogBook]="logBookGroup.getMostRecentItem"
                    [type]="'LOGBOOK'"
                    [userCanEditCards]="userCanEditCards"
                    [utcDisplay]="utcDisplay"
                  ></app-ocl-container-group-item>
                </li>
              </ng-container>
              <div *ngIf="logBookItemsFilteredFromPipe.length === 0" class="no-result">
                <span>{{ moduleConfig.config.translateKey + '.DASHBOARD.LOGBOOK.NO_RESULT' | translate }}</span>
              </div>
            </ng-container>
          </ul>
          <ng-template #noResultLogBookOccTemplate>
            <ul class="hol-list">
              <div class="no-result">
                <span>{{ moduleConfig.config.translateKey + '.DASHBOARD.LOGBOOK.NO_RESULT' | translate }}</span>
              </div>
            </ul>
          </ng-template>
          <div *ngIf="showLoadMore" class="hol-centered-line">
            <button
              (click)="loadMore()"
              [disabled]="
                loading ||
                modeLinkIsActivate ||
                showOnlyIsPinned ||
                filterDataStartDate !== undefined ||
                logBookItems.length === logbookService.totalLogbooks
              "
              class="more-small"
              color="primary"
              mat-raised-button
            >
              <span *ngIf="!loading">{{ 'COMMON.LOAD_MORE' | translate }} (> {{ logbookService.dateTo | time : !isReadOnly }})</span>
              <span *ngIf="loading">{{ 'COMMON.LOADING' | translate }}</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
