import { EclCrisisERPType, EclCrisisType } from '../../models/ecl-crisis';
import { CrisisTypeService } from '../../../common/services/crisis/crisis-type.service';
import { RequestService } from '../../../common/services/request.service';
import { Injectable } from '@angular/core';
import { HolCrisisParameter } from '../../../common/models/hol-crisis-parameter';

@Injectable({
  providedIn: 'root',
})
export class EclCrisisTypeService extends CrisisTypeService<EclCrisisType> {
  protected readonly ParseCrisisTypeParameter = Parse.Object.extend('ECLCrisisTypeParameter');
  protected readonly ParseCrisisERPType = Parse.Object.extend('ECLCrisisERPType');

  constructor(requestService: RequestService) {
    super(requestService);
    this.ParseCrisisType = Parse.Object.extend('ECLCrisisType');
  }

  public async getCrisisParameters(crisisTypeId: string = null): Promise<Map<string, HolCrisisParameter[]>> {
    const paramQuery = new Parse.Query(this.ParseCrisisTypeParameter);
    if (crisisTypeId) {
      paramQuery.equalTo('crisisTypeId', crisisTypeId);
    }
    paramQuery.ascending('order');
    const parseResult = await this.requestService.performFindAllQuery(paramQuery);
    const list = parseResult ? parseResult.map(r => new HolCrisisParameter(r)) : [];
    const result = new Map<string, HolCrisisParameter[]>();
    list.forEach(param => {
      const listByCrisisType = result.get(param.crisisTypeId) || [];
      result.set(param.crisisTypeId, [param, ...listByCrisisType]);
    });
    return result;
  }

  protected newCrisisTypeObject(parser: Parse.Object): EclCrisisType {
    return parser ? new EclCrisisType(parser) : undefined;
  }

  protected async fetchCrisisParameters(crisisTypes: EclCrisisType[]) {
    const crisisTypeId = crisisTypes.length === 1 ? crisisTypes[0].crisisTypeId : null;
    const parameterMap = await this.getCrisisParameters(crisisTypeId);
    crisisTypes.forEach(crisisType => {
      crisisType.parameters = (parameterMap.get(crisisType.crisisTypeId) || []).sort((a, b) => a.order - b.order);
    });
  }

  public async getERPCrisisForCrisisType(crisisType: EclCrisisType): Promise<EclCrisisERPType[]> {
    try {
      const crisisERPTypeQuery = new Parse.Query(this.ParseCrisisERPType);
      crisisERPTypeQuery.containedIn('crisisTypeId', crisisType.erpAvailable);

      const response = await this.requestService.performFindAllQuery(crisisERPTypeQuery);
      return response.map(r => new EclCrisisERPType(r));
    } catch (error) {
      return [];
    }
    return [];
  }
}
