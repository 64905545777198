<div class="page-container">
  <!-- TODO move header when occ component created-->
  <app-header
    (closeExternalPanels)="closeExternalPanels()"
    [canAddBreakingNews]="!isReadOnly"
    [closeOtherPanels]="showManagersPanel"
    [context]="moduleConfig.config.moduleName"
  >
    <ng-container class="header-acl">
      <div *ngIf="!isPhone">
        <app-acl-filter module="OCC"></app-acl-filter>
      </div>
    </ng-container>
    <ng-container class="header-search">
      <form [formGroup]="form">
        <div class="hol-search-container sm">
          <input
            [(ngModel)]="searchText"
            formControlName="searchInput"
            maxlength="30"
            placeholder="{{ moduleConfig.config.translateKey + '.TIMELINE.FILTER' | translate }}"
            type="text"
          />
          <img alt="filter" class="icon" src="/assets/images/filter.svg" />
        </div>
      </form>
    </ng-container>

    <ng-container class="buttons-addon">
      <button (click)="toggleManagerPanel()" [class.header-button__deployed]="showManagersPanel" class="header-button" type="button">
        <span class="material-symbols-outlined">person</span>
      </button>
    </ng-container>
  </app-header>
  <app-hol-addons-panel [expanded]="showManagersPanel">
    <app-ocl-managers-box [isReadOnly]="isReadOnly"></app-ocl-managers-box>
  </app-hol-addons-panel>

  <div class="hol-row">
    <div class="col-xs-12">
      <div class="hol-box">
        <div class="hol-box__header--col">
          <!--          <div class="hol-row">-->
          <!--            &lt;!&ndash;TABS&ndash;&gt;-->
          <!--            <mat-button-toggle-group (change)="resetDates()" [(ngModel)]="tabIndex" class="tab-buttons">-->
          <!--              <mat-button-toggle [value]="0">-->
          <!--                {{ moduleConfig.config.translateKey + '.TIMELINE.HISTORY.TITLE' | translate }}-->
          <!--              </mat-button-toggle>-->
          <!--            </mat-button-toggle-group>-->
          <!--          </div>-->
          <div class="hol-row">
            <div class="">
              <button (click)="openSearchModal($event)" color="primary" mat-raised-button>
                {{ moduleConfig.config.translateKey + '.TIMELINE_SEARCH.CTA' | translate | uppercase }}
                <i class="icon fa fa-search"></i>
              </button>
            </div>
            <div class="hol-centered-line">
              <!--DATES-->
              <form [formGroup]="dateForm">
                <div class="dates">
                  <img src="/assets/images/calendar.svg" />
                  <span>{{ 'COMMON.FROM' | translate }}</span>
                  <mat-form-field class="no-label">
                    <input
                      [(ngModel)]="fromDate"
                      [matDatepicker]="pickerFrom"
                      [max]="toDate"
                      [min]="tabIndex === TAB_AGENDA ? now : toDate?.clone().subtract(1, 'month')"
                      formControlName="fromDateInput"
                      matDatepickerToggleIcon="home"
                      matInput
                      placeholder="{{ 'COMMON.OPTIONAL' | translate }}"
                    />
                    <mat-datepicker-toggle [for]="pickerFrom" matSuffix></mat-datepicker-toggle>
                    <mat-datepicker #pickerFrom></mat-datepicker>
                  </mat-form-field>
                  <span>{{ 'COMMON.TO' | translate }}</span>
                  <mat-form-field class="no-label">
                    <input
                      [(ngModel)]="toDate"
                      [matDatepicker]="pickerTo"
                      [max]="tabIndex === TAB_HISTORY ? now : fromDate?.clone().add(1, 'month')"
                      [min]="fromDate"
                      formControlName="toDateInput"
                      matInput
                      placeholder="{{ 'COMMON.OPTIONAL' | translate }}"
                    />
                    <mat-datepicker-toggle [for]="pickerTo" matSuffix></mat-datepicker-toggle>
                    <mat-datepicker #pickerTo></mat-datepicker>
                  </mat-form-field>
                </div>
              </form>
              <!--SEARCH-->
              <div class="search">
                <!--
                                  <form [formGroup]="form">
                                      <div class="hol-search-container sm">
                                          <input
                                                  [(ngModel)]="searchText"
                                                  formControlName="searchInput"
                                                  maxlength="30"
                                                  placeholder="{{ moduleConfig.config.translateKey + '.TIMELINE.FILTER' | translate }}"
                                                  type="text"
                                          />
                                          <img alt="filter" class="icon" src="/assets/images/filter.svg"/>
                                      </div>
                                  </form>
                                -->
              </div>
            </div>
          </div>
        </div>
        <div class="hol-box__content">
          <app-ocl-history
            *ngIf="tabIndex === 0"
            [fromDate]="fromDate"
            [isReadOnly]="isReadOnly"
            [isValidDateForm]="dateForm.valid"
            [searchText]="searchText"
            [toDate]="toDate"
          >
          </app-ocl-history>

          <!--<div *ngIf="tabIndex === 1" class="iscroll-wrapper" iscroll>
                      <div class="iscroll-scroller">
                        <app-goc-agenda [fromDate]="fromDate"
                                        [toDate]="toDate"
                                        [searchText]="searchText"
                                        [isValidDateForm]="dateForm.valid">
                        </app-goc-agenda>
                      </div>
                    </div>-->
        </div>
      </div>
    </div>
  </div>
</div>
