import { Component, inject, Inject, Input, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { BehaviorSubject, combineLatest, Subject, Subscription } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

import { MatDialog } from '@angular/material/dialog';

import { CommonStoreManager } from 'src/app/common/store/common.store-manager';
import { TranslateService } from '@ngx-translate/core';
import { FlyIn } from 'src/app/common/models/hol-animations';
import { OPSLayoutInfos } from 'src/app/common/store/common.model';
import { HolTag } from 'src/app/common/models/hol-tag';
import { TagChangeService } from 'src/app/common/services/tag-change.service';

import { ModuleConfigService } from '../../../common/services/module-config/module-config.service';
import { RolesService } from '../../../common/services/roles.service';
import { OclGroupModalComponent } from '../../modals/ocl-group-modal/ocl-group-modal.component';
import { OCL_LOGBOOK_MODAL_TOKEN } from '../../modals/ocl-logbook-modal/ocl-logbook-modal.token';
import { OclLogBookGroup } from '../../models/ocl-logbook-group.model';
import { OclLogbook } from '../../models/ocl-logbook.model';
import { OclFlightStatusService } from '../../services/ocl-flight-status-service/ocl-flight-status.service';
import { OclGroupService } from '../../services/ocl-group-service/ocl-group.service';
import { OclLogbookService } from '../../services/ocl-logbook-service/ocl-logbook.service';
import { OclOptionsService } from '../../services/ocl-options-service/ocl-options.service';
import { OclLogBooksStoreManager } from '../../store/logbooks/ocl-log-books-store-manager.service';
import { cloneDeep, orderBy } from 'lodash';
import { HolUTCDisplay, utcEval } from '../../../common/enums/hol-utc-display.enum';
import { HolListItemComponent } from '../../../common/components/hol-list-item/hol-list-item.component';
import { OclLogbookListItemComponent } from '../ocl-logbook-list-item/ocl-logbook-list-item.component';
import { EclLogbook } from '../../../ecl/models/ecl-logbook';
import { EclLogbookGroup } from '../../../ecl/models/ecl-logbook-group.model';
import { EclCrisis } from '../../../ecl/models/ecl-crisis';

@Component({
  selector: 'app-ocl-logbook-box',
  templateUrl: './ocl-logbook-box.component.html',
  styleUrls: ['./ocl-logbook-box.component.scss'],
  animations: [FlyIn],
})
export class OclLogbookBoxComponent implements OnInit, OnDestroy {
  @Input() isReadOnly: boolean;
  @Input() isSummaryActivated? = false;
  @Input() utcDisplay: HolUTCDisplay;
  @Input() public decisionItems;
  @Input() events;
  @Input() linkedIds;
  @Input() showOnlyIsPinned;
  @Input() airportsCode;
  @Input() isItemsRequired?: boolean = true;
  @Input() showLoadMore = true;
  @Input() userCanEditCards?: boolean;
  public logBooksGrouped: OclLogBookGroup[] = [];
  public emptyLogBooksGroup: OclLogBookGroup[] = [];
  public logBookItems: OclLogbook[] = [];
  public groupsFromApi: OclLogBookGroup[] = [];
  public modeLinkIsActivate = false;
  public loading = false;
  public searchCriteriasForPipe: string;
  public context = 'logbook';
  public logbooksTitle: string;
  public logBookItemsSub: Subscription;
  public logBookGroupsSub: Subscription;
  public RolesService = RolesService;
  public filterDataStartDate: Date = undefined;
  public logbooksSubscription: Subscription;
  public logbooks: OclLogbook[];
  private groupsAvailable: OclLogBookGroup[] = [];
  private currentTags: HolTag[];
  private ngUnsubscribe: Subject<void> = new Subject();
  private _logBookItems = new BehaviorSubject<OclLogbook[]>([]);
  private _logBookGroups = new BehaviorSubject<OclLogBookGroup[]>([]);
  private _modeLinkIsActivate = new BehaviorSubject<boolean>(false);
  private storeLayoutInfos: OPSLayoutInfos;

  protected readonly translate = inject(TranslateService);

  constructor(
    protected dialog: MatDialog,
    protected groupService: OclGroupService,
    protected flightsStatusService: OclFlightStatusService,
    protected optionsService: OclOptionsService,
    public logbookService: OclLogbookService,
    protected occLogBookStoreManager: OclLogBooksStoreManager,
    public moduleConfig: ModuleConfigService,
    @Inject(OCL_LOGBOOK_MODAL_TOKEN) protected logbookModal,
    private commonStoreManager: CommonStoreManager,
    protected tagChangeService: TagChangeService,
    private logbookStoreManager: OclLogBooksStoreManager,
  ) {
    this.logbooksTitle = this.optionsService.getLogbooksTitle()
      ? this.optionsService.getLogbooksTitle()
      : this.translate.instant(this.moduleConfig.config.translateKey + '.DASHBOARD.LOGBOOK.TITLE');
  }

  get modeLinkIsActivateInput() {
    return this._modeLinkIsActivate.getValue();
  }

  @Input()
  set modeLinkIsActivateInput(value) {
    this._modeLinkIsActivate.next(value);
  }

  get logBookItemsInput() {
    return this._logBookItems.getValue();
  }

  @Input()
  set logBookItemsInput(value) {
    this._logBookItems.next(value);
  }

  get logBookGroupsInput() {
    return this._logBookGroups.getValue();
  }

  @Input()
  set logBookGroupsInput(value) {
    this._logBookGroups.next(value);
  }

  get searchCriteriasdata() {
    return this._searchCriterias.getValue();
  }

  private _searchCriterias = new BehaviorSubject<string>('');

  @Input()
  set searchCriterias(value) {
    this._searchCriterias.next(value);
  }

  get enableViewGroup(): boolean {
    return (
      (!this.currentTags || this.currentTags.length === 0) &&
      this.searchCriteriasForPipe.length === 0 &&
      (this.groupsAvailable.length || this.emptyLogBooksGroup) &&
      !this.modeLinkIsActivate
    );
  }

  get isSetGroups(): boolean {
    return this.enableViewGroup ? this.storeLayoutInfos.groups.logbooksGroupEnabled : false;
  }

  set isSetGroups(value: boolean) {
    this.commonStoreManager.updateLayoutGroups({
      logbooksGroupEnabled: value,
    });
  }

  public get localHourOnly(): boolean {
    return this.utcDisplay === HolUTCDisplay.FORCE_LOCAL;
  }

  public get isUtc(): boolean {
    return utcEval(this.utcDisplay, this.isReadOnly);
  }

  ngOnInit(): void {
    combineLatest([this._logBookItems, this._logBookGroups])
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(([logBookItems, logBookGroups]) => {
        this.logBookItems = logBookItems ? cloneDeep(logBookItems) : [];

        (this.groupsFromApi = logBookGroups ? logBookGroups : []),
          (this.groupsAvailable =
            logBookGroups && logBookGroups.length
              ? this.groupService.generateGroupAvailable(cloneDeep(logBookGroups), this.logBookItems)
              : []);
        this.logBooksGrouped = this.generateLogBooksGrouped(this.groupsAvailable, this.logBookItems);
        this.emptyLogBooksGroup = logBookGroups.filter(o => !o.items.length);
      });

    this.tagChangeService.tagsChanged.pipe(takeUntil(this.ngUnsubscribe)).subscribe(tags => (this.currentTags = cloneDeep(tags)));

    this._modeLinkIsActivate.pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
      this.modeLinkIsActivate = cloneDeep(res);
    });

    this._searchCriterias.pipe(takeUntil(this.ngUnsubscribe)).subscribe(cristerias => {
      this.searchCriteriasForPipe = cloneDeep(cristerias);
    });

    this.commonStoreManager.filterDataStartDate.pipe(takeUntil(this.ngUnsubscribe)).subscribe(filterDataStartDate => {
      if (this.moduleConfig.config.canChooseDataStartDate) {
        this.filterDataStartDate = filterDataStartDate;
      }
    });

    this.commonStoreManager.layoutInfos
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(layoutInfosData => (this.storeLayoutInfos = layoutInfosData));
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public openAddModal(event): void {
    event.stopPropagation();
    const dialogRef = this.dialog.open(this.logbookModal, {
      data: {
        events: this.events,
        isUtc: utcEval(this.utcDisplay, this.isReadOnly),
        logbook: null,
        decisionItems: this.decisionItems,
        modeLinkIsActivate: this.modeLinkIsActivate,
        airportsCode: this.airportsCode,
        isSummaryActivated: this.isSummaryActivated,
      },
    });
    dialogRef.afterClosed().subscribe(logbook => {
      if (logbook) {
        this.flightsStatusService.setNeedsUpdate(true);
      }
    });
  }

  public openGroupModal(): void {
    const dialogRef = this.dialog.open(OclGroupModalComponent, {
      data: {
        decisionItems: null,
        events: null,
        logBookItems: this.logBookItems,
        isReadOnly: this.isReadOnly,
        group: null,
        groupsFromApi: this.groupsFromApi,
        type: 'LOGBOOK',
        isItemsRequired: this.isItemsRequired,
        isSummaryActivated: this.isSummaryActivated,
      },
    });
    dialogRef.afterClosed().subscribe(group => {
      if (group) {
        this.groupService.fetchNewDataLogBookGroup().then(value => {});
      }
    });
  }

  loadMore() {
    this.loading = true;
    this.logbookService.getAll(true, true, false, this.filterDataStartDate).then(newlogBook => {
      this.occLogBookStoreManager.addManyLogBook(newlogBook);
      this.loading = false;
    });
  }

  trackByFn(index, item) {
    return item.objectId || item.items[0].objectId;
  }

  public findAndOpenLoogbook(logbook: EclLogbook) {
    if (this.userCanEditCards || this.userCanEditCards === undefined) {
      const dialogRef = this.dialog.open(this.logbookModal, {
        data: {
          events: this.events,
          isUtc: this.isUtc,
          logbook: cloneDeep(logbook), //fixme ask Maxime, why cloneDeep ?
          decisionItems: this.decisionItems,
          isReadOnly: cloneDeep(logbook).fromGOC || cloneDeep(logbook).fromCREW ? true : this.isReadOnly,
          modeLinkIsActivate: this.modeLinkIsActivate,
          airportsCode: this.airportsCode,
          itemComponent: OclLogbookListItemComponent,
          isHistory: false,
          isSummaryActivated: this.isSummaryActivated,
        },
      });
      dialogRef.afterClosed().subscribe(logbook => {
        if (logbook) {
          this.flightsStatusService.setNeedsUpdate(true);
        }
      });
    }
  }

  public async findAndOpenLogbookGroup(logbookGroup: EclLogbookGroup, selectedCrisisId: string) {
    const allLogbooks = await this.logbookStoreManager.logbookList.pipe(take(1)).toPromise();
    const crisisLogbooks = allLogbooks.filter(l => (l as EclLogbook).crisis.objectId == selectedCrisisId);

    const dialogRef = this.dialog.open(OclGroupModalComponent, {
      data: {
        decisionItems: null,
        events: null,
        logBookItems: crisisLogbooks,
        globalInstructionItems: null,
        isReadOnly: this.isReadOnly,
        group: this.groupsAvailable.find(g => g.objectId === logbookGroup.objectId) || cloneDeep(logbookGroup),
        groupsFromApi: this.groupsFromApi,
        type: 'LOGBOOK',
        isItemsRequired: this.isItemsRequired,
        isSummaryActivated: this.isSummaryActivated,
      },
    });
    dialogRef.afterClosed().subscribe(group => {
      if (group) {
        this.groupService.fetchNewDataLogBookGroup().then(value => {});
      }
    });
  }

  private generateLogBooksGrouped(group, logbooks): OclLogBookGroup[] {
    return orderBy(
      this.groupService.generateGroupForDisplay(group, logbooks, 'LOGBOOK'),
      item => {
        if ((item as OclLogBookGroup).getMostRecentItem) {
          return (item as OclLogBookGroup).getMostRecentItem.createdAt;
        }
        return null;
      },
      'desc',
    );
  }
}
