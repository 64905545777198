import { Injectable } from '@angular/core';
import { RequestService } from '../request.service';
import { HolCrisis } from '../../models/hol-crisis';
import { BehaviorSubject } from 'rxjs';
import moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class CrisisService<T extends HolCrisis = HolCrisis> {
  public crisisInCreation = false;
  public crisisInPreparation = false;
  public sendNotification = true;
  public usersToNotifyByUser: string[] = null;
  protected ParseCrisis;

  constructor(protected requestService: RequestService) {}

  async getAll(): Promise<T[]> {
    try {
      const crisisQuery = new Parse.Query(this.ParseCrisis);
      crisisQuery.include('ACL');
      crisisQuery.include('crisisType');
      crisisQuery.includeAll();
      crisisQuery.include('createdBy');
      crisisQuery.descending('createdAt');
      const parseResult = await this.requestService.performFindAllQuery(crisisQuery);
      const result = parseResult ? parseResult.map(r => this.newCrisisObject(r)) : [];
      return await this.afterGet(result);
    } catch (e) {
      return [];
    }
  }

  async getById(crisisId: string): Promise<T> {
    try {
      const crisisQuery = new Parse.Query(this.ParseCrisis);
      crisisQuery.include('ACL');
      crisisQuery.includeAll();
      const parseResult = await this.requestService.performGetQuery(crisisQuery, crisisId);
      let crisis = parseResult ? this.newCrisisObject(parseResult) : undefined;
      [crisis] = crisis && (await this.afterGet([crisis]));
      return crisis;
    } catch (e) {
      return undefined;
    }
  }

  async get(): Promise<T> {
    const parseCrisis = await this.requestService.performCloudCode<Parse.Object>('getCrisis', null);
    let crisis = this.newCrisisObject(parseCrisis);
    [crisis] = crisis && (await this.afterGet([crisis]));
    return crisis;
  }

  create(crisis: T, sendNotification: boolean = true, usersToNotifyByUser: string[] = null): BehaviorSubject<string> {
    this.sendNotification = sendNotification;
    this.usersToNotifyByUser = usersToNotifyByUser;
    const createCrisisProgress = new BehaviorSubject<string>('');
    this.beforeCreate(crisis).subscribe(
      s => {
        createCrisisProgress.next(s);
      },
      e => {
        createCrisisProgress.error(e);
      },
      async () => {
        if (crisis.isInPreparation) {
          this.crisisInPreparation = true;
        } else {
          this.crisisInCreation = true;
        }
        createCrisisProgress.next('Create new crisis... </br>');
        const savedCrisis = await this.saveCrisis(crisis);
        return await this.afterCreate(crisis, savedCrisis, createCrisisProgress);
      },
    );
    return createCrisisProgress;
  }

  saveCrisis(crisis: Partial<T>): Promise<T> {
    const parseObject = new this.ParseCrisis({ id: crisis.objectId });
    parseObject.set('mainTitle', crisis.mainTitle);
    parseObject.set('subtitle', crisis.subTitle);
    parseObject.set('crisisTypeId', crisis.crisisTypeId);
    parseObject.set('isTraining', crisis.isTraining);
    parseObject.set('isInPreparation', crisis.isInPreparation);
    parseObject.set('inProgress', crisis.inProgress);
    parseObject.set('clock_departure', crisis.clockDeparture);
    parseObject.set('clock_arrival', crisis.clockArrival);
    parseObject.set('clock_onSite', crisis.clockOnSite);

    if (crisis.acl) {
      parseObject.setACL(crisis.acl);
    }
    if (!crisis.objectId) {
      parseObject.set('createdBy', Parse.User.current());
    }
    this.beforeSave(crisis, parseObject);
    return this.requestService.performSaveQuery(parseObject).then(parseData => {
      const bufferCrisis = this.newCrisisObject(parseData);
      return this.afterSave(crisis, bufferCrisis);
    });
  }

  closeCrisis(crisis: T): Promise<T> {
    const parseCrisis = new this.ParseCrisis({ id: crisis.objectId });
    parseCrisis.set('isInPreparation', false);
    parseCrisis.set('inProgress', false);
    this.beforeClose(crisis, parseCrisis);
    return this.requestService.performSaveQuery(parseCrisis).then(parseData => {
      const closedCrisis = this.newCrisisObject(parseData);

      return this.afterClose(crisis, closedCrisis);
    });
  }

  activateByCrisisDirector(crisis: T): Promise<T> {
    const parseCrisis = new this.ParseCrisis({ id: crisis.objectId });
    parseCrisis.set('isInPreparation', false);
    parseCrisis.set('inProgress', true);
    this.crisisInCreation = true;
    return this.requestService.performSaveQuery(parseCrisis).then(async parseData => {
      const activatedCrisis = this.newCrisisObject(parseData);
      return await this.afterActivate(crisis, activatedCrisis);
    });
  }

  protected beforeSave(inputCrisis: Partial<T>, parseObject: Parse.Object) {
    return;
  }

  protected afterSave(inputCrisis: Partial<T>, savedCrisis: T): T {
    return savedCrisis;
  }

  protected beforeClose(inputCrisis: Partial<T>, parseObject: Parse.Object) {
    parseObject.set('closedAt', moment.utc().toDate());
  }

  protected async afterClose(inputCrisis: T, savedCrisis: T): Promise<T> {
    return savedCrisis;
  }

  protected async afterActivate(inputCrisis: T, savedCrisis: T): Promise<T> {
    return savedCrisis;
  }

  protected beforeCreate(inputCrisis: T): BehaviorSubject<string> {
    return new BehaviorSubject<string>('');
  }

  protected newCrisisObject(parser: Parse.Object): T {
    return new HolCrisis(parser) as T;
  }

  protected async afterCreate(inputCrisis: T, savedCrisis: T, progress: BehaviorSubject<string>): Promise<T> {
    return savedCrisis;
  }

  protected async afterGet(list: T[]): Promise<T[]> {
    return list;
  }
}
