'use strict';

/**
 * @ngdoc service
 * @name gestiondecriseApp.CrewSituationHelpTextService
 * @description
 * # CrewSituationHelpTextService
 * Service in the gestiondecriseApp.
 */
angular.module('gestiondecriseApp').service('CrewSituationHelpTextService', function ($q, RequestService, ParseMapper) {
  const CrewSituationHelpText = Parse.Object.extend('CrewSituationHelpText');

  this.getAll = function () {
    const deferred = $q.defer();

    const query = new Parse.Query(CrewSituationHelpText);
    RequestService.performFindAllQuery(
      query,
      function (parseHelpText) {
        deferred.resolve(_.map(parseHelpText, ht => ParseMapper.crewSituationHelpTextToObject(ht)));
      },
      deferred.reject
    );

    return deferred.promise;
  };
});
