import { ModuleConfigService } from '../../../services/module-config/module-config.service';
import { Component, Input, OnInit } from '@angular/core';
import { nextInfoType } from 'src/app/common/components/hol-next-info/hol-next-info.component';
import { HolHistoryLog } from '../../../models/hol-history-log.model';
import { HolTask } from '../../../models/hol-task';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-task-history',
  templateUrl: './task-history.component.html',
  styleUrls: ['./task-history.component.scss'],
})
export class TaskHistoryComponent implements OnInit {
  @Input() taskHistoryLog: HolHistoryLog;
  tagsToChip = [];
  task: HolTask;
  nextInfo: nextInfoType;

  constructor(public moduleConfig: ModuleConfigService, protected translate: TranslateService) {}

  ngOnInit() {
    const defaultTags = this.taskHistoryLog.jsonObject.defaultTags ? this.taskHistoryLog.jsonObject.defaultTags : [];
    this.tagsToChip = [...this.taskHistoryLog.jsonObject.tags, ...defaultTags];
    this.task = this.taskHistoryLog.jsonObject;
    this.task.contentText = this.translate.instant(this.moduleConfig.config.translateKey + '.HISTORY.' + this.taskHistoryLog.subType);
    this.task.createdAt = this.taskHistoryLog.createdAt;
    this.task.createdBy = this.taskHistoryLog.user;
  }
}
