import { Action } from '@ngrx/store';
import { HolUser, HolUserWithCompanies } from 'src/app/common/models/hol-user.model';
import { EclFunction, EclUserFunction, EclUserFunctionRef } from '../../models/ecl-function';
import { EclCrisis } from '../../models/ecl-crisis';

export enum EclFunctionActionTypes {
  INIT_ECL_FUNCTION = '[ECL FUNCTIONS] Init functions',
  UPDATE_ECL_CURRENT_USER_FUNCTION = '[ECL FUNCTIONS] Update current user function',
  UPDATE_ECL_FILTER_FUNCTION = '[ECL FUNCTIONS] Update current function for item filtering',
  UPDATE_ECL_TEAM_FUNCTION = '[ECL TEAM] Update current function for teams page',
  UPDATE_ECL_TEAM_FUNCTION_REF = '[ECL TEAM REF] Update current function for teams page',
  UPDATE_ECL_TEAM_USER = '[ECL TEAM] Update current user for teams page',
  ADD_ECL_USER_FUNCTION = '[ECL TEAM] Add a new UserFunction',
  ADD_ECL_USER_FUNCTIONS = '[ECL TEAM] Add a group of new UserFunction',
  ADD_ECL_USER_FUNCTION_REF = '[ECL TEAM REF] Add a new UserFunctionRef',
  DELETE_ECL_USER_FUNCTION = '[ECL TEAM] Delete an existing UserFunction',
  DELETE_ECL_USER_FUNCTION_REF = '[ECL TEAM REF] Delete an existing UserFunctionRef',
  ADD_ECL_FUNCTION = '[ECL FUNCTIONS] Add a new function on ECL',
  UPDATE_ECL_FUNCTION = '[ECL FUNCTIONS] Update an existing function on ECL',
  ADD_ECL_USER = '[ECL TEAM] Add a new user on ECL',
  UPDATE_ECL_USER = '[ECL TEAM] Update an existing user on ECL',
  UPDATE_MANY_ECL_USER = '[ECL TEAM] Update several existing user on ECL',
  UPDATE_ECL_FUNCTION_REFERENTIAL_MODE = '[ECL TEAM REF] mode',
  CHANGE_CRISIS = '[ECL CRISIS] when we change selected crisis',
}

export class InitECLFunctions implements Action {
  readonly type = EclFunctionActionTypes.INIT_ECL_FUNCTION;

  constructor(
    public payload: {
      allFunctions: EclFunction[];
      allUserFunctions: EclUserFunction[];
      allUsers: HolUserWithCompanies[];
      currentUserId: string;
      allUserFunctionsRef: EclUserFunctionRef[];
      currentCrisis: EclCrisis;
    },
  ) {}
}

export class UpdateECLCurrentUserFunction implements Action {
  readonly type = EclFunctionActionTypes.UPDATE_ECL_CURRENT_USER_FUNCTION;

  constructor(public payload: EclFunction) {}
}

export class UpdateECLFilterFunction implements Action {
  readonly type = EclFunctionActionTypes.UPDATE_ECL_FILTER_FUNCTION;

  constructor(public payload?: EclFunction) {}
}

export class UpdateECLTeamFunction implements Action {
  readonly type = EclFunctionActionTypes.UPDATE_ECL_TEAM_FUNCTION;

  constructor(public payload?: EclFunction) {}
}

export class UpdateECLTeamUserRef implements Action {
  readonly type = EclFunctionActionTypes.UPDATE_ECL_TEAM_FUNCTION_REF;

  constructor(public payload?: EclFunction) {}
}

export class UpdateECLTeamUser implements Action {
  readonly type = EclFunctionActionTypes.UPDATE_ECL_TEAM_USER;

  constructor(public payload?: HolUserWithCompanies) {}
}

export class AddECLUserFunction implements Action {
  readonly type = EclFunctionActionTypes.ADD_ECL_USER_FUNCTION;

  constructor(public payload: { addUserFunction: EclUserFunction; addCurrentUserId: string }) {}
}

export class AddECLUserFunctions implements Action {
  readonly type = EclFunctionActionTypes.ADD_ECL_USER_FUNCTIONS;

  constructor(public payload: { addUserFunctions: EclUserFunction[]; addCurrentUserIds: string }) {}
}

export class AddECLUserFunctionRef implements Action {
  readonly type = EclFunctionActionTypes.ADD_ECL_USER_FUNCTION_REF;

  constructor(public payload: { userFunctionRef: EclUserFunctionRef; currentUserId: string }) {}
}

export class DeleteECLUserFunction implements Action {
  readonly type = EclFunctionActionTypes.DELETE_ECL_USER_FUNCTION;

  constructor(public payload: { userFunction: EclUserFunction; currentUserId: string }) {}
}

export class DeleteECLUserFunctionRef implements Action {
  readonly type = EclFunctionActionTypes.DELETE_ECL_USER_FUNCTION_REF;

  constructor(public payload: { delUserFunctionRef: EclUserFunctionRef; currentUserId: string }) {}
}

export class AddECLFunction implements Action {
  readonly type = EclFunctionActionTypes.ADD_ECL_FUNCTION;

  constructor(public payload: EclFunction) {}
}

export class UpdateECLFunction implements Action {
  readonly type = EclFunctionActionTypes.UPDATE_ECL_FUNCTION;

  constructor(public payload: EclFunction) {}
}

export class AddECLUser implements Action {
  readonly type = EclFunctionActionTypes.ADD_ECL_USER;

  constructor(public payload: HolUserWithCompanies) {}
}

export class UpdateECLUser implements Action {
  readonly type = EclFunctionActionTypes.UPDATE_ECL_USER;

  constructor(public payload: HolUserWithCompanies) {}
}

export class UpdateManyECLUser implements Action {
  readonly type = EclFunctionActionTypes.UPDATE_MANY_ECL_USER;

  constructor(public payload: HolUser[]) {}
}

export class UpdateReferential implements Action {
  readonly type = EclFunctionActionTypes.UPDATE_ECL_FUNCTION_REFERENTIAL_MODE;

  constructor(public payload: boolean) {}
}

export class ChangeSelectedCrisis implements Action {
  readonly type = EclFunctionActionTypes.CHANGE_CRISIS;

  constructor(public payload: { eclFunction: EclUserFunction[]; baseFs: EclFunction[] }) {}
}

export type EclFunctionActions =
  | InitECLFunctions
  | UpdateECLCurrentUserFunction
  | UpdateECLFilterFunction
  | UpdateECLTeamFunction
  | UpdateECLTeamUser
  | AddECLUserFunction
  | AddECLUserFunctions
  | DeleteECLUserFunction
  | DeleteECLUserFunctionRef
  | AddECLFunction
  | UpdateECLFunction
  | AddECLUser
  | UpdateECLUser
  | UpdateManyECLUser
  | UpdateReferential
  | UpdateECLTeamUserRef
  | AddECLUserFunctionRef
  | ChangeSelectedCrisis;
