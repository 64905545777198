angular.module('gestiondecriseApp').filter('time', function () {
  return function (date, utc) {
    if (date) {
      let momentDate = moment(date);
      let dateFormat = 'HH:mm';

      if (utc) {
        dateFormat += '[Z]';
        momentDate = momentDate.utc();
      }
      if (!momentDate.isSame(moment(), 'days')) {
        dateFormat = dateFormat + '[\r\n]ddd DD';
      }

      return momentDate.format(dateFormat);
    }
  };
});
