import { Directive, ElementRef, HostListener } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ConfirmationModalComponent } from '../modals/confirmation-modal/confirmation-modal.component';
import { AlertModalComponent } from '../modals/alert-modal/alert-modal.component';
import { TranslateService } from '@ngx-translate/core';

@Directive({
  selector: 'a[href]', // Cette directive s'applique à tout élément <a> avec un attribut href
})
export class AutoLinkDirective {
  constructor(private el: ElementRef, private readonly matDialog: MatDialog, protected translate: TranslateService) {}

  @HostListener('click', ['$event'])
  onClick(event: Event): void {
    const href = this.el.nativeElement.getAttribute('href');
    if (this.isExternalLink(href)) {
      event.preventDefault();
      const dialogRef: MatDialogRef<ConfirmationModalComponent> = this.matDialog.open(ConfirmationModalComponent, {
        data: {
          modalTitle: this.translate.instant('COMMON.MODALS.HYPERLINK.TITLE'),
          modalContent: this.translate.instant('COMMON.MODALS.HYPERLINK.CONTENT', { url: href }),
          modalQuestion: this.translate.instant('COMMON.MODALS.HYPERLINK.QUESTION'),
        },
      });
      dialogRef.afterClosed().subscribe({
        next: (confirmed: boolean) => {
          if (confirmed) {
            window.open(href, '_blank');
          }
        },
      });
    } else if (href === '') {
      event.preventDefault();

      this.matDialog.open(AlertModalComponent, {
        data: {
          modalTitle: this.translate.instant('COMMON.MODALS.CORRUPTED_FILE.TITLE'),
          modalContent: this.translate.instant('COMMON.MODALS.CORRUPTED_FILE.CONTENT'),
          modalType: 'error',
        },
      });
    }
  }

  private isExternalLink(url: string): boolean {
    const link = document.createElement('a');
    link.href = url;
    const regex = new RegExp('https://[a-zA-Z0-9]+.files-sashido.cloud/');
    const isOK = link.hostname !== window.location.hostname && !regex.test(link.href);
    return isOK;
  }
}
