import { MccHistoryService } from '../../mcc/services/mcc-history.service';
import { CommonStoreManager } from 'src/app/common/store/common.store-manager';
import { HolUser } from 'src/app/common/models/hol-user.model';
import { RolesService } from 'src/app/common/services/roles.service';
import { HolFunction } from '../models/hol-function';
import { OptionsService } from 'src/app/common/services/options.service';
import { Inject, Injectable } from '@angular/core';
import { HolVacationChecklistItemRef, HolVacationChecklistRef, HolVacationRef } from '../models/hol-vacations-ref.model';
import moment from 'moment';
import {
  HolVacation,
  HolVacationChecklist,
  HolVacationChecklistItem,
  VACATION_CHECKLIST_ITEM_STATUS,
  VACATION_LOG_STATUS,
} from '../models/hol-vacation.model';
import { HolAttachments } from '../models/hol-attachments.model';
import { RequestService } from './request.service';
import { OclHistoryService } from 'src/app/ocl/services/ocl-history-service/ocl-history.service';
import { OclHistoryLog } from '../../ocl/models/ocl-history-log.model';
import { HolUserFunction } from '../models/hol-user-function';
import { ModuleConfigService } from './module-config/module-config.service';
import { ArchiveService } from './archive.service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root',
})
export class VacationService {
  // tslint:disable-next-line:variable-name
  public parseHolVacations_REF = Parse.Object.extend('HOLVacations_REF');
  // tslint:disable-next-line:variable-name
  public parseHolVacationChecklists_REF = Parse.Object.extend('HOLVacationChecklists_REF');
  // tslint:disable-next-line:variable-name
  public parseHolVacationChecklistItems_REF = Parse.Object.extend('HOLVacationChecklistItems_REF');
  public parseHolVacations = Parse.Object.extend('HOLVacations');
  public parseHolVacationChecklists = Parse.Object.extend('HOLVacationChecklists');
  public parseHolVacationChecklistItems = Parse.Object.extend('HOLVacationChecklistItems');
  public parseUser = Parse.Object.extend('User');
  public parseHolFunction = Parse.Object.extend('HOLFunction');

  constructor(
    protected requestService: RequestService,
    protected optionsService: OptionsService,
    protected historyService: OclHistoryService<OclHistoryLog>,
    @Inject('CrewLogsService') protected crewLogsService,
    protected userService: UserService,
    protected rolesService: RolesService,
    protected commonStoreManager: CommonStoreManager,
    protected mccHistoryService: MccHistoryService,
    protected archiveService: ArchiveService,
    protected moduleConfig: ModuleConfigService,
  ) {}

  public getVacationREFByFunction(functionId): Promise<HolVacationRef> {
    let holVacationRef: HolVacationRef;
    return new Promise((resolve, reject) => {
      const query = new Parse.Query(this.parseHolVacations_REF);
      query.equalTo('functionId', functionId);
      this.requestService.performFirstQuery(
        query,
        result => {
          if (!result) {
            resolve(undefined);
          } else {
            holVacationRef = new HolVacationRef(result);
            const queryChecklists = new Parse.Query(this.parseHolVacationChecklists_REF);
            queryChecklists.equalTo('vacationId', holVacationRef.vacationId);
            this.requestService.performFindQuery(
              queryChecklists,
              resultChecklists => {
                if (!resultChecklists) {
                  resolve(undefined);
                } else {
                  holVacationRef.checklistsRef = resultChecklists.map(checklist => new HolVacationChecklistRef(checklist));
                  const queryChecklistItems = new Parse.Query(this.parseHolVacationChecklistItems_REF);
                  queryChecklistItems.containedIn(
                    'vacationChecklistId',
                    holVacationRef.checklistsRef.map(el => el.vacationChecklistId),
                  );
                  this.requestService.performFindAllQuery(
                    queryChecklistItems,
                    resultChecklistItems => {
                      if (!resultChecklistItems) {
                        resolve(undefined);
                      } else {
                        holVacationRef.checklistItemsRef = resultChecklistItems.map(item => new HolVacationChecklistItemRef(item));
                        resolve(holVacationRef);
                      }
                    },
                    reject,
                  );
                }
              },
              reject,
            );
          }
        },
        reject,
      );
    });
  }

  public initVacationsByModule(module: string): Promise<HolVacation[]> {
    // 1 on récupère toutes les vacations non complétées
    return this.getVacationsByModule(module).then(results => {
      const vacations: HolVacation[] = results;
      this.commonStoreManager.initHolVacations(vacations);
      return vacations;
    });
  }

  public getVacationsByModule(mod: string): Promise<HolVacation[]> {
    let vacations: HolVacation[] = [];
    const query = new Parse.Query(this.parseHolVacations);
    query.include('user');
    query.include('holFunction');
    query.exists('holFunction');
    query.doesNotExist('closeReason');
    query.descending('createdAt');
    query.limit(1000);

    return this.requestService.performFindQuery(query).then(resultsVacations => {
      vacations = resultsVacations
        .map(vacation => {
          return new HolVacation(vacation);
        })
        .filter(t => t.aclModules.includes(mod.toUpperCase()));
      const queryChecklists = new Parse.Query(this.parseHolVacationChecklists);
      queryChecklists.containedIn('vacation', resultsVacations);
      queryChecklists.descending('checklistIdRef');
      queryChecklists.include('HolVacations');
      queryChecklists.limit(1000);

      return this.requestService.performFindAllQuery(queryChecklists).then(resultsChecklists => {
        vacations.forEach(
          vacation =>
            (vacation.checklists = resultsChecklists
              .filter(resultsChecklist => vacation.objectId === resultsChecklist.get('vacation').id)
              .map(el => new HolVacationChecklist(el))),
        );
        const queryChecklistItems = new Parse.Query(this.parseHolVacationChecklistItems);
        queryChecklistItems.addAscending('order');
        queryChecklistItems.containedIn('vacationChecklist', resultsChecklists);
        queryChecklistItems.include('updatedBy');
        queryChecklistItems.limit(5000);

        return this.requestService.performFindAllQuery(queryChecklistItems).then(resultsChecklistItems => {
          vacations.forEach(vacation => {
            vacation.checklists.forEach(
              checklist =>
                (checklist.checklistItems = resultsChecklistItems
                  .filter(resultsChecklistItem => checklist.objectId === resultsChecklistItem.get('vacationChecklist').id)
                  .map(el => new HolVacationChecklistItem(el))),
            );
          });
          return vacations;
        });
      });
    });
  }

  public async createVacation(
    vacationRef: HolVacationRef,
    holUserFunction: HolUserFunction,
    user: HolUser,
    holFunction: HolFunction,
    mod: string,
  ): Promise<HolVacation> {
    let newVacation: HolVacation;
    const parseVacation = new this.parseHolVacations();
    parseVacation.set('holFunction', new this.parseHolFunction({ id: holFunction.objectId }));
    parseVacation.set('user', new this.parseUser({ id: user.objectId }));
    parseVacation.set('startDate', moment().utc().toDate());
    // ACL from vacationRef module + holUserFunction companies
    let acl = new Parse.ACL();
    if (vacationRef.modules.length) {
      acl = await this.rolesService.getACLForCompanies(vacationRef.modules, holUserFunction.companies);
    } else {
      // ACL from holUserFunction
      acl = holUserFunction.acl;
    }
    parseVacation.setACL(acl);
    return this.requestService.performSaveQuery(parseVacation).then(resultsVacation => {
      newVacation = new HolVacation(resultsVacation);
      const parseChecklistsVacation: any[] = vacationRef.checklistsRef.map(checklist => {
        const parseBufferChecklist = new this.parseHolVacationChecklists();
        parseBufferChecklist.set('vacation', resultsVacation);
        parseBufferChecklist.set('title', checklist.title);
        parseBufferChecklist.set('dueDate', checklist.dueDate);
        parseBufferChecklist.set('checklistIdRef', checklist.vacationChecklistId);
        parseBufferChecklist.setACL(acl);
        return parseBufferChecklist;
      });
      return this.requestService.performSaveAllQuery(parseChecklistsVacation).then(resultsChecklists => {
        const parseChecklistItemsVacation = vacationRef.checklistItemsRef.map(item => {
          const parseBufferChecklistItem = new this.parseHolVacationChecklistItems();
          parseBufferChecklistItem.set(
            'vacationChecklist',
            resultsChecklists.find(el => el.get('checklistIdRef') === item.vacationChecklistId),
          );
          parseBufferChecklistItem.set('label', item.label);
          parseBufferChecklistItem.set('order', item.order);
          parseBufferChecklistItem.setACL(acl);
          return parseBufferChecklistItem;
        });
        return this.requestService.performSaveAllQuery(parseChecklistItemsVacation).then(() => {
          this.initVacationsByModule(mod);
          const vacationTitle = holFunction.title + ' ' + newVacation.user.firstName + ' ' + newVacation.user.lastName;
          this.logChecklist(
            mod,
            VACATION_LOG_STATUS.START_VACATION_CHECKLIST,
            newVacation,
            null,
            null,
            vacationTitle,
            null,
            newVacation.objectId,
          );
          return newVacation;
        });
      });
    });
  }

  public enabledOrDisabledChecklistUpdate(checklist: HolVacationChecklist, state: boolean): Promise<HolVacationChecklist> {
    const parseVacationChecklist = new this.parseHolVacationChecklists({ id: checklist.objectId });
    parseVacationChecklist.set('disabled', state);
    return new Promise((resolve, reject) => {
      this.requestService.performSaveQuery(
        parseVacationChecklist,
        null,
        result => {
          resolve(new HolVacationChecklist(result));
        },
        reject,
      );
    });
  }

  public completedChecklistUpdate(checklist: HolVacationChecklist, state: boolean): Promise<HolVacationChecklist> {
    const parseVacationChecklist = new this.parseHolVacationChecklists({ id: checklist.objectId });
    parseVacationChecklist.set('completedAt', state ? moment().utc().toDate() : null);
    return new Promise((resolve, reject) =>
      this.requestService.performSaveQuery(parseVacationChecklist, null, result => resolve(new HolVacationChecklist(result)), reject),
    );
  }

  public completedVacationUpdate(vacation: HolVacation, state: boolean): Promise<HolVacation> {
    const parseVacation = new this.parseHolVacations({ id: vacation.objectId });
    parseVacation.set('completedAt', state ? moment().utc().toDate() : null);
    return new Promise((resolve, reject) =>
      this.requestService.performSaveQuery(parseVacation, null, result => resolve(new HolVacation(result)), reject),
    );
  }

  public statusItemUpdate(item: HolVacationChecklistItem, state: VACATION_CHECKLIST_ITEM_STATUS): Promise<HolVacationChecklistItem> {
    const parseItem = new this.parseHolVacationChecklistItems({ id: item.objectId });
    parseItem.set('status', state);
    parseItem.set('updatedBy', new this.parseUser({ id: this.userService.getCurrentUserObject().objectId }));
    parseItem.set('statusDate', state ? moment().utc().toDate() : null);
    return new Promise((resolve, reject) =>
      this.requestService.performSaveQuery(parseItem, null, result => resolve(new HolVacationChecklistItem(result)), reject),
    );
  }

  public attachmentsItemUpdate(item: HolVacationChecklistItem, attachments: HolAttachments): Promise<HolVacationChecklistItem> {
    const parseItem = new this.parseHolVacationChecklistItems({ id: item.objectId });
    parseItem.set('attachments', attachments);
    return new Promise((resolve, reject) =>
      this.requestService.performSaveQuery(
        parseItem,
        null,
        result => {
          resolve(new HolVacationChecklistItem(result));
        },
        reject,
      ),
    );
  }

  public logChecklist(
    mod: string,
    status: VACATION_LOG_STATUS,
    vacation: HolVacation = null,
    vacationChecklist: HolVacationChecklist = null,
    vacationChecklistItem: HolVacationChecklistItem = null,
    comment: string = null,
    attachments: HolAttachments = {},
    vacationObjectId: string,
  ): void {
    const commentForOccGoc = 'Vacation ' + comment;
    let subTypeForOccGoc: string;
    const jsonObject: any = vacation || vacationChecklist || vacationChecklistItem;
    switch (status) {
      case VACATION_LOG_STATUS.START_VACATION_CHECKLIST:
        subTypeForOccGoc = 'create';
        break;
      case VACATION_LOG_STATUS.END_VACATION_CHECKLIST:
      case VACATION_LOG_STATUS.COMPLETED_CHECKLIST:
        subTypeForOccGoc = 'completed';
        break;
      case VACATION_LOG_STATUS.DEACTIVATE_CHECKLIST:
      case VACATION_LOG_STATUS.DEACTIVATE_ITEM_CHECKLIST:
        subTypeForOccGoc = 'deactivate';
        break;
      case VACATION_LOG_STATUS.ARCHIVE_VACATION_CHECKLIST:
        subTypeForOccGoc = 'archive';
        break;
      case VACATION_LOG_STATUS.UNCOMPLETED_CHECKLIST:
        subTypeForOccGoc = 'uncompleted';
        break;
      case VACATION_LOG_STATUS.REACTIVATE_CHECKLIST:
        subTypeForOccGoc = 'reactivate';
        break;
      case VACATION_LOG_STATUS.CHECKED_ITEM_CHECKLIST:
        subTypeForOccGoc = 'checked';
        break;
      case VACATION_LOG_STATUS.UNCHECKED_ITEM_CHECKLIST:
        subTypeForOccGoc = 'unchecked';
        break;
      case VACATION_LOG_STATUS.UPDATE_ATTACHEMENT_ITEM_CHECKLIST:
        subTypeForOccGoc = 'update';
        break;
      default:
        subTypeForOccGoc = '';
    }
    if (mod !== 'erp' && mod !== 'crew' && mod !== 'mcc') {
      let acl: Parse.ACL;
      const object: Parse.Object = new this.parseHolFunction({ id: vacationObjectId });
      if (object.getACL()) {
        acl = object.getACL();
      }
      this.historyService.postLog(
        OclHistoryLog.create(commentForOccGoc, 'holVacation', subTypeForOccGoc, attachments, jsonObject, acl, object),
      );
    }

    if (mod === 'crew') {
      this.crewLogsService.postLog(status, null, null, jsonObject);
    }

    if (mod === 'mcc') {
      let acl: Parse.ACL;
      const object: Parse.Object = new this.parseHolFunction({ id: vacationObjectId });
      if (object.getACL()) {
        acl = object.getACL();
      }
      this.mccHistoryService.postMccLog(
        commentForOccGoc,
        'holVacation',
        subTypeForOccGoc,
        acl,
        null,
        null,
        null,
        null,
        attachments,
        jsonObject,
      );
    }
  }

  closePreviousVacations(holUserFunction: HolUserFunction, func: HolFunction): Promise<HolVacation[]> {
    const prevVacQuery = new Parse.Query(this.parseHolVacations);
    prevVacQuery.equalTo('holFunction', new this.parseHolFunction({ id: func.objectId }));
    prevVacQuery.lessThan('createdAt', new Date());
    prevVacQuery.doesNotExist('closeReason');
    prevVacQuery.descending('createdAt');
    return this.requestService.performFindQuery(prevVacQuery).then(parseVacs => {
      const vacs = parseVacs.slice(1).map(v => new HolVacation(v));
      return Promise.all(
        vacs.map(vac => {
          return this.archiveService.close(vac, 'HOLVacations', '2', 'Automatic close').then(item => {
            this.commonStoreManager.removeHolVacations(item.objectId);
            this.logChecklist(
              this.moduleConfig.config.moduleName,
              VACATION_LOG_STATUS.ARCHIVE_VACATION_CHECKLIST,
              item,
              null,
              null,
              vac.holFunction.title,
              null,
              vac.objectId,
            );
            return vac;
          });
        }),
      );
    });
  }
}
