// From https://github.com/shivarajnaidu/ng-marquee

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

enum MarqueeDirection {
  left = 'left',
  right = 'right',
  alternate = 'alternate',
}

enum MarqueeSpeed {
  drowsy = 'drowsy',
  slow = 'slow',
  normal = 'normal',
  fast = 'fast',
  swift = 'swift',
  hyper = 'hyper',
}

@Component({
  selector: 'app-ng-marquee',
  templateUrl: './ng-marquee.component.html',
  styleUrls: ['./ng-marquee.component.scss'],
})
export class NgMarqueeComponent {
  @Input() speed: string;
  @Input() duration: string;
  @Input() direction: MarqueeDirection;
  @Input() stopOnHover = false;

  marqueeDirection = MarqueeDirection;
  marqueeSpeed = MarqueeSpeed;
}
