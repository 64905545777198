import { Inject, Injectable } from '@angular/core';
import { OccOptionsService } from '../occ-options-service/occ-options.service';
import { RequestService } from '../../../common/services/request.service';
import { OccHistoryService } from '../occ-history-service/occ-history.service';
import { OclFlightStatusService } from '../../../ocl/services/ocl-flight-status-service/ocl-flight-status.service';

@Injectable({
  providedIn: 'root',
})
export class OccFlightStatusService extends OclFlightStatusService {
  // tslint:disable:variable-name
  protected FlightsStatus = Parse.Object.extend('OCCFlightsStatus');
  // tslint:enabled

  public constructor(
    protected optionsService: OccOptionsService,
    protected requestService: RequestService,
    @Inject('$rootScope') protected $rootScope,
    protected historyService: OccHistoryService,
  ) {
    super(optionsService, requestService, $rootScope, historyService);
  }
}
