import { Component, Inject, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

import { MatDialog } from '@angular/material/dialog';

import moment from 'moment';
import { cloneDeep, extend } from 'lodash';

import { LocalStorageService } from 'src/app/common/services/local-storage.service';

import { ModuleConfigService } from '../../../common/services/module-config/module-config.service';
import { OCL_LOGBOOK_MODAL_TOKEN } from '../../modals/ocl-logbook-modal/ocl-logbook-modal.token';
import { OclLogbook } from '../../models/ocl-logbook.model';
import { OclEventService } from '../../services/ocl-event-service/ocl-event.service';
import { OclFlightStatusService } from '../../services/ocl-flight-status-service/ocl-flight-status.service';
import { OclLinksHelperService } from '../../services/ocl-links-helper-service/ocl-links-helper.service';
import { OclLogbookService } from '../../services/ocl-logbook-service/ocl-logbook.service';
import { OclNotificationsService } from '../../services/ocl-notifications-service/ocl-notifications.service';
import { OclOptionsService } from '../../services/ocl-options-service/ocl-options.service';
import { OclDecisionsStoreManager } from '../../store/decisions/ocl-decisions.store-manager';
import { OclEventsStoreManager } from '../../store/events/ocl-events-store-manager.service';
import { OclLinkedDisplayedItemsStoreManager } from '../../store/linked-display-items/ocl-linked-displayed-items-store-manager.service';
import { HolUTCDisplay, utcEval } from '../../../common/enums/hol-utc-display.enum';
import { EclSummary } from '../../../ecl/models/ecl-summary';
import { OclGroup } from '../../models/ocl-group.model';

@Component({
  selector: 'app-ocl-logbook-list-item',
  templateUrl: './ocl-logbook-list-item.component.html',
  styleUrls: ['./ocl-logbook-list-item.component.scss'],
})
export class OclLogbookListItemComponent implements OnInit, OnChanges {
  @Input() userCanEditCards?: boolean;
  @Input()
  public item: OclLogbook = null;
  @Input()
  public isReadOnly = false;
  @Input() isSummaryActivated? = false;
  @Input()
  public events = [];
  @Input()
  public utcDisplay: HolUTCDisplay;
  @Input()
  public isHistory = false;

  @Input()
  public summaryGroup?: EclSummary;

  @Input() public group?: OclGroup<any>;

  @Input() linkedIds;
  @Input() modeLinkIsActivate;
  @Input() decisionItems;
  @Input() airportsCode;

  public canCheckLogbook = false;

  public linkedUid: string;
  public randomColor: string;

  public fromModule = null;

  constructor(
    protected readonly localStorage: LocalStorageService,
    protected flightsStatusService: OclFlightStatusService,
    protected eventService: OclEventService,
    protected optionsService: OclOptionsService,
    @Inject('CONSTANTS') protected CONSTANTS,
    protected notificationsService: OclNotificationsService,
    protected logbookService: OclLogbookService,
    public moduleConfig: ModuleConfigService,
    protected linksHelperService: OclLinksHelperService,
    protected occLinkedDisplayedItemsStoreManager: OclLinkedDisplayedItemsStoreManager,
    protected occDecisionsStoreManager: OclDecisionsStoreManager,
    protected occEventsStoreManager: OclEventsStoreManager,
    protected dialog: MatDialog,
    @Inject(OCL_LOGBOOK_MODAL_TOKEN) protected logbookModal,
  ) {}

  public get isUtc(): boolean {
    return utcEval(this.utcDisplay, this.isReadOnly);
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (this.item.linkedData && !this.item.linkedData.linkedUid && this.item.linkedData.linkedUids.length === 0) {
      delete this.item.linkedData;
    }
    if (changes.item && changes.item.currentValue && changes.item.currentValue.linkedData) {
      this.linkedUid = changes.item.currentValue.linkedData.linkedUid;
      this.localStorage.setBufferLinkedUid(this.item.linkedData.objectId);
    } else if (
      changes.item &&
      changes.item.previousValue &&
      changes.item.previousValue.linkedData &&
      changes.item.currentValue &&
      !changes.item.currentValue.linkedData
    ) {
      this.linkedUid = '';
      this.localStorage.removeBufferLinkedUid();
    }
  }

  /*public loadStorage() {
    const prevUrl = this.localStorage.getSessionPrevUrlLocation().split('.', 2)[1];
    const actualUrl = this.localStorage.getSessionUrlLocation().split('.', 2)[1];
    const linkedUid = this.localStorage.getBufferLinkedUid();
    console.log('linkedUid ocl-logbook-- ', linkedUid);
    if (linkedUid && prevUrl && prevUrl !== actualUrl) {
      this.hideLinkedItems(linkedUid);
    }
  }*/

  public ngOnInit() {
    this.canCheckLogbook = this.optionsService.getCanCheckLogbook();
    this.fromModule = this.item.fromGOC ? 'GOC' : this.item.fromCREW ? 'CREW' : this.item.fromERP ? 'ERP' : null;
    // this.loadStorage();
  }

  public openDetailsModal(event, item): void {
    event.stopPropagation();
    this.openDetailModalWithoutEvent(item);
  }

  // This function seems to be never used...
  // public openEventModal($event, event): void {
  //   $event.stopPropagation();
  //   if (event && event.objectId) {
  //     const fullEvent = this.events.find(e => (e ? e.objectId === event.objectId : false));
  //     if (fullEvent) {
  //       this.$mdDialog
  //         .show({
  //           controller: 'EventsEditModalCtrl',
  //           templateUrl: 'views/occ/dashboard/events-edit.modal.html',
  //           clickOutsideToClose: true,
  //           targetEvent: $event,
  //           locals: {
  //             event: fullEvent,
  //             isReadOnly: this.isReadOnly,
  //             notifications: this.notificationsService.getAll(),
  //             isUtc: this.isUtc,
  //           },
  //         })
  //         .then(() => {
  //           this.eventService.fetchNewData();
  //           this.logbookService.fetchNewData();
  //         }, this.eventService.fetchNewData);
  //     }
  //   }
  // }

  /*public hideLinkedItems(linkedUid: string) {
    console.log('hideLinkedItems logbook');
    const bufferLinkedUid = 'LinkFrom_' + linkedUid;
    let randColor;
    this.linksHelperService.getLinkFromLogBook(linkedUid).then(data => {
      randColor = data[0];
    });
    // this.localStorage.removeBufferLinkedUid();
    this.linkedUid = '';
    this.occLinkedDisplayedItemsStoreManager.removeLinkedDiplayedItems(bufferLinkedUid, randColor);
    this.occEventsStoreManager.removeEventsFromLinkedValue(bufferLinkedUid);
    this.occDecisionsStoreManager.removeDecisionsFromLinkedValue(bufferLinkedUid);
    this.moduleConfig.unsetLoadingItemsLinked();
  }*/

  public openDetailModalWithoutEvent(item) {
    if (this.userCanEditCards || this.userCanEditCards === undefined) {
      const dialogRef = this.dialog.open(this.logbookModal, {
        data: {
          events: this.events,
          isUtc: this.isUtc,
          logbook: cloneDeep(item),
          decisionItems: this.decisionItems,
          isReadOnly: cloneDeep(item).fromGOC || cloneDeep(item).fromCREW || this.isHistory ? true : this.isReadOnly,
          modeLinkIsActivate: this.modeLinkIsActivate,
          airportsCode: this.airportsCode,
          itemComponent: OclLogbookListItemComponent,
          isHistory: this.isHistory,
          isSummaryActivated: this.isSummaryActivated,
        },
      });
      dialogRef.afterClosed().subscribe(logbook => {
        if (logbook) {
          this.flightsStatusService.setNeedsUpdate(true);
        }
      });
    }
  }

  public showLinkedItems($event): void {
    $event.stopPropagation();
    this.moduleConfig.setLoadingItemsLinked();
    const bufferLinkedUid = 'LinkFrom_' + this.item.objectId;

    if (!this.item.linkedData || !this.item.linkedData.linkedUid) {
      this.localStorage.setBufferLinkedUid(this.item.objectId);
      this.linksHelperService.getLinkFromLogBook(this.item.objectId).then(data => {
        this.randomColor = data[0];
        const linkedItemsToDisplay = data[1];
        const decisionsToUpdate = data[2];
        const eventsToUpdate = data[3];
        this.occLinkedDisplayedItemsStoreManager.addOrUpdateLinkedDiplayedItems(bufferLinkedUid, this.randomColor, linkedItemsToDisplay);
        if (eventsToUpdate.length) {
          this.occEventsStoreManager.updateEventsFromLinkedValue(eventsToUpdate, bufferLinkedUid);
        }
        if (decisionsToUpdate.length) {
          this.occDecisionsStoreManager.updateDecisionsFromLinkedValue(decisionsToUpdate, bufferLinkedUid);
        }
        this.moduleConfig.unsetLoadingItemsLinked();
      });
    } else {
      this.linkedUid = '';
      this.localStorage.removeBufferLinkedUid();
      this.occLinkedDisplayedItemsStoreManager.removeLinkedDiplayedItems(bufferLinkedUid, this.randomColor);
      this.occEventsStoreManager.removeEventsFromLinkedValue(bufferLinkedUid);
      this.occDecisionsStoreManager.removeDecisionsFromLinkedValue(bufferLinkedUid);
      this.moduleConfig.unsetLoadingItemsLinked();
    }
  }

  public toggleDone(item: OclLogbook, $event): void {
    $event.stopPropagation();
    // canCheckLogbook is global for each user
    if (this.canCheckLogbook && !this.isReadOnly) {
      this.logbookService.toggleDone(item).then(newLogBook => extend(item, newLogBook));
    }
  }

  public isElapsed(date): boolean {
    return moment(date).isBefore(moment());
  }

  public isAlmostElapsed(date): boolean {
    return moment(date).diff(moment(), 'minutes') < this.CONSTANTS.TIME_BEFORE_WARNING;
  }
}
