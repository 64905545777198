import { Injectable } from '@angular/core';
import { FltFlightLogbookService } from '../../../flt/services/flt-flight-logbook.service';

@Injectable({
  providedIn: 'root',
})
export class OpsFlightLogbookService extends FltFlightLogbookService {
  // tslint:disable:variable-name
  protected ParseFlightLogbook = Parse.Object.extend('OPSFlightLogbook');
  protected ParseFlightLogbookTag = Parse.Object.extend('OPSFlightLogbookTag');
  protected ParseTag = Parse.Object.extend('OPSTag');
  protected ParseDecisionTag = Parse.Object.extend('OPSDecisionTag');
  protected ParseLogbookTag = Parse.Object.extend('OPSLogBookTag');
  protected ParseDecision = Parse.Object.extend('OPSDecisions');
  protected ParseLogbook = Parse.Object.extend('OPSLogBook');
  // tslint:enable
}
