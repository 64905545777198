import { EclCrisis } from './ecl-crisis';
import { OclLogBookGroup } from '../../ocl/models/ocl-logbook-group.model';
import { EclLogbook } from './ecl-logbook';

export class EclLogbookGroup extends OclLogBookGroup<EclLogbook> {
  crisis: EclCrisis;
  //public summary: EclSummary;

  constructor(parseObject?: Parse.Object) {
    super(parseObject);
    if (!parseObject) {
      return;
    }
    this.crisis = new EclCrisis(parseObject.get('crisis'));
  }

  protected newItem(parseObject?: Parse.Object): EclLogbook {
    return new EclLogbook(parseObject);
  }
}
