import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';

import { CommonStoreManager } from 'src/app/common/store/common.store-manager';
import { FilesService } from 'src/app/common/services/files.service';

import { MarkdownService } from '../../../common/components/markdown-editor/markdown.service';
import { NotificationsService } from '../../../common/services/notifications/notifications.service';
import { ParseMapperService } from '../../../common/services/parse-mapper.service';
import { RequestService } from '../../../common/services/request.service';
import { OccDecisionTagService } from '../occ-decision-tag-service/occ-decision-tag.service';
import { OccHistoryService } from '../occ-history-service/occ-history.service';
import { OccOptionsService } from '../occ-options-service/occ-options.service';
import { OclDecisionService } from '../../../ocl/services/ocl-decision-service/ocl-decision.service';
import { OclDecisionsStoreManager } from '../../../ocl/store/decisions/ocl-decisions.store-manager';
import { OccMailService } from '../occ-mail-service/occ-mail.service';
import { OccSmsService } from '../occ-sms-service/occ-sms.service';
import { OccModuleConfigService } from '../occ-module-config/occ-module-config.service';
import { OclDecision } from '../../../ocl/models/ocl-decision.model';
import { HolContext } from '../../../common/models/hol-context.model';
import { HolNotification } from '../../../common/models/hol-notification.model';
import { GocDecision } from '../../../goc/models/goc-decision.model';
import { RolesService } from '../../../common/services/roles.service';
import { HelperService } from '../../../common/services/helper.service';

@Injectable({
  providedIn: 'root',
})
export class OccDecisionService extends OclDecisionService {
  // tslint:disable:variable-name
  protected ParseDecision = Parse.Object.extend('OCCDecisions');
  protected ParseEvent = Parse.Object.extend('OCCEvents');
  protected ParseDecisionTag = Parse.Object.extend('OCCDecisionTag');
  protected ParseTag = Parse.Object.extend('OCCTag');
  // tslint:enabled
  protected ParseGocFlightLogBook;

  public constructor(
    protected readonly datePipe: DatePipe,
    protected readonly filesService: FilesService,
    protected decisionsStoreManager: OclDecisionsStoreManager,
    protected parseMapper: ParseMapperService,
    protected requestService: RequestService,
    protected optionsService: OccOptionsService,
    protected notificationsService: NotificationsService,
    protected smsService: OccSmsService,
    protected mailService: OccMailService,
    protected historyService: OccHistoryService,
    protected decisionTagService: OccDecisionTagService,
    protected moduleConfig: OccModuleConfigService,
    protected commonStoreManager: CommonStoreManager,
    protected markdownService: MarkdownService,
    private rolesService: RolesService,
    private helperService: HelperService,
  ) {
    super(
      datePipe,
      filesService,
      decisionsStoreManager,
      parseMapper,
      requestService,
      optionsService,
      notificationsService,
      smsService,
      mailService,
      historyService,
      decisionTagService,
      moduleConfig,
      markdownService,
      commonStoreManager,
    );
  }

  public async create(
    decision: OclDecision,
    notifications: HolNotification[],
    context: HolContext,
    duplicateToOtherModule?: boolean,
  ): Promise<OclDecision> {
    const savedDecision = await super.create(decision, notifications, context);
    if (duplicateToOtherModule) {
      await this.duplicateDecisionToOtherModule(savedDecision);
    }
    return savedDecision;
  }

  public async update(
    decision: OclDecision,
    notifications: HolNotification[],
    context: HolContext,
    newDuplicateToOtherModuleValue?: boolean,
    oldDuplicateToOtherModuleValue?: boolean,
    updateDuplicateToOtherModuleFlightValue?: boolean,
  ): Promise<OclDecision> {
    return super.update(decision, notifications, context, newDuplicateToOtherModuleValue, oldDuplicateToOtherModuleValue).then(res => {
      if (updateDuplicateToOtherModuleFlightValue) {
        // Update flight
        if (decision.flight) {
          this.updateDuplicateDecisionToModule(res);
        } else {
          // Supprimer
          this.deleteDuplicateDecisionFromModule(res);
        }
      }
      return res;
    });
  }

  protected async duplicateDecisionToOtherModule(logbook: OclDecision) {
    const parseGocDecision = new this.ParseGocFlightLogBook();
    parseGocDecision.set('occDecision', new this.ParseDecision({ id: logbook.objectId }));
    parseGocDecision.set('flight', new this.ParseFlight({ id: logbook.flight.objectId }));
    const companies = this.helperService.parseACL(logbook.acl);
    const acl: Parse.ACL = await this.rolesService.getACLForCompanies(['GOC'], companies);
    parseGocDecision.setACL(acl);
    this.requestService.performSaveAllQuery(parseGocDecision).then();
  }

  private updateDuplicateDecisionToModule(logbook: OclDecision) {
    const parseOccDecision = new this.ParseDecision({ id: logbook.objectId });
    const query = new Parse.Query(this.ParseGocFlightLogBook);
    query.equalTo('occDecision', parseOccDecision);
    this.requestService.performFirstQuery(query).then(result => {
      if (result) {
        const gocDecision = new GocDecision(result);
        const parseGocDecision = new this.ParseGocFlightLogBook({ id: gocDecision.objectId });
        parseGocDecision.set('flight', new this.ParseFlight({ id: logbook.flight.objectId }));
        this.requestService.performSaveQuery(parseGocDecision).then();
      }
    });
  }
}
