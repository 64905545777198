import { Directive, ElementRef, Inject, Input, OnChanges, OnInit, Renderer2, SimpleChanges } from '@angular/core';
import moment from 'moment';

@Directive({
  selector: '[appFilterColor]',
})
export class FilterColorDirective implements OnChanges, OnInit {
  @Input()
  public date: Date;
  @Input()
  public isComplete: boolean;

  constructor(private elem: ElementRef, private renderer: Renderer2, @Inject('CrewOptionsService') private CrewOptionsService) {}

  public ngOnInit(): void {
    this.setColors();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    // tslint:disable-next-line:no-string-literal
    if (changes['isComplete']) {
      this.setColors();
    }
  }

  public setColors(): void {
    const filters = this.CrewOptionsService.getFilters();
    for (let i = 0; i < filters.length; i++) {
      this.renderer.removeClass(this.elem.nativeElement, 'filter-color-' + (i + 1));
    }
    if (this.isComplete) {
      this.renderer.addClass(this.elem.nativeElement, 'filter-color-0');
    } else {
      for (let i = 0; i < filters.length - 1; i++) {
        // passed dates
        if (moment(this.date).isBefore(moment())) {
          this.renderer.addClass(this.elem.nativeElement, 'filter-color-1');
          break;
        }
        if (
          moment(this.date).isSameOrAfter(
            moment()
              .startOf('day')
              .add(filters[i].threshold - 1, 'day'),
          ) &&
          moment(this.date).isBefore(
            moment()
              .startOf('day')
              .add(filters[i + 1].threshold - 1, 'day'),
          )
        ) {
          this.renderer.addClass(this.elem.nativeElement, 'filter-color-' + (i + 1));
          break;
        }
      }
    }
  }
}
