'use strict';

/**
 * @ngdoc service
 * @name gestiondecriseApp.CrewSituationWaitingHelpTextService
 * @description
 * # CrewSituationWaitingHelpTextService
 * Service in the gestiondecriseApp.
 */
angular.module('gestiondecriseApp').service('CrewSituationWaitingHelpTextService', function ($q, RequestService, ParseMapper) {
  const CrewSituationWaitingHelpText = Parse.Object.extend('CrewSituationWaitingHelpText');

  this.getAll = function () {
    const deferred = $q.defer();

    const query = new Parse.Query(CrewSituationWaitingHelpText);
    RequestService.performFindAllQuery(
      query,
      function (parseHelpText) {
        deferred.resolve(_.map(parseHelpText, ht => ParseMapper.crewSituationWaitingHelpTextToObject(ht)));
      },
      deferred.reject
    );

    return deferred.promise;
  };
});
