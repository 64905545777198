import { OclDecisionGroup } from '../../ocl/models/ocl-decision-group.model';
import { EclDecision } from './ecl-decision';
import { EclCrisis } from './ecl-crisis';

export class EclDecisionGroup extends OclDecisionGroup<EclDecision> {
  crisis: EclCrisis;

  // public summary: EclSummary;

  constructor(parseObject?: Parse.Object) {
    super(parseObject);
    if (!parseObject) {
      return;
    }
    this.crisis = new EclCrisis(parseObject.get('crisis'));
  }

  protected newItem(parseObject?: Parse.Object): EclDecision {
    return new EclDecision(parseObject);
  }
}
