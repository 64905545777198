import { ErpFunctionCategory } from '../models/erp-functionCategory';
import { Injectable } from '@angular/core';
import { ErpFunctionUser } from '../models/erp-functionUser';
import { ErpFunctionCrisis } from '../models/erp-functionCrisis';
import { RequestService } from '../../common/services/request.service';
import { FunctionsStoreManager } from '../store/functions/functions.store-manager';
import { HolUser } from '../../common/models/hol-user.model';
import { RolesService } from '../../common/services/roles.service';
import { filter, map, take } from 'rxjs/operators';
import { CrisisStoreManager } from '../store/crisis/crisis.store-manager';
import { ErpCrisisTask } from '../models/erp-crisisTask';

@Injectable({
  providedIn: 'root',
})
export class ErpFunctionsService {
  // tslint:disable:variable-name
  ParseFunctionUser = Parse.Object.extend('GDCUserFunction');
  ParseFunction = Parse.Object.extend('GDCFunction');
  ParseFunctionCategory = Parse.Object.extend('GDCTags');
  ParseTask = Parse.Object.extend('GDCTask');
  public updatingUserFunctionsIds: string[] = [];

  constructor(
    private requestService: RequestService,
    private functionsStoreManager: FunctionsStoreManager,
    private crisisStoreManager: CrisisStoreManager,
    private rolesService: RolesService,
  ) {}

  async getUserFunctionsCrisis(): Promise<ErpFunctionCrisis[]> {
    const functionQuery = new Parse.Query(this.ParseFunction);
    functionQuery.exists('tagId');
    functionQuery.include('ACL');
    functionQuery.ascending('createdAt');
    const functionsCrisisFromApi = await this.requestService.performFindAllQuery(functionQuery);
    if (functionsCrisisFromApi) {
      return functionsCrisisFromApi.map(functionCrisisFromApi => new ErpFunctionCrisis(functionCrisisFromApi));
    } else {
      return [];
    }
  }

  async getAllUserFunctions(): Promise<ErpFunctionUser[]> {
    const functionQuery = new Parse.Query(this.ParseFunction);
    functionQuery.exists('tagId');
    const functionUserQuery = new Parse.Query(this.ParseFunctionUser);
    functionUserQuery.include('ACL');
    functionUserQuery.descending('createdAt');
    functionUserQuery.include('createdBy');
    functionUserQuery.matchesKeyInQuery('functionId', 'functionId', functionQuery);
    const functionsUserFromApi = await this.requestService.performFindAllQuery(functionUserQuery);
    if (functionsUserFromApi) {
      return functionsUserFromApi.map(functionUserFromApi => new ErpFunctionUser(functionUserFromApi));
    } else {
      return [];
    }
  }

  getAllFunctionsCategories(): Promise<ErpFunctionCategory[]> {
    let functionCategories: ErpFunctionCategory[];
    return new Promise((resolve, reject) => {
      const functionCategoryQuery = new Parse.Query(this.ParseFunctionCategory);
      functionCategoryQuery.include('ACL');
      functionCategoryQuery.ascending('tagId');
      return this.requestService.performFindAllQuery(
        functionCategoryQuery,
        functionCategoryFromApi => {
          if (functionCategoryFromApi) {
            functionCategories = functionCategoryFromApi.map(functionCategorieFromApi => new ErpFunctionCategory(functionCategorieFromApi));
            resolve(functionCategories);
          } else {
            resolve([]);
          }
        },
        reject,
      );
    });
  }

  async removeUserFromFunction(userId: string, functionId: string): Promise<Parse.Object> {
    const query = new Parse.Query(this.ParseFunctionUser);
    query.equalTo('userId', userId);
    query.equalTo('functionId', functionId);
    const res = await this.requestService.performFirstQuery(query);
    const removed = await this.requestService.performDestroyQuery(res);
    this.functionsStoreManager.removeOneFunctionAllUser(new ErpFunctionUser(removed));
    return removed;
  }

  async addUserToFunction(userId: string, functionId: string): Promise<Parse.Object> {
    const userFunction = new this.ParseFunctionUser();
    userFunction.set('userId', userId);
    userFunction.set('functionId', functionId);
    userFunction.set('isHolder', false);
    userFunction.setACL(await this.rolesService.getACLFromCompaniesRolesFilter('ERP'));
    return this.requestService.performSaveQuery(userFunction).then(res => {
      this.functionsStoreManager.addOneFunctionAllUser(new ErpFunctionUser(res));
      return res;
    });
  }

  public async setHolder(_function: ErpFunctionCrisis, user: HolUser): Promise<ErpFunctionUser[]> {
    const userFunctions = await this.functionsStoreManager.functionsAllUserErpState
      .pipe(filter(t => t && !!t.length))
      .pipe(map(this.rolesService.filterFromCompanyRoles))
      .pipe(map(ufs => ufs.filter(uf => uf.functionId === _function.functionId)))
      .pipe(take(1))
      .toPromise();
    const currentHolders = userFunctions.filter(uf_1 => uf_1.isHolder);
    const userFunction = userFunctions.filter(uf_2 => uf_2.userId === user.userId);
    this.updatingUserFunctionsIds.push(...[...currentHolders, ...userFunction].map(uf_3 => uf_3.objectId));
    const parseUserFunctions = await this.requestService.performSaveAllQuery([
      ...currentHolders.map(ch => new this.ParseFunctionUser({ id: ch.objectId, isHolder: false })),
      ...userFunction.map(uf_4 => new this.ParseFunctionUser({ id: uf_4.objectId, isHolder: true })),
    ]);
    const updatedUserFunctions = parseUserFunctions.map(puf => new ErpFunctionUser(puf));
    updatedUserFunctions.forEach(uuf => {
      this.functionsStoreManager.updateOneFunctionAllUser(uuf);
    });
    return updatedUserFunctions;
  }

  /**
   * Reset all holder flag for a given user ignoring ACL
   * @param user The user for which the holder flags has to be reset
   */
  public async resetHolder(user: HolUser): Promise<ErpFunctionUser[]> {
    const userFunctions = await this.functionsStoreManager.functionsAllUserErpState
      .pipe(filter(t => t && !!t.length))
      .pipe(map(ufs => ufs.filter(uf => uf.userId === user.userId && uf.isHolder)))
      .pipe(take(1))
      .toPromise();
    const parseUserFunctions = await this.requestService.performSaveAllQuery(
      userFunctions.map(
        uf_1 =>
          new this.ParseFunctionUser({
            id: uf_1.objectId,
            isHolder: true,
          }),
      ),
    );
    const updatedUserFunctions = parseUserFunctions.map(puf => new ErpFunctionUser(puf));
    updatedUserFunctions.forEach(uuf => {
      this.functionsStoreManager.updateOneFunctionAllUser(uuf);
    });
    return updatedUserFunctions;
  }

  async duplicate(originalFunction: ErpFunctionCrisis, shortTitle: string, title: string) {
    // Duplicate function object
    const newFunction = new this.ParseFunction();
    const newFunctionId = Math.round(Math.random() * 1000000) + '';
    newFunction.set('title', title);
    newFunction.set('shortTitle', shortTitle + '*');
    newFunction.set('functionId', newFunctionId);
    newFunction.set('isTemporary', true);
    newFunction.set('tagId', originalFunction.tagId);
    newFunction.set('tasksSummary', originalFunction.tasksSummary);
    newFunction.set('phone', originalFunction.phone);
    newFunction.set('email', originalFunction.email);
    if (originalFunction.acl) {
      newFunction.setACL(originalFunction.acl);
    }

    try {
      await this.requestService.performSaveQuery(newFunction);
      this.functionsStoreManager.addOneFunctionCrisis(new ErpFunctionCrisis(newFunction));
      const crisis = await this.crisisStoreManager.crisisErpState.pipe(take(1)).toPromise();
      const query = new Parse.Query(this.ParseTask);
      query.ascending('order');
      query.equalTo('functionId', originalFunction.functionId);
      query.equalTo('crisisTypeId', crisis.crisisTypeId);
      query.notEqualTo('isDocumentOnly', true);
      query.limit(1000);
      const parseTasks = await this.requestService.performFindQuery(query);
      // Tasks
      const tasks = parseTasks.map(task => {
        const newTask = new this.ParseTask();
        newTask.set('crisisTypeId', task.get('crisisTypeId'));
        newTask.set('code', shortTitle + '*');
        newTask.set('functionId', newFunctionId);
        newTask.set('order', task.get('order'));
        newTask.set('taskTitle', task.get('taskTitle'));
        newTask.set('taskDescription', task.get('taskDescription'));
        newTask.set('outputTitle', task.get('outputTitle'));
        newTask.set('outputDataLabel', task.get('outputDataLabel'));
        newTask.set('isOnDashboard', task.get('isOnDashboard'));
        newTask.set('keywords', task.get('keywords'));
        newTask.set('outputDataHint', task.get('outputDataHint'));
        newTask.set('formIoFormRef', task.get('formIoFormRef'));
        newTask.set('defaultTags', task.get('defaultTags'));
        newTask.set('status', 'TODO');
        newTask.set('isDocumentOnly', task.get('isDocumentOnly'));
        newTask.set('isConditionalTask', task.get('isConditionalTask'));
        newTask.set('isTemporary', true);
        newTask.set('customVisibleBy', task.get('customVisibleBy'));
        newTask.set('visibleBy', task.get('visibleBy'));
        newTask.setACL(task.getACL());
        return newTask;
      });
      const tasks_1 = await this.requestService.performSaveAllQuery(tasks);
      this.crisisStoreManager.createManyCrisisTasks(tasks_1.map(t => new ErpCrisisTask(t)));
      console.log('✓ All tasks (' + tasks_1.length + ') duplicated');
    } catch (error) {
      throw new Error('❌ Could not duplicate tasks ' + JSON.stringify(error));
    }
  }

  async markAsNotified(functionIds: string[]): Promise<any> {
    const query = new Parse.Query(this.ParseFunction);
    if (functionIds) {
      query.containedIn('functionId', functionIds);
    }
    query.exists('tagId');
    query.notEqualTo('tagId', '');
    const fns = await this.requestService.performFindQuery(query);
    fns.forEach(fn => {
      fn.set('notified', true);
    });
    const savedFunctions = await this.requestService.performSaveAllQuery(fns);
    savedFunctions.forEach(fn_1 => {
      this.functionsStoreManager.updateOneFunctionCrisis(new ErpFunctionCrisis(fn_1));
    });
  }
}
