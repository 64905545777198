import { Action } from '@ngrx/store';
import { EclAnnouncement } from '../../models/ecl-annoucement';
import { EclCrisis, EclCrisisType } from '../../models/ecl-crisis';
import { EclSummary } from '../../models/ecl-summary';
import { EclUserFunction } from '../../models/ecl-function';

export enum EclCrisisActionTypes {
  INIT_ECL_CRISIS = '[ECL CRISIS] Init crisis',
  INIT_ECL_CRISIS_LIST_FROM_POLLING = '[ECL CRISIS] Init crisis list from Polling',
  SELECT_ECL_CURRENT_CRISIS = '[ECL CRISIS] Select current crisis',
  CREATE_ECL_CRISIS = '[ECL CRISIS] Create crisis',
  CLOSE_ECL_CRISIS = '[ECL CRISIS] Close crisis',
  UPDATE_ONE_ECL_CRISIS = '[ECL CRISIS] update crisis',
  UPDATE_MANY_ECL_CRISIS = '[ECL CRISIS] update last change crisis',
  UPDATE_ONE_ECL_CRISIS_ANNOUNCEMENT = '[ECL CRISIS] Update One ECL Announcement',
  CREATE_ONE_ECL_CRISIS_ANNOUNCEMENT = '[ECL CRISIS] Create One ECL Announcement',
  INIT_ECL_CRISIS_ANNOUNCEMENT_LIST = '[ECL CRISIS] INIT ECL Announcement List',
  INIT_ECL_CRISIS_ANNOUNCEMENT_LIST_FROM_POLLING = '[ECL CRISIS] INIT ECL Announcement List from Polling',
  UPDATE_ONE_ECL_CRISIS_SUMMARY = '[ECL CRISIS] Update One ECL Summary',
  UPDATE_MANY_ECL_CRISIS_SUMMARY = '[ECL CRISIS] Update many ECL Summary',
  DELETE_ONE_ECL_CRISIS_SUMMARY = '[ECL CRISIS] Delete One ECL Summary',
  CREATE_ONE_ECL_CRISIS_SUMMARY = '[ECL CRISIS] Create One ECL Summary',
  INIT_ECL_CRISIS_USERFUNCTION_LIST = '[ECL CRISIS] INIT ECL UserFunctionFor Current User List',
  INIT_ECL_CRISIS_SUMMARY_LIST = '[ECL CRISIS] INIT ECL Summary List',
  INIT_ECL_CRISIS_SUMMARY_LIST_FROM_POLLING = '[ECL CRISIS] INIT ECL Summary List from Polling',
  //UPDATE_ECL_CRISIS = '[ECL CRISIS] Update crisis',
}

export class InitEclCrisis implements Action {
  readonly type = EclCrisisActionTypes.INIT_ECL_CRISIS;

  constructor(
    public payload: {
      crisisList: EclCrisis[];
      crisisTypeList: EclCrisisType[];
      allCurrentUserFunction: EclUserFunction[];
      crisisIdSaved: string;
    },
  ) {}
}

export class InitEclCrisisListFromPolling implements Action {
  readonly type = EclCrisisActionTypes.INIT_ECL_CRISIS_LIST_FROM_POLLING;

  constructor(public payload: EclCrisis[]) {}
}

export class SelectEclCurrentCrisis implements Action {
  readonly type = EclCrisisActionTypes.SELECT_ECL_CURRENT_CRISIS;

  constructor(public payload: EclCrisis) {}
}

export class CreateEclCrisis implements Action {
  readonly type = EclCrisisActionTypes.CREATE_ECL_CRISIS;

  constructor(public payload: EclCrisis) {}
}

export class CloseEclCrisis implements Action {
  readonly type = EclCrisisActionTypes.CLOSE_ECL_CRISIS;

  constructor(public payload: EclCrisis) {}
}

export class UpdateOneEclAnnouncement implements Action {
  readonly type = EclCrisisActionTypes.UPDATE_ONE_ECL_CRISIS_ANNOUNCEMENT;

  constructor(public payload: EclAnnouncement) {}
}

export class CreateOneEclAnnouncement implements Action {
  readonly type = EclCrisisActionTypes.CREATE_ONE_ECL_CRISIS_ANNOUNCEMENT;

  constructor(public payload: EclAnnouncement) {}
}

export class InitEclAnnouncementList implements Action {
  readonly type = EclCrisisActionTypes.INIT_ECL_CRISIS_ANNOUNCEMENT_LIST;

  constructor(public payload: EclAnnouncement[]) {}
}

export class InitEclUserFunctionList implements Action {
  readonly type = EclCrisisActionTypes.INIT_ECL_CRISIS_USERFUNCTION_LIST;

  constructor(public payload: EclUserFunction[]) {}
}

export class InitEclAnnouncementListFromPolling implements Action {
  readonly type = EclCrisisActionTypes.INIT_ECL_CRISIS_ANNOUNCEMENT_LIST_FROM_POLLING;

  constructor(public payload: EclAnnouncement[]) {}
}

export class UpdateOneEclSummary implements Action {
  readonly type = EclCrisisActionTypes.UPDATE_ONE_ECL_CRISIS_SUMMARY;

  constructor(public payload: EclSummary) {}
}

export class UpdateManyEclSummary implements Action {
  readonly type = EclCrisisActionTypes.UPDATE_MANY_ECL_CRISIS_SUMMARY;

  constructor(public payload: EclSummary[]) {}
}

export class CreateOneEclSummary implements Action {
  readonly type = EclCrisisActionTypes.CREATE_ONE_ECL_CRISIS_SUMMARY;

  constructor(public payload: EclSummary) {}
}

export class DeleteOneEclSummary implements Action {
  readonly type = EclCrisisActionTypes.DELETE_ONE_ECL_CRISIS_SUMMARY;

  constructor(public payload: EclSummary) {}
}

export class InitEclSummaryList implements Action {
  readonly type = EclCrisisActionTypes.INIT_ECL_CRISIS_SUMMARY_LIST;

  constructor(public payload: EclSummary[]) {}
}

export class InitEclSummaryListFromPolling implements Action {
  readonly type = EclCrisisActionTypes.INIT_ECL_CRISIS_SUMMARY_LIST_FROM_POLLING;

  constructor(public payload: EclSummary[]) {}
}

export class UpdateOneEclCrisis implements Action {
  readonly type = EclCrisisActionTypes.UPDATE_ONE_ECL_CRISIS;

  constructor(public payload: EclCrisis) {}
}

export class UpdateManyEclCrisis implements Action {
  readonly type = EclCrisisActionTypes.UPDATE_MANY_ECL_CRISIS;

  constructor(public payload: EclCrisis[]) {}
}

export type EclCrisisActions =
  | InitEclCrisis
  | InitEclCrisisListFromPolling
  | SelectEclCurrentCrisis
  | CreateEclCrisis
  | CloseEclCrisis
  | UpdateOneEclAnnouncement
  | CreateOneEclAnnouncement
  | InitEclAnnouncementList
  | InitEclAnnouncementListFromPolling
  | UpdateOneEclSummary
  | DeleteOneEclSummary
  | CreateOneEclSummary
  | InitEclSummaryList
  | InitEclSummaryListFromPolling
  | InitEclUserFunctionList
  | UpdateOneEclCrisis
  | UpdateManyEclSummary
  | UpdateManyEclCrisis;
