import { Inject, Injectable } from '@angular/core';

import { MailSenderService } from 'src/app/common/services/mail/mail-sender.service';
import { MailTemplateService } from 'src/app/common/services/mail/mail-template.service';

import { OclMailService } from '../../../ocl/services/ocl-mail-service/ocl-mail.service';
import { GocModuleConfigService } from '../goc-module-config/goc-module-config.service';

@Injectable({
  providedIn: 'root',
})
export class GocMailService extends OclMailService {
  protected constructor(
    protected readonly mailSenderService: MailSenderService,
    @Inject('CONSTANTS') protected CONSTANTS,
    protected mailTemplateService: MailTemplateService,
    protected moduleConfig: GocModuleConfigService,
  ) {
    super(mailSenderService, CONSTANTS, mailTemplateService, moduleConfig);

    this.translate.onLangChange.subscribe(() => {
      this.SENDER_SUFFIX = this.translate.instant('MAIL.GOC.SENDER_SUFFIX') || 'GOC';
    });
  }
}
