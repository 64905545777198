import { inject, Inject, Injectable } from '@angular/core';
import { map, uniq } from 'lodash';
import moment from 'moment';
import { HolNextInfo } from 'src/app/common/models/hol-next-info.model';
import { TranslateService } from '@ngx-translate/core';
import { MccEvent } from '../../../mcc/models/mcc-event.model';
import { MccMel } from '../../../mcc/models/mcc-mel.model';
import { ISmsMessage, SmsSenderService } from './sms-sender.service';

@Injectable({
  providedIn: 'root',
})
export class SmsService {
  private readonly translate = inject(TranslateService);

  constructor(@Inject('CONSTANTS') private CONSTANTS, private readonly smsSenderService: SmsSenderService) {}

  public sendNewMELInfoSMS(mel: MccMel, melInfo: HolNextInfo, phoneNumbers: string[]) {
    const messages: ISmsMessage[] = [];
    map(uniq(phoneNumbers), phoneNumber => {
      messages.push({
        to: phoneNumber,
        text: this._getNewMELInfoMessage(mel, melInfo),
      });
    });
    this.smsSenderService.sendMultipleSms(messages, true, this.CONSTANTS.COMPANY_NAME + this.CONSTANTS.MCC_SUFFIX);
  }

  public sendNewSituationSms(situation: any) {
    if (!situation.user || !situation.user.phone) {
      console.warn('SmsService::sendNewSituationSms: no user phone');
      return;
    }
    this.smsSenderService.sendSms(
      situation.user.phone,
      this._getSituationMessage(situation),
      true,
      this.CONSTANTS.COMPANY_NAME + this.CONSTANTS.CREW_SUFFIX,
    );
  }

  public sendNewMCCEventSMS(event: MccEvent, phoneNumbers: string[], eventInfo?: HolNextInfo) {
    const messages: ISmsMessage[] = [];
    map(uniq(phoneNumbers), phoneNumber => {
      messages.push({
        to: phoneNumber,
        text: this._getNewMCCEventMessage(event, eventInfo),
      });
    });
    this.smsSenderService.sendMultipleSms(messages, true, this.CONSTANTS.COMPANY_NAME + this.CONSTANTS.MCC_SUFFIX);
  }

  private _getNewMELInfoMessage(mel: MccMel, melInfo: HolNextInfo): string {
    return this.translate.instant('SMS.MCC.NEW_MEL_INFO', {
      dueDate: moment(mel.dueDate).utc().format('ddd DD/MM'),
      foundDate: moment(mel.foundDate).utc().format('ddd DD/MM'),
      reference: mel.reference,
      faultType: mel.faultType,
      faultName: mel.faultName,
      nextInfoTime: moment(melInfo.nextInfoTime).utc().format('ddd DD [at] HH:mm [UTC]'),
      appUrl: location.origin,
    });
  }

  private _getSituationMessage(situation: any): string {
    const translateKey = 'SMS.CREW.NEW_SITUATION_' + situation.direction;

    return this.translate.instant(translateKey, situation);
  }

  private _getNewMCCEventMessage(event: MccEvent, eventInfo: HolNextInfo): string {
    const lastInfo = eventInfo ? eventInfo : event.infos && event.infos.length ? event.infos[0] : null;
    return this.translate.instant('SMS.MCC.NEW_EVENT', {
      aircraft: event.aircraft && event.aircraft.registration,
      ataCode: event.ataCode && event.ataCode.code,
      ataDescription: event.ataCode && event.ataCode.description,
      description: event.description,
      nextInfoTime: lastInfo && lastInfo.nextInfoTime && moment(lastInfo.nextInfoTime).utc().format('ddd DD [at] HH:mm [UTC]'),
      nextInfoText: lastInfo && lastInfo.message,
    });
  }
}
