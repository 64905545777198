import { Component, Input, OnInit } from '@angular/core';
import { HolFlight } from '../../../models/hol-flight.model';
import moment from 'moment';

@Component({
  selector: 'app-indicator-timeline',
  templateUrl: './indicator-timeline.component.html',
  styleUrls: ['./indicator-timeline.component.scss'],
})
export class IndicatorTimelineComponent implements OnInit {
  @Input()
  public dayTargeted: any;
  @Input()
  public flight: HolFlight;
  @Input()
  public showInfo: boolean;
  @Input() departureSelected: string;

  public arrivalOffset = 8;
  public arrivalPosition: number;
  public departurePosition: number;
  public intervalWidth: number;
  public oneDay = 86400000;
  public showMarker: any;

  public ngOnInit(): void {
    this.dayTargeted = !this.dayTargeted ? moment().utc(false) : moment(this.dayTargeted).utc(false);
    const departurePosition = this.isSameDay(moment(this.flight.std).utc(false)) ? this.getPosition(moment(this.flight.std).utc(false)) : 0;
    const arrivalPosition = this.isSameDay(moment(this.flight.sta).utc(false)) ? this.getPosition(moment(this.flight.sta).utc(false)) : 100;
    const minDiff = 8;
    const diff = arrivalPosition - departurePosition;
    if (diff < minDiff) {
      if (arrivalPosition + minDiff <= 100) {
        this.departurePosition = departurePosition;
        this.arrivalPosition = arrivalPosition + minDiff;
      } else {
        this.departurePosition = departurePosition - minDiff;
        this.arrivalPosition = arrivalPosition;
      }
    } else {
      this.departurePosition = departurePosition;
      this.arrivalPosition = arrivalPosition;
    }
    this.showMarker = {
      departure: this.isSameDay(moment(this.flight.std).utc(false)),
      arrival: this.isSameDay(moment(this.flight.sta).utc(false)),
    };
    this.intervalWidth = this.arrivalPosition - this.departurePosition;
    this.arrivalOffset = this.intervalWidth < 5 ? 0 : 8;
  }

  private getPosition(date): number {
    const startOfDay = moment(this.dayTargeted).utc(false).startOf('day').valueOf();
    const duration = moment(date).utc(false).diff(moment(startOfDay), 'millisecond');
    return (100 * duration) / this.oneDay;
  }

  private isSameDay(date): boolean {
    return moment(date).isSame(this.dayTargeted, 'day');
  }
}
