import { CrisisStoreManager } from '../store/crisis/crisis.store-manager';
import { ErpHistoryLog } from '../models/erp-historyLog';
import { RequestService } from 'src/app/common/services/request.service';
import { Injectable } from '@angular/core';
import { HistoryService } from 'src/app/common/services/history.service';

@Injectable({
  providedIn: 'root',
})
export class ErpHistoryService extends HistoryService<ErpHistoryLog> {
  // tslint:disable:variable-name
  protected ParseHistory = Parse.Object.extend('GDCHistory');
  private ParseCrisis = Parse.Object.extend('GDCCrisis');
  private ParseCrisisTask = Parse.Object.extend('GDCTask');
  private ParseCrisisNew = Parse.Object.extend('GDCCrisisNews');
  private ParseCrisisAnnouncement = Parse.Object.extend('GDCAnnouncement');
  private ParseCrisisDecision = Parse.Object.extend('GDCDecisions');

  constructor(protected requestService: RequestService, private crisisStoreManager: CrisisStoreManager) {
    super(requestService);
  }

  getHistoryByObjectIdAndType(objectId: string, type: string): Promise<ErpHistoryLog[]> {
    let historyLogs: ErpHistoryLog[];
    let parseType;
    let typeToRequest;
    if (type === 'crisisTask' || type === 'crisisDocument') {
      typeToRequest = 'crisisTask';
      parseType = new this.ParseCrisisTask({ id: objectId });
    } else {
      typeToRequest = type;
    }
    if (type === 'crisisNew') {
      parseType = new this.ParseCrisisNew({ id: objectId });
    }
    if (type === 'crisisAnnouncement') {
      parseType = new this.ParseCrisisAnnouncement({ id: objectId });
    }
    if (type === 'crisisDecision') {
      parseType = new this.ParseCrisisDecision({ id: objectId });
    }

    const crisisHistoryLogsQuery = new Parse.Query(this.ParseHistory);
    crisisHistoryLogsQuery.include('ACL');
    crisisHistoryLogsQuery.equalTo('type', type);
    crisisHistoryLogsQuery.equalTo(typeToRequest, parseType);
    crisisHistoryLogsQuery.descending('createdAt');

    return this.requestService.performFindAllQuery(crisisHistoryLogsQuery).then(crisisHistoryLogsFromApi => {
      if (crisisHistoryLogsFromApi) {
        historyLogs = crisisHistoryLogsFromApi.map(crisisHistoryLogFromApi => new ErpHistoryLog(crisisHistoryLogFromApi));
        return historyLogs;
      } else {
        return [];
      }
    });
  }

  postLog(erpHistoryLog: ErpHistoryLog): Promise<Parse.Object> {
    const parseUser = Parse.User.current();
    if (parseUser) {
      const log = new this.ParseHistory();
      if (erpHistoryLog.crisis) {
        log.set('crisis', erpHistoryLog.crisis);
      }
      log.set('type', erpHistoryLog.type);
      log.set('subType', erpHistoryLog.subType);
      log.set('user', parseUser);
      log.set('jsonObject', { ...erpHistoryLog.jsonObject });
      log.set('crisisTask', erpHistoryLog.crisisTask);
      log.set('crisisNew', erpHistoryLog.crisisNew);
      log.set('crisisAnnouncement', erpHistoryLog.crisisAnnouncement);
      log.set('crisisDecision', erpHistoryLog.crisisDecision);
      if (erpHistoryLog.acl) {
        log.setACL(erpHistoryLog.acl);
      }
      log.set('hideInHistory', erpHistoryLog.hideInHistory);
      return this.requestService.performSaveQuery(log).then(parseData => {
        const bufferHistoryLog: ErpHistoryLog = new ErpHistoryLog(parseData);
        if (!erpHistoryLog.hideInHistory) {
          this.crisisStoreManager.createOneCrisisHistoryLog(bufferHistoryLog);
        }
        return parseData;
      });
    } else {
      return Promise.resolve(null);
    }
  }

  getHistoryByCrisisObjectId(crisisObjectId?: string): Promise<ErpHistoryLog[]> {
    let historyLogs: ErpHistoryLog[];
    const crisis = new this.ParseCrisis();
    crisis.set('objectId', crisisObjectId);
    const crisisHistoryLogsQuery = new Parse.Query(this.ParseHistory);
    crisisHistoryLogsQuery.include('ACL');
    crisisHistoryLogsQuery.equalTo('crisis', crisis);
    crisisHistoryLogsQuery.descending('createdAt');
    return this.requestService.performFindAllQuery(crisisHistoryLogsQuery).then(crisisHistoryLogsFromApi => {
      if (crisisHistoryLogsFromApi) {
        historyLogs = crisisHistoryLogsFromApi.map(crisisHistoryLogFromApi => new ErpHistoryLog(crisisHistoryLogFromApi));
        return historyLogs;
      } else {
        return [];
      }
    });
  }

  deleteAllTaskLogs(crisisObjectId?: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const crisis = new this.ParseCrisis();
      crisis.set('objectId', crisisObjectId);
      const crisisHistoryLogsQuery = new Parse.Query(this.ParseHistory);
      crisisHistoryLogsQuery.equalTo('crisis', crisis);
      crisisHistoryLogsQuery.limit(1000);
      this.requestService.performFindQuery(
        crisisHistoryLogsQuery,
        crisisHistoryLogsToDelete => {
          this.requestService.performDestroyAllQuery(crisisHistoryLogsToDelete).then(
            () => {
              console.log('All logs deleted');
              resolve();
            },
            error => {
              console.error('Oops! Something went wrong: ' + error.message + ' (' + error.code + ')');
              reject(error);
            },
          );
        },
        reject,
      );
    });
  }
}
