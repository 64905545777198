import { orderBy } from 'lodash';
import { OclGroup } from './ocl-group.model';
import { OclDecision } from './ocl-decision.model';

export class OclDecisionGroup<T extends OclDecision = OclDecision> extends OclGroup<T> {
  public decisionsInSummary: T[];

  constructor(parseObject?: Parse.Object) {
    super(parseObject);
    if (!parseObject) {
      return;
    }
    this.items =
      parseObject.get('decisions') && parseObject.get('decisions').length
        ? orderBy(
            parseObject.get('decisions').map(dcn => this.newItem(dcn)),
            'createdAt',
            'desc',
          )
        : [];

    this.decisionsInSummary =
      parseObject.get('decisionsInSummary') && parseObject.get('decisionsInSummary').length
        ? orderBy(
            parseObject.get('decisionsInSummary').map(lg => this.newItem(lg)),
            'createdAt',
            'desc',
          )
        : [];
  }

  public get getMostCriticalInfo(): OclDecision {
    const buffer = orderBy(
      this.items.filter(dcn => {
        if (dcn.nextInfoTime && !dcn.done) {
          return dcn;
        }
      }),
      'nextInfoTime',
      'asc',
    )[0];
    return buffer && buffer.nextInfoTime ? buffer : null;
  }

  protected newItem(parseObject?: Parse.Object): T {
    return new OclDecision(parseObject) as T;
  }
}
