import { HolRole } from './hol-role';
import { identity, uniqBy } from 'lodash';
import { RolesService } from '../services/roles.service';
import { HolUser } from './hol-user.model';
import { HolAttachments } from './hol-attachments.model';

export abstract class HolObject {
  public objectId?: string;
  public acl?: Parse.ACL;
  public companies?: string[] = [];
  public aclModules?: string[] = [];
  public readOnly?: boolean;
  public createdAt?: Date;
  public updatedAt?: Date;
  public createdBy?: HolUser;
  public updatedBy?: HolUser;
  public attachments?: HolAttachments;
  public contentText?: string;
  public disabled?: boolean;
  public customCreatedAt?: Date;
  public doneBy?: HolUser;

  protected constructor(parseObject?: Parse.Object) {
    if (!parseObject) {
      this.acl = new Parse.ACL();
      this.acl.setPublicReadAccess(true);
      this.acl.setPublicWriteAccess(true);
    } else {
      this.objectId = parseObject.id;
      this.acl = parseObject.getACL();
      this.createdAt = parseObject.get('customCreatedAt') || parseObject.get('createdAt');
      this.customCreatedAt = parseObject.get('customCreatedAt');
      this.updatedAt = parseObject.get('updatedAt');
      this.createdBy = new HolUser(parseObject.get('createdBy'));
      this.updatedBy = new HolUser(parseObject.get('updatedBy'));
      this.doneBy = new HolUser(parseObject.get('doneBy'));
      this.contentText = this.getContentText(parseObject);
      let jsonAttachments = parseObject.get('attachments');
      if (typeof jsonAttachments === 'string') {
        jsonAttachments = JSON.parse(parseObject.get('attachments'));
      }

      if (jsonAttachments) {
        this.attachments = new HolAttachments(
          jsonAttachments.note,
          jsonAttachments.file,
          jsonAttachments.image,
          jsonAttachments.noteFile,
          jsonAttachments.link,
          jsonAttachments.files,
        );
      } else {
        this.attachments = new HolAttachments();
      }
    }
    this.parseACL(this.acl);
  }

  public parseACL?(acl: Parse.ACL) {
    this.companies = [];
    this.aclModules = [];
    if (!acl) {
      return;
    }
    this.readOnly = !acl.getPublicWriteAccess();
    Object.entries<any>(acl.permissionsById).forEach(([key, value]) => {
      if ((key.startsWith('role:') && value.read) || value.write) {
        const roleName = key.replace('role:', '');
        if (value.write && RolesService.currentUserRoles && RolesService.currentUserRoles.roles.find(ur => ur.name === roleName)) {
          this.readOnly = false;
        }
        if (HolRole.ROLE_PARTS_REGEXP.test(roleName)) {
          const parts = HolRole.ROLE_PARTS_REGEXP.exec(roleName);
          this.companies.push(parts[2]);
          if (!this.aclModules.includes(parts[1])) {
            this.aclModules.push(parts[1]);
          }
        }
      }
    });
    this.companies = uniqBy(this.companies, identity).sort((a, b) => (a < b ? -1 : 1));
  }

  public updateACL?(acl?: Parse.ACL) {
    if (acl) {
      this.acl = acl;
    }
    this.parseACL(this.acl);
  }

  protected getContentText?(parseObject: Parse.Object) {
    return '⚠️ getContentText is not implemented in sub class...';
  }
}
