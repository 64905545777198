import { Inject, Injectable } from '@angular/core';
import { OclSmsService } from '../../../ocl/services/ocl-sms-service/ocl-sms.service';
import { OccModuleConfigService } from '../occ-module-config/occ-module-config.service';
import { SmsSenderService } from 'src/app/common/services/sms/sms-sender.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class OccSmsService extends OclSmsService {
  constructor(
    protected readonly smsSenderService: SmsSenderService,
    @Inject('CONSTANTS') protected CONSTANTS,
    protected translate: TranslateService,
    protected moduleConfig: OccModuleConfigService,
  ) {
    super(smsSenderService, CONSTANTS, translate, moduleConfig);

    this.translate.onLangChange.subscribe(() => {
      this.SENDER_SUFFIX = this.translate.instant('SMS.OCC.SENDER_SUFFIX') || 'OCC';
    });
  }
}
