import { HolAttachments } from './hol-attachments.model';
import moment from 'moment';
import { HolTag } from './hol-tag';
import { FltFlightLogbook } from '../../flt/models/flt-flight-logbook';
import { HolObject } from './hol-object';
import { FltFlightInstruction } from '../../flt/models/flt-flight-instruction';
import { HolEvent } from './hol-event';

// https://helpdesk.cirium.com/hc/en-us/articles/217613238-What-are-the-flight-status-codes
export const TAKEN_OFF_STATUS = ['A', 'L', 'D', 'R'];
export const LANDED_STATUS = ['L', 'D'];
export const ENDED_STATUS = ['L', 'D', 'C'];

export interface HolChecklistItem {
  [key: string]: string;
}

export class HolFlight<
  T extends FltFlightLogbook = FltFlightLogbook,
  U extends FltFlightInstruction = FltFlightInstruction,
  V extends HolEvent = HolEvent,
> extends HolObject {
  flightId: number;
  flightNumber: string;
  registration: string;
  parkingArea: string;
  /**
   * Scheduled time departure
   */
  std: Date;
  /**
   * Scheduled time arrival
   */
  sta: Date;
  /**
   * Estimated time departure
   */
  etd: Date;
  /**
   * Estimated time arrival
   */
  eta: Date;
  /**
   * Actual time departure
   */
  atd: Date;
  /**
   * Actual time arrival
   */
  ata: Date;

  destination: string;
  departure: string;
  cdb: string;
  ccp: string;
  status: string;
  occ: string;
  crew: string;
  mcc: string;
  goc: string;
  ops: string;
  checklistItems: HolChecklistItem[];
  events: V[];
  logbooks: T[];
  instructions: U[];
  opsAttachments?: HolAttachments;
  isDepartureDelayed: boolean;
  isArrivalDelayed: boolean;
  tags: HolTag[];

  constructor(parseObject?: Parse.Object, tags?: HolTag[]) {
    super(parseObject);
    if (!parseObject) {
      return;
    }
    this.flightNumber = parseObject.get('flightNumber');
    this.flightId = parseObject.get('flightId');
    this.registration = parseObject.get('registration');
    this.parkingArea = parseObject.get('parkingArea');
    this.std = parseObject.get('std');
    this.etd = parseObject.get('etd');
    this.atd = parseObject.get('atd');
    this.departure = parseObject.get('departure');
    this.sta = parseObject.get('sta');
    this.eta = parseObject.get('eta');
    this.ata = parseObject.get('ata');
    this.destination = parseObject.get('destination');
    this.cdb = parseObject.get('cdb');
    this.ccp = parseObject.get('ccp');
    this.status = parseObject.get('status');
    this.occ = parseObject.get('occ');
    this.crew = parseObject.get('crew');
    this.mcc = parseObject.get('mcc');
    this.goc = parseObject.get('goc');
    this.ops = parseObject.get('ops');
    this.checklistItems = parseObject.get('checklistItems');
    this.opsAttachments = parseObject.get('opsAttachments') ? parseObject.get('opsAttachments') : {};
    this.isDepartureDelayed =
      (this.atd && !moment(this.std).isSame(moment(this.atd))) || (!this.atd && this.etd && !moment(this.std).isSame(moment(this.etd)));
    this.isArrivalDelayed =
      (this.ata && !moment(this.sta).isSame(moment(this.ata))) || (!this.ata && this.eta && !moment(this.sta).isSame(moment(this.eta)));
    this.tags = tags || [];
  }

  toString() {
    return `${this.flightNumber}/${moment(this.std).format('DDMMM')} ${this.departure} -> ${this.destination} (${this.objectId})`;
  }

  isArrived() {
    return this.ata && moment(this.ata).isBefore(moment().utc());
  }
}
