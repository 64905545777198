import { Inject, Injectable } from '@angular/core';

import { UserService } from 'src/app/common/services/user.service';
import { CommonStoreManager } from 'src/app/common/store/common.store-manager';
import { HolNotification } from 'src/app/common/models/hol-notification.model';
import { NotificationsService } from 'src/app/common/services/notifications/notifications.service';

import { FilesService } from 'src/app/common/services/files.service';
import { differenceBy, flatten, isEqual, startsWith } from 'lodash';
import moment from 'moment';
import { MarkdownService } from 'src/app/common/components/markdown-editor/markdown.service';
import { OclMailService } from 'src/app/ocl/services/ocl-mail-service/ocl-mail.service';

import { HolContext } from '../../common/models/hol-context.model';
import { HolFlight } from '../../common/models/hol-flight.model';
import { HolTag } from '../../common/models/hol-tag';
import { ModuleConfigService } from '../../common/services/module-config/module-config.service';
import { ParseMapperService } from '../../common/services/parse-mapper.service';
import { RequestService } from '../../common/services/request.service';
import { GocFlightLogbookService } from '../../goc/services/goc-flight-logbook-service/goc-flight-logbook.service';
import { OclHistoryService } from '../../ocl/services/ocl-history-service/ocl-history.service';
import { OclLogbookService } from '../../ocl/services/ocl-logbook-service/ocl-logbook.service';
import { OclLogbookTagService } from '../../ocl/services/ocl-logbook-tag-service/ocl-logbook-tag.service';
import { OclOptionsService } from '../../ocl/services/ocl-options-service/ocl-options.service';
import { OclSmsService } from '../../ocl/services/ocl-sms-service/ocl-sms.service';
import { OclLogBooksStoreManager } from '../../ocl/store/logbooks/ocl-log-books-store-manager.service';
import { FltLogbook } from '../models/flt-logbook';
import { FltApplicabilityService } from './flt-applicability.service';
import { FltFlightLogbookService } from './flt-flight-logbook.service';
import { FltFlightService } from './flt-flight.service';
import { OclHistoryLog } from '../../ocl/models/ocl-history-log.model';

@Injectable({
  providedIn: 'root',
})
export abstract class FltLogbookService<T extends FltLogbook = FltLogbook> extends OclLogbookService<FltLogbook> {
  // tslint:disable:variable-name
  protected abstract ParseFlightLogbook;
  protected ParseFlight = Parse.Object.extend('GOCFlight');

  // tslint:enabled

  protected constructor(
    @Inject('$rootScope') protected $rootScope,
    protected requestService: RequestService,
    protected readonly filesService: FilesService,
    protected readonly userService: UserService,
    protected optionsService: OclOptionsService,
    protected historyService: OclHistoryService<OclHistoryLog>,
    protected parseMapper: ParseMapperService,
    protected logBookTagService: OclLogbookTagService,
    protected gocLogbookService: GocFlightLogbookService,
    protected occLogBooksStoreManager: OclLogBooksStoreManager,
    public moduleConfig: ModuleConfigService,
    protected flightService: FltFlightService,
    protected flightLogbookService: FltFlightLogbookService,
    protected markdownService: MarkdownService,
    protected applicabilityService: FltApplicabilityService,
    protected notificationsService: NotificationsService,
    protected smsService: OclSmsService,
    protected mailService: OclMailService,
    public commonStoreManager: CommonStoreManager,
  ) {
    super(
      $rootScope,
      requestService,
      userService,
      optionsService,
      historyService,
      parseMapper,
      logBookTagService,
      gocLogbookService,
      occLogBooksStoreManager,
      moduleConfig,
      notificationsService,
      smsService,
      mailService,
      commonStoreManager,
      filesService,
      markdownService,
    );
  }

  public setAdditionalFields(logbook: T, parseLogbook: Parse.Object) {
    if (logbook.applicability) {
      logbook.applicability.updateParseObject(parseLogbook);
    }
    if (logbook.isFromFlight && logbook.isFromFlight.objectId !== null) {
      parseLogbook.set(
        'isFromFlight',
        new this.ParseFlight({
          id: logbook.isFromFlight.objectId,
        }),
      );
    }
  }

  public create(logbook: T, notifications: HolNotification[], context: HolContext): Promise<T> {
    let addBreakLinesBefore = false;
    if (logbook.attachments && logbook.attachments.note) {
      addBreakLinesBefore = true;
    }
    return super.create(logbook, notifications, context).then(savedLogbook => {
      logbook.objectId = savedLogbook.objectId;
      return this.flightService.getFlightsForApplicability(logbook.applicability, logbook.acl).then(applicableFlights => {
        if (logbook.isFromFlight && applicableFlights.find(f => f.objectId === logbook.isFromFlight.objectId)) {
          applicableFlights = applicableFlights.filter(f => f.objectId !== logbook.isFromFlight.objectId);
        }
        const logbooks = flatten(
          applicableFlights.map(f => {
            return this.getFlightLogbookFromLogbook(f, logbook);
          }),
        );
        return this.requestService
          .performSaveAllQuery(logbooks)
          .then(() => this.saveApplicabilityHistory(savedLogbook, context, addBreakLinesBefore));
      });
    });
  }

  public async update(logbook: T, notifications: HolNotification[], context: HolContext): Promise<T> {
    const oldLogbook = new FltLogbook(new this.ParseLogbook({ id: logbook.objectId }));
    const oldApplicability = oldLogbook.applicability;
    const newApplicability = logbook.applicability;

    return super.update(logbook, notifications).then(res => {
      return Promise.all([
        this.flightLogbookService.getAllFromLogbook(logbook),
        this.flightService.getFlightsForApplicability(logbook.applicability, logbook.acl),
      ]).then(([oldFlightLogbooks, matchingFlights]) => {
        const newFlightLogbooks = flatten(
          matchingFlights.map(f => {
            return this.getFlightLogbookFromLogbook(f, logbook);
          }),
        );
        const compareFlightLogbooks = flb => {
          return flb.get('flight').id + '#' + flb.get('station');
        };
        const toRemove = differenceBy(oldFlightLogbooks, newFlightLogbooks, compareFlightLogbooks);
        const toCreate = differenceBy(newFlightLogbooks, oldFlightLogbooks, compareFlightLogbooks);

        return Promise.all([this.requestService.performDestroyAllQuery(toRemove), this.requestService.performSaveAllQuery(toCreate)]).then(
          () => {
            if (!isEqual(newApplicability, oldApplicability)) {
              return this.saveApplicabilityHistory(res, context);
            } else {
              return res as T;
            }
          },
        );
      });
    });
  }

  protected initFlightLogbookFromLogbook(flight: HolFlight, logbook: T) {
    const flightLogbook = new this.ParseFlightLogbook();
    flightLogbook.set('flight', new this.ParseFlight({ id: flight.objectId }));
    flightLogbook.set('logbook', new this.ParseLogbook({ id: logbook.objectId }));
    if (!flight.acl.getPublicWriteAccess()) {
      Object.entries(flight.acl.permissionsById).forEach(([key, value]) => {
        if (key.startsWith('role:') && !startsWith(key.replace('role:', ''), this.moduleConfig.config.moduleName.toUpperCase())) {
          delete flight.acl.permissionsById[key];
        }
      });
      flightLogbook.setACL(flight.acl);
    } else {
      flightLogbook.setACL(logbook.acl);
    }
    return flightLogbook;
  }

  protected newLogbook(parseObject?: Parse.Object, tags?: Parse.Object[]): T {
    return new FltLogbook(parseObject, tags && tags.map(t => new HolTag(t.get('tag')))) as T;
  }

  private getFlightLogbookFromLogbook(flight: HolFlight, logbook: T): Parse.Object[] {
    const flightLogbook = this.initFlightLogbookFromLogbook(flight, logbook);

    if (logbook.applicability.flightsDirection === 'DEP' || logbook.applicability.stationsDirection === 'OUT') {
      flightLogbook.set('station', flight.departure);
    } else if (logbook.applicability.flightsDirection === 'ARR' || logbook.applicability.stationsDirection === 'IN') {
      flightLogbook.set('station', flight.destination);
    } else if (!logbook.applicability.stationsDirection && logbook.applicability.stations) {
      const flightLogbooks = [];
      logbook.applicability.stations.forEach(station => {
        if (flight.departure === station || flight.destination === station) {
          const tempLogbook = this.initFlightLogbookFromLogbook(flight, logbook);
          tempLogbook.set('station', station);
          flightLogbooks.push(tempLogbook);
        }
      });
      return flightLogbooks;
    }
    return [flightLogbook];
  }

  protected getAdditionnalQueries(query, queryPinned, filterDataStartDate) {
    // Filtrage du tableau de bord par date
    // - Soit T = date sélectionnée à 00:00Z ou date à 00:00Z si aucune date n’est sélectionnée
    // - Soit C = date de création d’une carte `createdAt`
    // - Soit S = date de fin d’applicabilité escale d’une carte
    // - Soit F = Max(STA) des vols applicables à une carte
    // | Applicabilité LOGBOOK | Visibilité                                                                                   | Prise en compte valeur `…ToDisplay` |
    // | ------------------------------ | ----------------------------------------------------------------------------------- | ----------------------------------- |
    // | ESCALES                        | `C ≤ T && S ≥ T`                                                                    | NON                                 |
    // | VOLS                           | `C ≤ T && F ≥ T`                                                                    | NON                                 |
    // | TOUS VOLS                      | Toujours                                                                            | NON                                 |=> NON DEV pour l'instant
    // | AUCUNE                         | Si  `MAX(createdAt, customCreatedAt?)`  appartient à l’intervalle  `T - …ToDisplay` | OUI                                 |
    if (this.moduleConfig.config.canChooseDataStartDate && filterDataStartDate instanceof Date && filterDataStartDate !== undefined) {
      query.doesNotExist('applStations');
      query.doesNotExist('applFlights');

      const todayStart = moment.utc(filterDataStartDate).startOf('day');
      const todayEnd = moment.utc(filterDataStartDate).endOf('day');

      const queryStationApplicability = new Parse.Query(this.ParseLogbook);
      queryStationApplicability.exists('applStations');
      queryStationApplicability.notEqualTo('applStations', '');
      queryStationApplicability.doesNotExist('customCreatedAt');
      queryStationApplicability.lessThanOrEqualTo('createdAt', todayEnd.toDate());
      queryStationApplicability.greaterThanOrEqualTo('validTo', todayStart.toDate());

      const queryStationApplicability2 = new Parse.Query(this.ParseLogbook);
      queryStationApplicability2.exists('applStations');
      queryStationApplicability2.notEqualTo('applStations', '');
      queryStationApplicability2.doesNotExist('customCreatedAt');
      queryStationApplicability2.lessThanOrEqualTo('createdAt', todayEnd.toDate());
      queryStationApplicability2.doesNotExist('validTo');

      const queryStationApplicability3 = new Parse.Query(this.ParseLogbook);
      queryStationApplicability3.exists('applStations');
      queryStationApplicability3.notEqualTo('applStations', '');
      queryStationApplicability3.exists('customCreatedAt');
      queryStationApplicability3.notEqualTo('customCreatedAt', '');
      queryStationApplicability3.lessThanOrEqualTo('customCreatedAt', todayEnd.toDate());
      queryStationApplicability3.greaterThanOrEqualTo('validTo', todayStart.toDate());

      const queryStationApplicability4 = new Parse.Query(this.ParseLogbook);
      queryStationApplicability4.exists('applStations');
      queryStationApplicability4.notEqualTo('applStations', '');
      queryStationApplicability4.exists('customCreatedAt');
      queryStationApplicability4.notEqualTo('customCreatedAt', '');
      queryStationApplicability4.lessThanOrEqualTo('customCreatedAt', todayEnd.toDate());
      queryStationApplicability4.doesNotExist('validTo');

      const queryLinkedFlights = new Parse.Query(this.ParseFlight);
      queryLinkedFlights.greaterThanOrEqualTo('sta', todayStart.toDate());
      queryLinkedFlights.notEqualTo('status', 'C');

      const queryFlightsApplicability = new Parse.Query(this.ParseLogbook);
      queryFlightsApplicability.exists('applFlights');
      queryFlightsApplicability.notEqualTo('applFlights', '');
      queryFlightsApplicability.doesNotExist('customCreatedAt');
      queryFlightsApplicability.lessThanOrEqualTo('createdAt', todayEnd.toDate());
      queryFlightsApplicability.matchesQuery('applFlights', queryLinkedFlights);

      const queryFlightsApplicability2 = new Parse.Query(this.ParseLogbook);
      queryFlightsApplicability2.exists('applFlights');
      queryFlightsApplicability2.notEqualTo('applFlights', '');
      queryFlightsApplicability2.exists('customCreatedAt');
      queryFlightsApplicability2.notEqualTo('customCreatedAt', '');
      queryFlightsApplicability2.lessThanOrEqualTo('customCreatedAt', todayEnd.toDate());
      queryFlightsApplicability2.matchesQuery('applFlights', queryLinkedFlights);

      return Parse.Query.or(
        query,
        queryPinned,
        queryStationApplicability,
        queryStationApplicability2,
        queryStationApplicability3,
        queryStationApplicability4,
        queryFlightsApplicability,
        queryFlightsApplicability2,
      );
    } else {
      return Parse.Query.or(query, queryPinned);
    }
  }

  private saveApplicabilityHistory(savedLogbook: FltLogbook, context: HolContext, addBreakLinesBefore = false) {
    return this.applicabilityService.applicabilityHistoryInNotes(savedLogbook, context, addBreakLinesBefore).then(attachments => {
      const parseLogbook = new this.ParseLogbook();
      parseLogbook.id = savedLogbook.objectId;
      parseLogbook.set('attachments', JSON.stringify(attachments));
      return this.requestService.performSaveQuery(parseLogbook).then(l => {
        const newLogbook = this.newLogbook(l, l.tags);
        this.occLogBooksStoreManager.updateOneLogBook(newLogbook);
        return newLogbook;
      });
    });
  }
}
