import moment from 'moment';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timeOrDay',
})
export class TimeOrDayPipe implements PipeTransform {
  transform(value: any, utc?: any): any {
    if (value) {
      let momentDate;
      let dateFormat;

      if (utc) {
        momentDate = moment(value).utc();
      } else {
        momentDate = moment(value);
      }

      if ((utc && !momentDate.isSame(moment().utc(), 'days')) || (!utc && !momentDate.isSame(moment(), 'days'))) {
        dateFormat = 'DD/MM';
      } else {
        if (utc) {
          dateFormat = 'HH:mm[Z]';
        } else {
          dateFormat = 'HH:mm';
        }
      }

      return momentDate.format(dateFormat);
    }
  }
}
