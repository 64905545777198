import { Inject, Injectable } from '@angular/core';

import { UserService } from 'src/app/common/services/user.service';
import { CommonStoreManager } from 'src/app/common/store/common.store-manager';
import { RolesService } from 'src/app/common/services/roles.service';
import { NotificationsService } from 'src/app/common/services/notifications/notifications.service';
import moment from 'moment';
import { FltEventService } from 'src/app/flt/services/flt-event.service';
import { MarkdownService } from 'src/app/common/components/markdown-editor/markdown.service';
import { FltFlightService } from 'src/app/flt/services/flt-flight.service';
import { FltEvent } from 'src/app/flt/models/flt-event';
import { FltApplicabilityService } from 'src/app/flt/services/flt-applicability.service';
import { FilesService } from 'src/app/common/services/files.service';
import { OclScenariosStoreManager } from 'src/app/ocl/store/scenarios/ocl-scenarios.store-manager';

import { HolNextInfo } from '../../../common/models/hol-next-info.model';
import { ParseMapperService } from '../../../common/services/parse-mapper.service';
import { RequestService } from '../../../common/services/request.service';
import { MccEventService } from '../../../mcc/services/mcc-event.service';
import { MccOptionsService } from '../../../mcc/services/mcc-options.service';
import { OclEventsStoreManager } from '../../../ocl/store/events/ocl-events-store-manager.service';
import { OclTagsStoreManager } from '../../../ocl/store/tags/ocl-tags.store-manager';
import { OclTasksStoreManager } from '../../../ocl/store/tasks/ocl-tasks.store-manager';
import { OpsEventTagService } from '../ops-event-tag-service/ops-event-tag.service';
import { OpsFlightEventService } from '../ops-flight-event-service/ops-flight-event-service';
import { OpsHistoryService } from '../ops-history-service/ops-history.service';
import { OpsMailService } from '../ops-mail-service/ops-mail.service';
import { OpsModuleConfigService } from '../ops-module-config/ops-module-config.service';
import { OpsSmsService } from '../ops-sms-service/ops-sms.service';
import { OpsTagsService } from '../ops-tags-service/ops-tags.service';
import { OpsTasksService } from '../ops-tasks.service';

@Injectable({
  providedIn: 'root',
})
export class OpsEventService extends FltEventService {
  // tslint:disable:variable-name
  protected ParseEvents = Parse.Object.extend('OPSEvents');
  protected ParseScenario = Parse.Object.extend('OPSScenario');
  protected ParseEventInfos = Parse.Object.extend('OPSEventInfos');
  protected ParseTaskRef = Parse.Object.extend('OPSTask_REF');
  protected ParseTag = Parse.Object.extend('OPSTag');
  protected ParseEventTag = Parse.Object.extend('OPSEventTag');
  protected ParseFlightEvents = Parse.Object.extend('OPSFlightEvents');
  // tslint:enable
  protected taskEventColumn = 'opsEvent';

  public constructor(
    protected requestService: RequestService,
    protected mccEventsService: MccEventService,
    protected mccOptionsService: MccOptionsService,
    protected userService: UserService,
    protected parseMapper: ParseMapperService,
    protected notificationsService: NotificationsService,
    protected historyService: OpsHistoryService,
    protected eventTagService: OpsEventTagService,
    protected eventsStoreManager: OclEventsStoreManager,
    protected mailService: OpsMailService,
    protected smsService: OpsSmsService,
    @Inject('ChatService') protected chatService,
    @Inject('$rootScope') protected $rootScope,
    public moduleConfig: OpsModuleConfigService,
    public markdownService: MarkdownService,
    protected flightService: FltFlightService,
    protected readonly filesService: FilesService,
    protected applicabilityService: FltApplicabilityService,
    protected flightEventService: OpsFlightEventService,
    protected tasksStoreManager: OclTasksStoreManager,
    protected tasksService: OpsTasksService,
    protected tagsService: OpsTagsService,
    protected rolesService: RolesService,
    protected commonStoreManager: CommonStoreManager,
    protected tagsStoreManager: OclTagsStoreManager,
    protected scenariosStoreManager: OclScenariosStoreManager,
  ) {
    super(
      requestService,
      mccOptionsService,
      userService,
      parseMapper,
      notificationsService,
      historyService,
      eventTagService,
      eventsStoreManager,
      mailService,
      smsService,
      chatService,
      $rootScope,
      moduleConfig,
      markdownService,
      flightService,
      filesService,
      applicabilityService,
      flightEventService,
      tasksStoreManager,
      tasksService,
      tagsService,
      rolesService,
      commonStoreManager,
      tagsStoreManager,
      scenariosStoreManager,
    );
  }

  protected markPreviousInfosAsDone(event: FltEvent, nextInfoTime: Date): Promise<HolNextInfo[]> {
    const infosToMarkAsDone = event.infos.filter(info => !info.done && moment(info.nextInfoTime).isSameOrBefore(nextInfoTime));
    const promises = infosToMarkAsDone.map(info => this.markInfoAsDone(info, event, true, false));
    return Promise.all(promises);
  }

  public activateECLModule(eclOptionsId: string, moduleName: string, event: FltEvent, infos: any[]): void {}

  public deactivateECLModule(eclOptionsId: string): void {}

  protected getAllMCCEvents(): Promise<any[]> {
    return Promise.resolve([]);
  }
}
